import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  
import { Page, Section } from 'react-page-layout';

import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';

import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';


import Tooltip from '@material-ui/core/Tooltip';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';



function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [


{ id: 'cu_contactname', numeric: false,  label: 'Contact Name' },
{ id: 'cu_email', numeric: false,  label: 'Email' },
{ id: 'cu_mobileno', numeric: false,  label: 'Mobile No' },
{ id: 'cu_phonenum', numeric: false,  label: 'Phone No' },
{ id: 'cu_panno', numeric: false,  label: 'Pan No' },
{ id: 'cu_gstinno', numeric: false,  label: 'GST No' },
{ id: 'cu_billingassress', numeric: false,  label: 'Billing Address' },
{ id: 'cu_shippingaddress', numeric: false,  label: 'Shipping Address' },

{ id: 'cu_dob', numeric: false,  label: 'DOB' },
{ id: 'cu_weddinganniversary', numeric: false,  label: 'Wedding Anniversary' },
{ id: 'cu_payment', numeric: false,  label: 'Payment' },
{ id: 'pl_name', numeric: false,  label: 'Place' },

]

function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
  
  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class vendorreport extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],
	 
	 
	search:'',order:'desc' ,orderBy:'cu_id',name:'',cu_id:0,contacttype:'',contactname:'',email:'',mobileno:'',phonenum:'',panno:'',gstinno:'',billingassress:'',shippingaddress:'',
	cusemail:''
	 
	  
	  
	 
        
      }
	 };
	 
	 
	
	 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 } 
 
handleSubmit= (event) => {
    
    event.preventDefault();
	//alert('')
  
	let cusemail = this.state.cusemail;
	this.apicall(this.state.page,this.state.rowsPerPage);
	
} 


 componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}

componentDidMount() {
	
	document.title = "";
	
if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
	else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "3" || localStorage.getItem("Rrole") === "4" )

	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  

	}


	
 localStorage.setItem("page",this.state.rowsPerPage);
  this.apicall(this.state.page,this.state.rowsPerPage); 

}	 

apicall(page,rowsPerPage){
	
	axios.get(Serverurl+'ajaxcustomer.php?action=customerreport&cu_contacttype=2&cu_email='+this.state.cusemail+'&sorting='+this.state.orderBy +' '+this.state.order+'&StartIndex='+page+'&PageSize='+rowsPerPage)
  	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  StoreData: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			let cnt=this.state.totaldata;
			let page=this.state.rowsPerPage;
			 cnt=Number(cnt)/Number(page);
		let copy=[50,100,150];
		
			/*for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push(val)
			}*/
			
	
	  this.setState({rowsPaging:copy});
	  
	 
     }
	 else{
		   this.setState({StoreData:[],totaldata:0});
	 }
  
    });
}

handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
  handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });  
  
}

render() {
	
	return (
		 <Page layout="public">

<Section slot="menu"> Vendor Report
</Section>

<Section slot="btn"><a href='#/configuration'>Configuration</a>
</Section>

<Section slot="content">
  
   <React.Fragment>
   
   
    <div className="main">
	
	<br></br>
 
  <p><TextField
           
            margin="normal"
            
            fullWidth
			value={this.state.cusemail}
            id="cusemail"
            variant="outlined"
            name="cusemail" onChange={this.myChangeHandler}
            autoComplete="cusemail"
			placeholder=" Name / Mobile / E-Mail"
            autoFocus
			
          />
		  </p>
		
	
	
	
	<Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            id="submitempform"
            className={useStyles.submit}
			onClick={this.handleSubmit}
			
          >
            Search
          </Button>	 
	
	<br/>
	<br/>
	
	
	
	
	
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  

		    <EnhancedTableHead
             
              
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.StoreData.length}
            />
                      <TableBody>

                     {stableSort(this.state.StoreData, getSorting(this.state.orderBy, this.state.order)).map((item,index) => {  
           return ( <React.Fragment>
 <TableRow key={index} >




<TableCell>{item.cu_contactname}</TableCell>

<TableCell>{item.cu_email}</TableCell>
<TableCell>{item.cu_mobileno}</TableCell>
<TableCell>{item.cu_phonenum}</TableCell>
<TableCell>{item.cu_panno}</TableCell>

<TableCell>{item.cu_gstinno}</TableCell>
<TableCell>{item.cu_billingassress}</TableCell>
<TableCell>{item.cu_shippingaddress}</TableCell>
<TableCell>{item.cu_dob}</TableCell>

<TableCell>{item.cu_weddinganniversary}</TableCell>
<TableCell>{item.cu_payment}</TableCell>
<TableCell>{item.pl_name}</TableCell>

<a className="link" href={"#/customerviewmore?cuid="+item.cu_id+""}>
<Tooltip title="View more">
 <IconButton aria-label="Viewmore">
<RemoveRedEyeIcon  />
 </IconButton>
 </Tooltip>


 </a>


	

 </TableRow>


				</React.Fragment>	
				);
					})}
					
					
	{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="13">No Records</TableCell>
					</TableRow>
					)}					
					
                      </TableBody>


</Table>
  </TableContainer> 
 <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
   
 

   </div>
 </React.Fragment>
		

		 </Section></Page>	 


);
 
  }	
   
   

}
 
		 