import React from 'react';
import axios from 'axios';   
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';

import MenuIcon from '@material-ui/icons/Menu';
import  { Redirect } from 'react-router-dom'

import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Serverurl from './Apiurl';
import Accordion from '@material-ui/core/Accordion';


import Displaymenu from './Displaymenu'
const createHistory = require("history").createBrowserHistory;

const useStyles = makeStyles((theme) =>({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },  
  
  
}));

export default function SwipeableTemporaryDrawer() {
		 
	
  const classes = useStyles();
    const [data, setData] = useState([]);   
  const [state, setState] = React.useState({
    
    redirect: false,
    left: false,
  });
   const [expanded, setExpanded] = React.useState(false);
   const handleChange = (panel) => (event, isExpanded) => {
	
	
	
	 
	 setExpanded(isExpanded ? panel : false);
	
  };



  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  
  
  
 
  
  

  


  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, true)}
      
    >
     

	  
	  
		  
		  
	  <Displaymenu />
	  
	
	

      
    </div>
  );

  return (
    <div>
      {[ 'left', ].map((anchor) => (
        <React.Fragment key={anchor}>
		
        <MenuIcon onClick={toggleDrawer(anchor, true)}/>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
