import React ,{Component} from 'react';


import { Page, Section } from 'react-page-layout';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Serverurl from './Apiurl';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';



const useStyles = makeStyles(theme => ({
 
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  
  form: {
    width: '500%', 
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
}));

export default class addproduct extends Component {
	
	constructor(props) {
    super();
	
	
	
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],redirect: false,
	 
	
	 
	search:'',order:'desc' ,orderBy:'pr_id',pr_id:0,primage:'',type:'',code:'',hsncode:'',name:'',description:'',category:'',unit:'',size:0,
	salesrateexcl:'',taxname:'',purchaserateincl:'',salesrateincl:'',minstock:'',vendor:'',openingstock:'',currentstock:'',
	taxarray:[],categoryarray:[], previd_icon:'',vendorarray:[],discount:'',btndisabled:false,
submitbtn:"Submit",  
	   
        
      }
	  this.handleBlur = this.handleBlur.bind(this);
	 
	 };


  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


componentDidMount() {
 
 if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
 
 else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "3" || localStorage.getItem("Rrole") === "4" )

	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  

	}
 
   /*let search = window.location.search;
let params = new URLSearchParams(search);
let prid = params.get('prid');*/

let search = window.location.href;
let prid = this.getParameterByName('prid',search);


if(prid!=null)
{
	
	setTimeout(()=>this.onloadproductbyid(prid),500); 
	//this.onloadtaxbind(prid);
	
}

this.adminoptioncall();


}





adminoptioncall(){
		
axios.get(Serverurl+'ajaxadminoption.php?action=list&ao_id=2')
 
  
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		 var currentYear = (new Date()).getFullYear();
		 	  let nxt=(currentYear-1)+'-'+(currentYear);
			this.setState({  
			
              code: resp.data[0].ao_type		  
			   
			});
			 
     }
  
    });
	this.onloadtaxbind();


		
	}

onloadtaxbind(){

    axios.get(Serverurl+"ajaxtaxes.php?action=list")
    
      .then((response) => {    
     
      this.setState({  taxarray: response.data,  });
	  this.onloadcategorybind();

      })   
    
      .then(data => {
        let roleFromApi = this.state.taxarray.map(taxname => {
                            
          return {value: taxname.tx_id, display:taxname.tx_name+" - "+taxname.tx_rate+"%", tid: taxname.tx_rate}
        });
      
		
        this.setState({
          taxarray: [{value: '0', display: 'Select Tax'}].concat(roleFromApi)
        });
      })


}



onloadcategorybind(){

    axios.get(Serverurl+"ajaxcategories.php?action=categoriesgetallforproduct")
    
      .then((response) => {    
     
      this.setState({  categoryarray: response.data,  });
	  this.onloadvendorbind();
      })   
    
      .then(data => {
        let roleFromApi = this.state.categoryarray.map(category => {
                            
          return {value: category.ca_id, display:category.ca_name}
        });
      
		
        this.setState({
          categoryarray: [{value: '0', display: 'Default'}].concat(roleFromApi)
        });
      })


}


onloadvendorbind(){

    axios.get(Serverurl+"ajaxcustomer.php?action=list&cu_contacttype=2")
   
      .then((response) => {    
     
      this.setState({  vendorarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.vendorarray.map(vendor => {
                            
          return {value: vendor.cu_id, display:vendor.cu_contactname}
        });
      
		
        this.setState({
          vendorarray: [{value: '0', display: 'Select vendor'}].concat(roleFromApi)
        });
      })


}

myChangeHandler = (event) => {
	
	const re = /^[0-9\b]+$/; 
	const renew = /^[0-9\b.]+$/; 
  let nam = event.target.name;
  let val = event.target.value;
  
  



  

  
 
  
  
  if( nam==="hsncode" ||  nam==="minstock" ||  nam==="currentstock")
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	  
  }
} if( nam==="purchaserateincl" || nam==="salesrateexcl" || nam==="salesrateincl" || nam==="discount")
  {if (event.target.value === '' || renew.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	
	  if(nam==="salesrateincl" )
	  {

		  let taxname = this.state.taxname;
  //let spercent=((Number(taxname))/100)
 // let sval=Number(val)*Number(spercent)
  //let sales=Number(val)-((Number(val)*Number(taxname))/ 100)
  //let sales=(Number(val)-Number(sval))
  
  
  //let gstamt = Number(val)-(Number(val) * (100 / (100 + Number(taxname) )))

  //let salexc = Number(val)-Number(gstamt)
  
		//this.setState({salesrateexcl: salexc});   
	  }
  }
  }
  else{
  this.setState({[nam]: val});
  
  if(nam==="taxname")
  {
	  
	  let idx = event.target.selectedIndex;
	 let taxvalue=event.target.options[idx].dataset.tname;
	//  alert(taxvalue)
	  
	  this.setState({["taxvalue"]: event.target.options[idx].dataset.tname });

//alert(this.state.taxvalue)
  }
  }
  
  
  
  
 

 }  
 

 handleBlur  = (event) => {
    
    event.preventDefault();
	

//alert(this.state.salesrateincl+"~"+this.state.taxvalue+"~"+this.state.taxname)
	let val=this.state.salesrateincl;
	let taxvalue = this.state.taxvalue;
    let taxname = this.state.taxname;

if(taxname==="")
{
	this.setState({errormessage:"Please Select Tax.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}	
	
	 
  let gstamt = Number(val)-(Number(val) * (100 / (100 + Number(taxvalue) )))
	
  let salexc = Number(val)-Number(gstamt)
  
		this.setState({salesrateexcl: salexc.toFixed(2)});   
        
	
 }
 
 
 
 
 

myRoleChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
 this.setState({[nam]: val});
  


}

handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
  
  
  
onloadproductbyid(prid){
		
		 axios.get(Serverurl+'ajaxproduct.php?action=list&pr_id='+prid)
  .then(resp => {  
   if(resp.data.length>0){
	   
    this.setState({ 
   	pr_id:resp.data[0].pr_id,
	primage:resp.data[0].pr_image,
	type:resp.data[0].type,	
	code:resp.data[0].pr_code, 
   
	hsncode:resp.data[0].pr_hsncode,
	name:resp.data[0].pr_name,
	description:resp.data[0].pr_description,
	category:resp.data[0].pr_ca_id,
	
	unit:resp.data[0].unit,
	size:resp.data[0].pr_size,
	salesrateexcl:resp.data[0].pr_salesrateexcl,
	
	taxname:resp.data[0].pr_tx_id,
	taxvalue:resp.data[0].tx_rate,
	
	purchaserateincl:resp.data[0].pr_purchaserateincl,
	salesrateincl:resp.data[0].pr_salesrateincl,
	minstock:resp.data[0].pr_minstock,
	vendor:resp.data[0].pr_vendor,
	
	openingstock:resp.data[0].pr_openingstock,
	currentstock:resp.data[0].pr_currentstock,
	discount:resp.data[0].pr_discount,
	
  
	});
	
   }
  });

} 


handleSubmit= (event) => {
    
    event.preventDefault();
	//alert('')
    
    let primage = this.state.primage;
    let type = this.state.type;
	let code = this.state.code;
    let hsncode = this.state.hsncode;
	let name = this.state.name;
    let description = this.state.description;
	let category = this.state.category;
	
	
	let unit = this.state.unit;
    let size = this.state.size;
	let salesrateexcl = this.state.salesrateexcl;
    
	
	let taxname = this.state.taxname;
	let purchaserateincl = this.state.purchaserateincl;
	let salesrateincl = this.state.salesrateincl;
	
	let minstock = this.state.minstock;
	
	let vendor = this.state.vendor;
	let openingstock = this.state.openingstock;
    let currentstock = this.state.currentstock;
    
	let discount = this.state.discount;
	
	
		
	
   
	


if(type==="")
{
	this.setState({errormessage:"Please Select Type.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(code==="")
{
	this.setState({errormessage:"Please Enter Product Code.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}	

if(category==="")
{
	this.setState({errormessage:"Please Select Category.",open:true});
	
	window.scrollTo({
          top: 0
      })
	  return false;
}


if (name==="") {
	  this.setState({errormessage:"Please Enter Product Name.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
  }

if(taxname==="")
{
	this.setState({errormessage:"Please Select Tax.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(purchaserateincl==="")
{
	this.setState({errormessage:"Please Enter Purchase Rate.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}
/*if(salesrateexcl==="")
{
	this.setState({errormessage:"Please Enter Sales Rate Exclusive.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}*/

if(salesrateincl==="")
{
	this.setState({errormessage:"Please Enter Sales Rate Inclusive.",open:true});
	
	window.scrollTo({
          top: 0
      })
	  return false;
}


if(unit==="")
{
	this.setState({errormessage:"Please Select Unit.",open:true});
	
	window.scrollTo({
          top: 0
      })
	  return false;
}

/*
if(discount==="")
{
	this.setState({errormessage:"Please Enter Discount.",open:true});
	
	window.scrollTo({
          top: 0
      })
	  return false;
}

*/


/*
if(vendor==="")
{
	this.setState({errormessage:"Please Select vendor.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}
*/

if(minstock==="")
{
	this.setState({errormessage:"Please Enter Minimum Stock.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(currentstock==="")
{
	this.setState({errormessage:"Please Enter Current Stock.",open:true});
	
	window.scrollTo({
          top: 0
      })
	  return false;
}
/*if(description==="")
{
	this.setState({errormessage:"Please Enter Product Disription.",open:true});
	return false;
}*/
/*if(openingstock==="")
{
	this.setState({errormessage:"Please Enter Opening Stock.",open:true});
	return false;
}*/


this.setState({btndisabled:true,submitbtn:"Processing..."})
 var formData = new FormData();
	 
	 if(this.state.pr_id===0)
	  {
		  formData.append('pr_id',0) 
		  
	  }
	  else
	  {
		  
	   formData.append('pr_id',this.state.pr_id)
		
          
	  }
	  
	
	  		 
	 //alert(this.state.pr_id)
	
//alert(name);
formData.append('action','insertorupdate') 


formData.append('pr_type',type)
formData.append('pr_code',code)
formData.append('pr_hsncode',hsncode)
formData.append('pr_name',name)
formData.append('pr_description',description)
formData.append('pr_ca_id',category)


formData.append('pr_unit',unit)
formData.append('pr_size',size)
formData.append('pr_salesrateexcl',salesrateexcl)


formData.append('pr_tx_id',taxname)
formData.append('pr_purchaserateincl',purchaserateincl)
formData.append('pr_salesrateincl',salesrateincl)
formData.append('pr_minstock',minstock)

formData.append('pr_vendor',vendor)
formData.append('pr_openingstock',openingstock)
formData.append('pr_currentstock',currentstock)

formData.append('pr_discount',discount)



	   
formData.append('userid',localStorage.getItem("Ruserid"))	
	 
	   	  axios.post(Serverurl+"ajaxproduct.php", formData, {
        }).then(res => {
			
			
		   if(res.data==-1)
{ 
	 

		this.setState({errormessage:"Product Code Already Exist.",open:true,btndisabled:false,submitbtn:"Submit"
		});
		window.scrollTo({
          top: 0
      })

	}else{
		this.setState({errormessage:"Record Udated Successfuly",open:true,btndisabled:false,submitbtn:"Submit",
		
			
			
			
			
			
			 redirect: true,pagename:"productbarcode?prid="+res.data,
			
		primage:"",
		type:"",
		code:'',
		hsncode:'',
		name:'',
		description:'',
		category:'',
		unit:'',
		
		size:"",
		salesrateexcl:'',
		taxname:'',
		
		
		
		purchaserateincl:'',
		salesrateincl:'',
		minstock:'',
		vendor:'',
		openingstock:'',
		currentstock:'',
		//previd_icon:'',
		discount:'',
		
		pr_id:0
		});
			
	}		
            });
			
};




Filedataimage=(e) =>{
	
	
	
	   var filedate=new Date(e.target.files[0].lastModified);
	   var todaydate=new Date();
	   var minutes = Math.abs(todaydate- filedate);
	 
	   
		 
	   let imageFile = e.target.files[0];
	   
	   if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
  alert( 'Please select valid file (jpg,jpeg,png).' );
    return false;
  }else{
        this.setState({ primage: e.target.files })
  }
	
	
}

 handleClickdiscard = (e) => {
 
  this.setState({ open:!this.state.open,  pr_id:0,primage:'',type:'',code:'',hsncode:'',name:'',description:'',category:'',unit:'',size:'',
	salesrateexcl:'',taxname:'',purchaserateincl:'',salesrateincl:'',minstock:'',vendor:'',openingstock:'',currentstock:'',
	taxarray:[],categoryarray:[], previd_icon:'',vendorarray:[],discount:''
	   
   
	  
		 });
		


};

	

render() {
	if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 


	
	return (
	
	<Page layout="public">

<Section slot="menu"> / <a href='#/configuration'>Configuration</a> / Product
</Section>

<Section slot="btn"><a href='#/adminproduct'>Back</a>
</Section>

<Section slot="content">
	
	
	
<div class="Productform">

	
<div class="main">


<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>

 



<div className="headername">
		 <p><b>  New Product</b></p>
		 </div>

<div className="productright">

      <ul>

<li className="dropdown">  
		<p>Product Type <span className="validation">*</span></p>
		<p> <FormControl  className={useStyles.formControl} >
  
		
        <Select
          native
		  variant="outlined"
            margin="normal"
         value={this.state.type}
          onChange={this.myRoleChangeHandler}
        name='type'
         id='type'>
		 
          <option value={0}>Type</option>
		  <option value={1}>Goods</option>
		  <option value={2}>Service</option>
		 
		  
          
        </Select>
      </FormControl>
		  </p>
		   
		
		</li>
		
	
		<li>		 
<p>Product Code <span className="validation">*</span></p> 
        <p>
		<TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.code}
            id="code"
           
            name="code" onChange={this.myChangeHandler}
            autoComplete="code"
			
            
			
          /></p></li>

		
		
		
		
		  
		<li>
		<p>HSN Code</p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.hsncode}
            id="hsncode"
          
            name="hsncode" onChange={this.myChangeHandler}
            autoComplete="hsncode"
            
			
          /></p></li>
		  
	 
		
		 
		 
		 <li className="dropdown">  
		 <p>Category <span className="validation">*</span></p>
		 <p><FormControl  className={useStyles.formControl} >
        
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.category }}
          onChange={this.myChangeHandler}
          id="category1"
		   value={this.state.category}
		  name="category"
		
		
        >
		{this.state.categoryarray.map(category => (
            <option  
            
              value={category.value}
            >
              {category.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
		 
		 </li>
		  
		  
		  
		  <li>
<p>Product Name <span className="validation">*</span></p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.name}
            id="name"
          
            name="name" onChange={this.myChangeHandler}
            autoComplete="name"
            
			
          /></p></li>
		  
		  
		  
		  <li className="dropdown"> 
<p>Tax <span className="validation">*</span></p>
		 <p><FormControl  className={useStyles.formControl} >
        
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.taxname }}
          onChange={this.myChangeHandler}
          id="tax1"
		   value={this.state.taxname}
		  name="taxname"
		
		
        >
		{this.state.taxarray.map(taxname => (
            <option  
            data-tname={taxname.tid}
              value={taxname.value}
            >
              {taxname.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p></li>
		  
		
 <li>  
		   <p>Purchase Rate(Tax Inclusive) <span className="validation">*</span></p>
		   <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.purchaserateincl}
            id="purchaserateincl"
           
            name="purchaserateincl" onChange={this.myChangeHandler}
            autoComplete="purchaserateincl"
		

            
			
          /></p></li>
		  
		  
		  <li> 
<p>Sales Rate(Tax Inclusive) <span className="validation">*</span></p>
		   <p>
		  <TextField
            variant="outlined"
            margin="normal"
          
            fullWidth
			value={this.state.salesrateincl}
            id="salesrateincl"
          
            name="salesrateincl" 
			onChange={this.myChangeHandler}
			 onBlur={this.handleBlur}
			
          /></p></li>
		  
		  
		  
		<li> 
<p>Sales Rate(Tax Exclusive) <span className="validation">*</span></p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.salesrateexcl}
            id="salesrateexcl"
          
            name="salesrateexcl" onChange={this.myChangeHandler}
            autoComplete="salesrateexcl"
			
            
			
          /></p></li>  
		  
		   

<li>
		<p>Discount (In Percentage)</p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.discount}
            id="discount"
          
            name="discount" onChange={this.myChangeHandler}
            autoComplete="discount"
			inputProps={{maxLength:2,  }} 
            
			
          /></p></li>


		
		 
		<li className="dropdown"> 
<p>Product Unit <span className="validation">*</span></p>		
		
		<p> <FormControl  className={useStyles.formControl} >
  
		
        <Select
          native
		  variant="outlined"
            margin="normal"
         value={this.state.unit}
          onChange={this.myRoleChangeHandler}
        name='unit'
         id='unit'>
		 
          <option value={0}>Select Unit</option>
		  <option value={1}>Meter</option>
		  <option value={2}>Number</option>
		  <option value={3}>Kg</option>
		 
		  
          
        </Select>
      </FormControl>
		  </p>
		   
		
		</li>
		 
		 
		 
		 <li>  
		 <p>Size</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.size}
            id="size"
           
            name="size" onChange={this.myChangeHandler}
            autoComplete="size"
			
            
			
          /></p></li>
		 
		
		 
		 
		  <li className="dropdown">  
		 <p>Vendor</p>
		 <p><FormControl  className={useStyles.formControl} >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.vendor }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.vendor}
		  name="vendor"
		
		
        >
		{this.state.vendorarray.map(vendor => (
            <option  
            
              value={vendor.value}
            >
              {vendor.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
		 
		 </li>
		  
		  
		
		  
		  
		  
		  
		  
		  
		  
		  
		  
		   <li> 
<p>Minimum Stock <span className="validation">*</span></p>
		   <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.minstock}
            id="minstock"
          
            name="minstock" onChange={this.myChangeHandler}
            autoComplete="minstock"
			
            
			
          /></p></li>
		  
		  
		  
		 
		 
		  
		  
		  <li> 
<p>Current Stock <span className="validation">*</span></p>
		   <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.currentstock}
            id="currentstock"
          
            name="currentstock" onChange={this.myChangeHandler}
            autoComplete="currentstock"
			
            
			
          /></p></li>
		  
		  
		  
		  
		  
		  
		  
		  
		 
		   </ul>
		
		  
		<div className="productaddress">
		
		
		
<p>Description</p>
		<p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.description}
            id="description"
			
            fullWidth
           
            name="description" onChange={this.myChangeHandler}
            autoComplete="description"
            autoFocus
          />  </p>
		
		
		</div>
		 
		
		
		<div className="formbtns">
		  <ul>
		
		  
		  <li> <p> <Button
            type="submit"
            halfWidth
            variant="contained" 
            color="primary"
            id="discard"
            className={useStyles.submit}
			onClick={this.handleClickdiscard}
          >
           Discard
          </Button></p></li><li> <p> <Button
            type="submit"
            halfWidth
			disabled={this.state.btndisabled}
            variant="contained" 
            color="primary"
            id="searchform"  onClick={this.handleSubmit}
            className={useStyles.submit}
          >
            {this.state.submitbtn}
          </Button></p></li>
		  </ul>
		  </div>   
		
		 
		   
	  </div>	   
		   	   
		  
		
		  
	
		 	  
	
		  
		 
		  
		 
		  
		   
		 


</div>

<div className="clear"></div>
          
</div>


	 
	 </Section></Page>		
		  
		 
		  




  );
  }
}










