import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  


import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';

import home from './images/home.png';
import configuration from './images/configuration.png';

import user from './images/user.png';
import contacts from './images/contacts.png';
import categories from './images/categories.png';
import warehouse from './images/warehouse.png';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}





const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class masterlayout extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] 
      }
	 };
    
 





  
render() {
	

  	 
    
  return (
   <React.Fragment>
   
   
    <div class="header">


 <div class="stockleft">
 <h3>STOCK-BOOK</h3>
 </div>

<div class="stockright">
<ul>
<li><h3>RASHINKAR'S </h3></li>
<li><p>Dashboard</p></li>
<li><p>Users</p></li>
<li><p>Configuration</p></li>
</ul>

</div>

  
 </div>

   <div className="clear"></div>
 
		
 <div class="menulist">
 <ul>
 <li><img src={home} /> Home</li>
 <li><img src={configuration} /> Configuration</li>
 <li>PEOPLE</li>
 <li><img src={user} /> Users</li>
 <li><img src={contacts} /> Contacts</li>
 <li>INVENTORY</li>
 <li><img src={categories} /> Categories</li>
 <li><img src={warehouse} /> Warehouses</li>
 <li><img src={configuration} /> Products</li>
 <li>PURCHASE</li>
 <li><img src={configuration} /> Purchase Orders</li>
 <li><img src={configuration} /> Purchase Bills</li>
 
</ul>
 </div>
 

 
 
   
  
   
 </React.Fragment>
		



  );
  }	
   
   


}