import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  
import { Page, Section } from 'react-page-layout';

import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';
import Uploadsurl from './Apiurl';
import Autocomplete from '@material-ui/lab/Autocomplete';

import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';

import Tooltip from '@material-ui/core/Tooltip';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';

import TableFooter from '@material-ui/core/TableFooter';

import ReactExport from "react-export-excel";

import ReactToPrint from 'react-to-print';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';

import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';


let strCus=[{title:'',year:0}];
const strCusdeflt=[];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [

{ id: 'po_createddate', numeric: false,  label: 'Date' },
{ id: 'po_billno', numeric: false,  label: 'Invoice No' },
{ id: 'subtotal', numeric: false,  label: 'Total' },



]

function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
  
  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Vendoroutstandingreport extends Component {
	
	
	constructor(props) {
    super();
	
	
	var date = addDays(moment().toDate(),0);
   var month="",  month1="",date1="";
  if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate())		 
	 }else{date1=(date.getDate() )}
	 
	 
	    var strtodate = addDays(moment().toDate(),0);
   var strmonth="",  month1="",strdate1="";
  if((strtodate.getMonth() + 1)<10)
	 {
		strmonth="0"+ (strtodate.getMonth() + 1)		 
	 }else{strmonth=(strtodate.getMonth() + 1)}
	  if((strtodate.getDate() )<10)
	 {
		strdate1="0"+ (strtodate.getDate())		 
	 }else{strdate1=(strtodate.getDate() )}
	
	
	
	
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:-1,totaldata:0,rowsPerPage:50,rowsPaging:[],
	 
	 
	search:'',order:'desc' ,orderBy:'cu_id',name:'',cu_id:0,date:'',
	invoice:'', searcharray:[],custtags:[],customer:'',cuid:'',VendorData:[],exportData:[],
	 
	date: addDays(moment().toDate(),0),
	fdate:addDays(moment().toDate(),0),tdate:addDays(moment().toDate(),0),
fdate1:date.getFullYear() + '-' + month + '-' +date1,tdate1:strtodate.getFullYear() + '-' + strmonth + '-' +strdate1,
btndisabled:false,
submitbtn:"Submit", orlogo:'',	
  orphonenum:'',
	  
	 
        
      }
	  
	  
	   this.onTagsChange = this.onTagsChange.bind(this);
	  
	 };
	 
	 
handfrChange = (date) => {
	 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }	 
	 
	
handtoChange = (date) => {
	  var month="",date1="";
	 
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)} 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
	  var strtotime=this.state.fdate1.split(' ');
	 
	if(strtotime[0]>date.getFullYear() + '-' + month + '-' +date.getDate())
	{
		 var strtoday=this.state.fdate1.split('-');
		 if(strtoday[0]>date.getFullYear() && strtoday[1]>month && strtoday[2]>date.getDate())
	{
	alert("Please Select To date greater than equal to")
	return false;
	}
	}
	 
     this.setState({ tdate:date,
tdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }

	
onloadCustomerBind=(event)=>{
	// axios.get(Serverurl+'ajaxcustomer.php?action=searchextender')
	  axios.get(Serverurl+'ajaxcustomer.php?action=searchextender&cu_contactname='+event.target.value)

  .then(resp => { 
  this.setState({ searcharray:resp.data});

	 var rowsArray1 = this.state.searcharray;
	 rowsArray1.map((row, k) => {
			 
		 const items = {
			 
        title:row.title,
         year:row.year
          
        };
         strCus.push( items)
		
		 });
  });
  
  
}
	 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  
  localStorage.removeItem("fromdate");
   localStorage.removeItem("todate");
  
  this.setState({[nam]: val});

 } 
 
 

 
 
 
handleSubmit= (event) => {
    
    event.preventDefault();
	//alert('')
  
let customer = this.state.customer;
	
	let fdate = this.state.fdate1;
	let tdate = this.state.tdate1;
	
	
	
	this.apicall(this.state.page,this.state.rowsPerPage);
	
} 


 componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}

componentDidMount() {
	
	document.title = "";
	
	
	if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
	else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "3" || localStorage.getItem("Rrole") === "4" )

	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  

	}
 localStorage.setItem("page",this.state.rowsPerPage);
  this.apicall(this.state.page,this.state.rowsPerPage); 

 this.orgviewmore(); 

}	

orgviewmore(){
	
	axios.get(Serverurl+'ajaxorganization.php?action=orgviewmore&or_id=1')
	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  orgviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	this.setState({		
    orname:resp.data[0].or_name,	
	oraddress:resp.data[0].or_address, 
   
	orcity:resp.data[0].or_city,
	oremail:resp.data[0].or_email,
	ormobilenum:resp.data[0].or_mobilenum,
	orpannum:resp.data[0].or_pannum,
	
	orgstinnum:resp.data[0].or_gstinnum,
	orlogo:resp.data[0].or_logo,
	orphonenum:resp.data[0].or_phonenum,
	 }); 
	 
     }
 // this.viewmoreorder();
    });
}

 

apicall(page,rowsPerPage){
	
	this.setState({btndisabled:true,submitbtn:"Processing..."})	
	
	axios.get(Serverurl+'ajaxvendoroutstanding.php?cu_contactname='+this.state.customer+'&fromdate='+this.state.fdate1+'&todate='+this.state.tdate1+'&cu_id='+this.state.cuid+'&sorting='+this.state.orderBy +' '+this.state.order+'&StartIndex='+page+'&PageSize='+rowsPerPage)
  	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  //StoreData: resp.data, 
VendorData: resp.data,  			  
			   totaldata:parseInt(resp.data[0].count)
			});
			let cnt=this.state.totaldata;
			let page=this.state.rowsPerPage;
			 cnt=Number(cnt)/Number(page);
		let copy=[50,100,150];
		
		this.setState({rowsPaging:copy,btndisabled:false,submitbtn:"Search"});
	 
     }else{this.setState({  
	 VendorData:[],totaldata:0,
	 btndisabled:false,submitbtn:"Search"
	// btnsearch:"Search",
	// loadingpanel:'0',
	 });}
  
    });
	
	this.exportapicall();
	
}


exportapicall(){
	
		axios.get(Serverurl+'ajaxvendoroutstanding.php?cu_contactname='+this.state.customer+'&fromdate='+this.state.fdate1+'&todate='+this.state.tdate1+'&cu_id='+this.state.cuid)
  	
  
	 .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  exportData: resp.data,  
			
			  
			   totaldata:parseInt(resp.data[0].count),
			   
			   
			   
			});
			
		
	 
     }
  
    });
	

	
}



handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
  handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });  
  
}


onTagsChange = (event, values) => {
	
	let optionsval= values["year"];
	let optionsval1= values["title"];

	
	 
    this.setState({
      custtags: values,
	   customer: values["title"]
	   
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
     
    });
  }



render() {
	let	grandtotal=0;	
	return (
	
	
	 <Page layout="public">

<Section slot="menu"> / Report / Vendor Outstanding Report
</Section>

<Section slot="btn"><a href='#/Reports'>Reports</a>


<ReactToPrint
            trigger={() => <p id="print-barcode" className="printreport"> Print </p>}
            content={() => this.componentRef}
			
          />

 <ExcelFile element={<button class="expbtn">Excel</button>}>

        <ExcelSheet data={this.state.exportData} name="Customer Outstanding Report">
		<ExcelColumn label="Date" value="po_createddate"/>
        <ExcelColumn label="Bill No" value="po_billno"/>
        <ExcelColumn label="Total" value="subtotal"/>
	     <ExcelColumn label="Paid" value="paid"/>
		

    
                </ExcelSheet> 

 				
            </ExcelFile>


</Section>

<Section slot="content">
  
  
     <div className="main">
  
  
   <React.Fragment>
   
   
	<br></br>
 
 <div className="search">
 
  <ul>
  
  <li><p>From :</p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth
        value={this.state.fdate || addDays(moment().toDate(),1)}
        onChange={this.handfrChange}
		maxDate={addDays(moment().toDate(),0)}
            format="dd/MM/yyyy"
        autoOk name="fdate"
        ampm={false}
	id="borderdate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		</li>
 
 <li><p>To :</p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
      <DatePicker fullWidth
        value={this.state.tdate || addDays(moment().toDate(),1)}
		maxDate={addDays(moment().toDate(),0)}
        onChange={this.handtoChange}
            format="dd/MM/yyyy"
        autoOk name="tdate"
        ampm={false}
		id="borderdate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
	
		</li>
 
 
<li className="lialignment"> <Autocomplete
       
        
      
       margin="normal"
      
            fullWidth
	   options={this.state.searcharray}
        getOptionLabel={option => option.title}
		//options={strCus}
   //defaultValue={strCusdeflt}
	  onChange={this.onTagsChange}  
        renderInput={params => (
          <TextField {...params} onChange={this.onloadCustomerBind}  variant="outlined" id="search" placeholder="Vendor" />
        )}
      /></li>
 
 
 
 </ul>
		
	
	
	<br/>
	
	<p className='submitrep'><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            id="searchform"
            className={useStyles.submit}
			onClick={this.handleSubmit}
			disabled={this.state.btndisabled}
          >
           {this.state.submitbtn}
          </Button>	</p> 
	
	<br/>
	<br/>
	
	
<div className="">
	
		<div ref={el => (this.componentRef = el)} >
	
	 <div className="reportheading">
		
       <b>VENDOR OUTSTANDING REPORT</b>
           </div>
		   
		<div className="repborder">   
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  	
	
	
	
	<TableRow >  
<TableCell colSpan={2} >


<div className="reportmain">
<div className="repfromdetails">


	<p><b>{this.state.orname}</b><br/>
	{this.state.oraddress} <br/> 
	{this.state.orcity} <br/>

{this.state.orgstinnum}<br/>
	{this.state.orphonenum} , {this.state.ormobilenum}

	<br/>{this.state.oremail}</p>
	</div>
	
	
	

	
	<div className="replogo">
	
	{this.state.orlogo!=="" &&(
 <p> <img  src={Uploadsurl+"uploads/"+this.state.orlogo} /> </p>
 )}
 
	</div>
	<div clssName="clear"></div>
	
	
	</div>
	</TableCell>
	
	


	
	
	
	
	
 </TableRow> 
 

 </Table>
 </TableContainer>
 </Paper>
 
 </div>
	
     
	
	
	<div className="main">
	
<Paper className={useStyles.root}>  
      <TableContainer className={useStyles.container}>  
        <Table stickyHeader aria-label="sticky table">  
        <TableHead>  
            <TableRow> 
		
	
        	
   
            </TableRow>  
          </TableHead>  
          <TableBody>  
            {this.state.VendorData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row,index) => {  
               
		  let grandtotal=0;	
		  let dueamt=0;
			   return ( 
                 <React.Fragment>
				 
				 <TableRow colSpan={2} >  
			
					
          

		  
<TableCell>		   
		   

<p>Customer Name : <b>{row.cu_contactname}</b></p>
<p>Phone : {row.cu_phonenum}</p>
<p>GSTIN : {row.cu_gstinno}</p>


</TableCell>

	   
		   
			
              </TableRow> 
				 
				 
				
              
  <TableRow className="expandable" ref="expanderBody" key={"tr-expander"}> 
      <TableCell className="uk-background-muted" colSpan={2}> 
      <TableContainer className={useStyles.container}>  
        <Table stickyHeader aria-label="sticky table"> 
        <TableHead>  
            <TableRow>   
            
       
             <TableCell ></TableCell> <TableCell ></TableCell> <TableCell ></TableCell> 
               
			   
			   <TableCell ><b>Date</b></TableCell>
			   <TableCell ><b>Invoice No</b></TableCell>			   
               <TableCell ><b>Total</b></TableCell>  
               
<TableCell ><b>Paid</b></TableCell> 
 <TableCell ><b>Due</b></TableCell> 
				 
              
               
            </TableRow>  
          </TableHead>  
      {this.state.VendorData[index].subvendor.map((roweach) => {
dueamt=(Number(roweach.subtotal)-Number(roweach.paid)).toFixed(2)
 grandtotal=(Number(grandtotal)+Number(roweach.subtotal)).toFixed(2)
              return ( 
     
        
          <TableBody>
		  
          <TableRow  > 
       
  <TableCell ></TableCell> <TableCell ></TableCell>  <TableCell ></TableCell> 
      
          <TableCell>{roweach.po_createddate}</TableCell>
           <TableCell>{roweach.po_billno}</TableCell>
           
            <TableCell>Rs. {roweach.subtotal}</TableCell>
			
        	<TableCell>Rs. {roweach.paid}</TableCell> 
			<TableCell>Rs. {dueamt}</TableCell> 
        </TableRow>
		
		
		
		
		
		
	</TableBody> 
        
              )})}
			  
			<TableFooter>  <TableRow  > 
	  <TableCell ></TableCell> <TableCell ></TableCell>  <TableCell ></TableCell> 		
			


<TableCell></TableCell>
<TableCell><b className="bold">Total</b></TableCell>
<TableCell><b className="bold">Rs. {grandtotal}</b></TableCell>
  </TableRow  > </TableFooter>	  
			  
			  
			  
              </Table> 
           </TableContainer>
                 </TableCell>
           </TableRow> 
     
              </React.Fragment>   
              );  
            })}  
			
			
		{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="2">No Records</TableCell>
					</TableRow>
					) }
			
          </TableBody>  
        </Table>  
      </TableContainer>  
      <TablePagination  
        rowsPerPageOptions={[50, 100, 150]}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />  
    </Paper> 
	
	</div>

</div>
	
	
	</div></div>
	
</React.Fragment>

</div>
</Section>
</Page>
   
 

	 	


);
 
  }	
   
   

}
 
		 