import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  
import { Page, Section } from 'react-page-layout';

import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';



import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';

import Tooltip from '@material-ui/core/Tooltip';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';

import TableFooter from '@material-ui/core/TableFooter';

import ReactExport from "react-export-excel";

import ReactToPrint from 'react-to-print';
import Uploadsurl from './Apiurl';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';

import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [

{ id: 'or_createddate', numeric: false,  label: ' Invoice Date' },
{ id: 'maxinvoice', numeric: false,  label: 'Invoice No.' },
{ id: 'mininvoice', numeric: false,  label: 'Invoice No.' },

{ id: 'netamount', numeric: false,  label: 'Net Amount' },


]

function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
  
  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class  Billgstreport extends Component {
	
	
	constructor(props) {
    super();
	
	
	var date = addDays(moment().toDate(),0);
   var month="",  month1="",date1="";
  if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate())		 
	 }else{date1=(date.getDate() )}
	 
	 
	    var strtodate = addDays(moment().toDate(),0);
   var strmonth="",  month1="",strdate1="";
  if((strtodate.getMonth() + 1)<10)
	 {
		strmonth="0"+ (strtodate.getMonth() + 1)		 
	 }else{strmonth=(strtodate.getMonth() + 1)}
	  if((strtodate.getDate() )<10)
	 {
		strdate1="0"+ (strtodate.getDate())		 
	 }else{strdate1=(strtodate.getDate() )}
	
	
	
	
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:10,rowsPaging:[],
	 
	 
	search:'',order:'desc' ,orderBy:'or_id',name:'',op_id:0,productname:'',categoryname:'',date:'',
	product:'',productarray:[],category:'',categoryarray:[],CategoryData:[],
	 
	date: addDays(moment().toDate(),0),
	fdate:addDays(moment().toDate(),0),tdate:addDays(moment().toDate(),0),
fdate1:date.getFullYear() + '-' + month + '-' +date1,tdate1:strtodate.getFullYear() + '-' + strmonth + '-' +strdate1,
	
  btndisabled:false,invoice:'',orlogo:'',
submitbtn:"Submit", orphonenum:'',
	  
	 
        
      }
	 };
	 
	 
handfrChange = (date) => {
	 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }	 
	 
	
handtoChange = (date) => {
	  var month="",date1="";
	 
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)} 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
	  var strtotime=this.state.fdate1.split(' ');
	 
	if(strtotime[0]>date.getFullYear() + '-' + month + '-' +date.getDate())
	{
		 var strtoday=this.state.fdate1.split('-');
		 if(strtoday[0]>date.getFullYear() && strtoday[1]>month && strtoday[2]>date.getDate())
	{
	alert("Please Select To date greater than equal to")
	return false;
	}
	}
	 
     this.setState({ tdate:date,
tdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }

	



	 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  
  localStorage.removeItem("fromdate");
   localStorage.removeItem("todate");
  
  this.setState({[nam]: val});

 } 
 
 

 
 
 
handleSubmit= (event) => {
    
    event.preventDefault();
	//alert('')
  

	let invoice = this.state.invoice;
	
	let fdate = this.state.fdate1;
	let tdate = this.state.tdate1;
	
	
	
	this.apicall(this.state.page,this.state.rowsPerPage);
	
} 


 componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}

componentDidMount() {
	
	document.title = "";
	
	
	if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
	else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "3" || localStorage.getItem("Rrole") === "4" )

	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  

	}
 localStorage.setItem("page",this.state.rowsPerPage);
 
  this.orgviewmore(); 
//this.onloadproductbind();

}	 



orgviewmore(){
	
	axios.get(Serverurl+'ajaxorganization.php?action=orgviewmore&or_id=1')
	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  orgviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	this.setState({		
    orname:resp.data[0].or_name,	
	oraddress:resp.data[0].or_address, 
   
	orcity:resp.data[0].or_city,
	oremail:resp.data[0].or_email,
	ormobilenum:resp.data[0].or_mobilenum,
	orpannum:resp.data[0].or_pannum,
	
	orgstinnum:resp.data[0].or_gstinnum,
	orlogo:resp.data[0].or_logo,
	orphonenum:resp.data[0].or_phonenum,
	
	 }); 
	 
     }
  this.apicall(this.state.page,this.state.rowsPerPage);
    });
}
	

apicall(page,rowsPerPage){
	
	
	this.setState({btndisabled:true,submitbtn:"Processing..."})	
	
  	
	axios.get(Serverurl+'ajaxbillgstreport.php?fromdate='+this.state.fdate1+'&todate='+this.state.tdate1+'&or_invoice='+this.state.invoice+'&sorting='+this.state.orderBy +' '+this.state.order+'&StartIndex='+page+'&PageSize='+rowsPerPage)
  	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		let copy=[10,50,100,150];
			this.setState({  
			  //StoreData: resp.data, 
CategoryData: resp.data,  			  
			   totaldata:parseInt(resp.data[0].count),
			   rowsPaging:copy,
	
	btndisabled:false,submitbtn:"Search"
			});
			
		
		
		
	

	  
	 
     }else{this.setState({  
	 CategoryData:[],totaldata:0,
	 btndisabled:false,submitbtn:"Search"
	// btnsearch:"Search",
	// loadingpanel:'0',
	 });}
  
    });
	

	
}



exportapicall(){
	
	
	
  	
	axios.get(Serverurl+'ajaxbillgstreport.php?fromdate='+this.state.fdate1+'&todate='+this.state.tdate1+'&or_invoice='+this.state.invoice)
  	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  exportData: resp.data, 
			  
			   
			});
			
			
	
	 
	  
	 
     }
  
    });
	
	
	
}



handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage, prevpage:-1,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
  handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });  
  
}

render() {
	
	return (
	
	
	 <Page layout="public">

<Section slot="menu">/ Report / Bill GST Report
</Section>

<Section slot="btn"><a href='#/Reports'>Reports</a>

<ReactToPrint
            trigger={() => <p id="print-barcode" className="printreport"> Print </p>}
            content={() => this.componentRef}
			
          />


  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="expbtn"
                    table="purreport"
                    filename="BillGST Report"
                    sheet="Bill GST Report"
                    buttonText="Excel"/>


</Section>

<Section slot="content">
  
   <React.Fragment>
   
   
   
	
	<br></br>
 
 <div className="search">
 
  <ul>
  
  <li><p>From :</p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth
        value={this.state.fdate || addDays(moment().toDate(),1)}
        onChange={this.handfrChange}
		maxDate={addDays(moment().toDate(),0)}
            format="dd/MM/yyyy"
        autoOk name="fdate"
        ampm={false}
	id="borderdate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		</li>
 
 <li><p>To :</p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
      <DatePicker fullWidth
        value={this.state.tdate || addDays(moment().toDate(),1)}
		maxDate={addDays(moment().toDate(),0)}
        onChange={this.handtoChange}
            format="dd/MM/yyyy"
        autoOk name="tdate"
        ampm={false}
		id="borderdate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
	
		</li>
		<li className="lialignment">
		
		
		<TextField
            //variant="outlined"
            margin="normal"
            variant="outlined"
            fullWidth
			value={this.state.invoice}
            id="invoice"
           
            name="invoice" onChange={this.myChangeHandler}
            autoComplete="invoice"
			placeholder="Invoice No"
            autoFocus
			
          />
		 
		
		</li>
 
 

 
 
 
 
 </ul>
		
	
	
	<br/>
	
	<p className='submitrep'><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
             id="searchform"
            className={useStyles.submit}
			onClick={this.handleSubmit}
			disabled={this.state.btndisabled}
			
          >
           {this.state.submitbtn}
          </Button>	</p> 
	
	<br/>
	<br/>
	
	
	
	<div className="main">
	
	  <div className="reportheading">
		
       <b>BILL GST REPORT</b>
           </div>
	
	
	
	
	
	
	
	<div ref={el => (this.componentRef = el)} >
	
	
	
	   
		<div className="repborder">   
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  	
	
	
	
	<TableRow >  
<TableCell colSpan={2} >


<div className="reportmain">
<div className="repfromdetails">

<p><b>{this.state.orname}</b><br/>
	{this.state.oraddress} <br/> 
	{this.state.orcity} <br/>

{this.state.orgstinnum}<br/>
	{this.state.orphonenum} , {this.state.ormobilenum}

	<br/>{this.state.oremail}</p>
	</div>
	
	
	

	
	<div className="replogo">
	
	{this.state.orlogo!=="" &&(
 <p> <img  src={Uploadsurl+"uploads/"+this.state.orlogo} /> </p>
 )}
 
	</div>
	<div clssName="clear"></div>
	
	
	</div>
	</TableCell>
	
	


	
	
	
	
	
 </TableRow> 
 

 </Table>
 </TableContainer>
 </Paper>
 
 </div>
	
	
	
	
	
	
<Paper className={useStyles.root}>  
      <TableContainer className={useStyles.container}>  
       
		
  
        <Table stickyHeader aria-label="sticky table"  cellspacing="1" cellpadding="5" id="purreport">  
		
          
			<TableHead > <TableRow >
			 
			 <TableCell> <b>Invoice Date</b></TableCell>
               <TableCell> <b>Invoice Number</b></TableCell>
			    <TableCell> <b>Invoice Number</b></TableCell>
			    <TableCell ><b>Net Amount</b></TableCell>
				<TableCell ><b>Others</b></TableCell>
				<TableCell colspan="3" ><b>GST 0.0%</b></TableCell>
				<TableCell colspan="3" ><b>GST 5.0%</b></TableCell>
				<TableCell colspan="3" ><b>GST 12.0%</b></TableCell>
				<TableCell colspan="3" ><b>GST 18.0%</b></TableCell>
				<TableCell colspan="3" ><b>GST 28.0%</b></TableCell>
				
				</TableRow></TableHead>
				
			 <TableHead>	<TableRow >
				
			<TableCell colspan="5"></TableCell>
			
			
				<TableCell><b>Basic </b></TableCell>
				<TableCell><b>CGST 0.0%</b></TableCell>
				<TableCell><b>SGST 0.0%</b></TableCell>
				<TableCell><b>Basic </b></TableCell>
				<TableCell><b>CGST 2.5%</b></TableCell>
				<TableCell><b>SGST 2.5%</b></TableCell>
				<TableCell><b>Basic </b></TableCell>
				<TableCell><b>CGST 6.0%</b></TableCell>
				<TableCell><b>SGST 6.0%</b></TableCell>
				<TableCell><b>Basic </b></TableCell>
				<TableCell><b>CGST 9.0%</b></TableCell>
				<TableCell><b>SGST 9.0%</b></TableCell>
				<TableCell><b>Basic </b></TableCell>
				<TableCell><b>CGST 14.0%</b></TableCell>
				<TableCell><b>SGST 14.0%</b></TableCell>
				
				
				
				</TableRow></TableHead>
				
 			   
        	
   
               <TableBody>
            
            {this.state.CategoryData.map((row,index) => {  
             
			   let	grandtotal=0;	
	let basic5=0;
	let cgst5=0;
	let basic12=0;
	let cgst12=0;
	let basic18=0;
	let cgst18=0;
	let basic0=0;
	let cgst0=0
	let basic28=0;
	let cgst28=0;
	let gst5percent=0;
	let gst12percent=0;
	let gst18percent=0;
	let gst0percent=0;
	let gst28percent=0;
		  let totalgstamt=0;
			   return ( 
                 <React.Fragment>
          
   <TableRow  className="bordertbl">  
               
   <TableCell >{row.or_createddate}</TableCell>
      <TableCell>{row.mininvoice}</TableCell>
   <TableCell>{row.maxinvoice}</TableCell>
 
			
             
        
      {row.subbillgst.map((roweach,i) => {
	//totalgstamt=Number(totalgstamt)+Number(roweach.or_taxamount);
	totalgstamt=Number(roweach.or_taxamount);
		  if(roweach.op_tax_value == 5)
		  {
			  basic5=Number(roweach.basicamt);
			    gst5percent=Number((roweach.op_tax_value)/2)
			  cgst5=(Number(roweach.basicamt)*Number(gst5percent)/100).toFixed(2)
			
			    
		  }
		  if(roweach.op_tax_value == 12)
		  {
			  basic12=Number(roweach.basicamt);
			    gst12percent=Number((roweach.op_tax_value)/2)
			  cgst12=(Number(roweach.basicamt)*Number(gst12percent)/100).toFixed(2)
			
			 
		  }
		  if(roweach.op_tax_value == 18)
		  {
			  basic18=Number(roweach.basicamt);
			  gst18percent=Number((roweach.op_tax_value)/2)
			  cgst18=(Number(roweach.basicamt)*Number(gst18percent)/100).toFixed(2)
			  
		  }
		    if(roweach.op_tax_value == 28)
		  {
			  basic28=Number(roweach.basicamt);
			  gst28percent=Number((roweach.op_tax_value)/2);
			  cgst28=(Number(roweach.basicamt)*Number(gst28percent)/100).toFixed(2)
			  
		  }
		   else 
		  {
			  basic0=Number(0);
			  cgst0=(Number(0)*Number(0)/100)
			  gst0percent=Number((0)/2)
		  }

 //grandtotal=(Number(grandtotal)+Number(roweach.totalamount)).toFixed(2)
              return ( 
      <React.Fragment>
        
   
      
            
			
        
       
		
		
		
		
	
        
             </React.Fragment>  )})}
			    <TableCell >Rs. {Number(row.netamount) + Number(totalgstamt)}</TableCell>
   <TableCell >Rs. {row.or_roundoff}</TableCell>
			<TableCell>Rs. {basic0}</TableCell>
            <TableCell>Rs. {cgst0}</TableCell>
            <TableCell>Rs. {cgst0}</TableCell>
			<TableCell>Rs. {basic5}</TableCell>
            <TableCell>Rs. {cgst5}</TableCell>
            <TableCell>Rs. {cgst5}</TableCell>
			<TableCell>Rs. {basic12}</TableCell>
            <TableCell>Rs. {cgst12}</TableCell>
            <TableCell>Rs. {cgst12}</TableCell>
			<TableCell>Rs. {basic18}</TableCell>
            <TableCell>Rs. {cgst18}</TableCell>
            <TableCell>Rs. {cgst18}</TableCell>
			<TableCell>Rs. {basic28}</TableCell>
            <TableCell>Rs. {cgst28}</TableCell>
            <TableCell>Rs. {cgst28}</TableCell>
			  
			  </TableRow>
             
     
              </React.Fragment>   
              );  
            })}  
			
			
		{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="13">No Records</TableCell>
					</TableRow>
					) }
			
          
       </TableBody>  
        </Table>  
      </TableContainer>  
      <TablePagination  
        rowsPerPageOptions={[50, 100, 150]}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />  
    </Paper>  
	
	</div>
	</div>
	</div>
	
	
</React.Fragment>


</Section>
</Page>
   
 

	 	


);
 
  }	
   
   

}
 
		 