import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  

import { Page, Section } from 'react-page-layout';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';


import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import DateFnsUtils from '@date-io/date-fns';
import TableBody from '@material-ui/core/TableBody'; 
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import Tooltip from '@material-ui/core/Tooltip';




let strCust=[{title:'',year:0}];



const strProduct=[];
const strProductexchange=[];
const strProductdeflt='';


const useStyles = makeStyles(theme =>({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
 
form: {
    width: '500%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
})
)



export default class Addinvoice extends Component {
	
	
	constructor(props) {
    super();
	var today = new Date();
	var todayMonth=(today.getMonth() + 1);
	if(todayMonth<10)
		todayMonth='0'+todayMonth;
    var        cudate = today.getFullYear() + '-' + todayMonth + '-' + today.getDate();
	
	
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,
rowsPerPage:20,rowsPaging:[],invoicearray:[],invoice:'18',invoicelabel:'Select Invoice Type',
invoiceno:'',payment:'1',paymentarray:[],fdate:Date.now(),fdate1:cudate,gstin:'',
salespersonarray:[], salesperson:'0',adminData:[],custtags:'',
custarray:[],stat:'0',statarray:[],dest:'0',scancode:'',pvdata:[],taxdata:[],taxdatacount:0,
	 barcodeval:'',salesrateexcl:'',salesrateincl:'',orddiscount:'',qty:'',newpcid:"-1",ordgst:0,
amount:'',produserarray:[],taxarray:[],tax:'',barcodedata:[],prdextndata:[],
customer:[],redirect:false,roundoff:0,billingassress:'',shippingaddress:'',
	
	 pr_salesrateexcl:'',pr_salesrateincl:'',pr_tx_id:'',orddiscounttype:1,editclick:0,
subtotal:0,roundoffval:0,discountval:'',ordtotaldiscount:0,salesdata:[],
salespersonname:'',dcus:'',errormessage:'',open:false,selectedcustomerid:'',paymentname:'',orderproduct:0,
 btndisabled:false,inveditData:[],ordertype:1,comment:'',exchangepvdata:'',
submitbtn:"Submit",  ordertypedisplay:"1",selectedproductexchange:'',
exchangeproductdisplay:"0",exchangeamt:0,exchangediscount:0,produserarray1:[]
	   

	        
      }
	  
	  this.onTagsChange = this.onTagsChange.bind(this);
	    this.insertDonenewParent = this.insertDonenewParent;
	   this.onTagsproductChange = this.onTagsproductChange.bind(this);
	     this.onTagsproductChangeexchange = this.onTagsproductChangeexchange.bind(this);
	  this.escFunction = this.escFunction;
	   this.escSalesFunction = this.escSalesFunction;
	   
	    this.ChildElement = React.createRef();
	 };
	 
	 
 insertDonenewParent =(text)=>{
	 
	 
	//alert(localStorage.getItem("newcuid")) 
   this.onloaduserbyid(localStorage.getItem("newcuid"));
	 
 }

 Useraddbox(val){
       // this.setState({ cuid: val });  
  
         setTimeout(() => this.refs.Newcustomer.handleClickOpen(),500);

      }
componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
 // this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}

 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

componentDidMount() {

let search = window.location.href;
let orid = this.getParameterByName('orid',search);

	
if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  
		  
		  
		  }


if(orid!=="" && orid!==null)
{
	
	 this.Invoiceorderbyid(orid);
}
else{
		this.adminoptioncall();
}

	
this.textSP.focus();	
 localStorage.setItem("page",this.state.rowsPerPage);
 // this.apicall(this.state.page,this.state.rowsPerPage); 
 this.UsergetPVdata();

 document.addEventListener("keydown", this.escFunction, false);
  document.addEventListener("keydown", this.escSalesFunction, false);
  
 
	}
	
	componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
	document.removeEventListener("keydown", this.escSalesFunction, false);
  }
  
  myChangeHandler = (event) => {
	
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
if(nam==="payment")
  {
	 
	  let idx = event.target.selectedIndex;
	 let paymentname=event.target.options[idx].dataset.pname;
	//  alert(paymentname)
	  
	  this.setState({["paymentname"]: event.target.options[idx].dataset.pname});

//alert(this.state.paymentname)
  }
  
 if(nam==="ordertype" && val==="2")
 {
	this.setState({exchangeproductdisplay :"1"})
 }
 else{
	 this.setState({exchangeproductdisplay :"0"})
 }

}

Invoiceorderbyid(orid){
	
axios.get(Serverurl+'ajaxorders.php?action=invoiceeditbyorid&or_id='+orid)
 
  
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		
		
		this.onloaduserbyid(resp.data[0].or_cu_id)	;
		setTimeout(() => {this.setState({  
			  inveditData: resp.data,
              invoice: resp.data[0].or_invoicetype,
              invoiceno: resp.data[0].or_invoice,
              fdate: resp.data[0].invoicedate,
              payment: resp.data[0].or_paymentterms,
              salespersonname: resp.data[0].us_username,
			  salesperson:resp.data[0].or_sp_id,
              gstin: resp.data[0].cu_gstinno,
              customer: resp.data[0].cu_contactname,
              billingassress: resp.data[0].cu_billingassress,
              shippingaddress: resp.data[0].cu_shippingaddress,
              dest: resp.data[0].or_destination,
			  comment: resp.data[0].or_comment,
			  ordertypedisplay:"0",
              			  
			   
			});}, 800);
		
	}
  });	
	
	
}





  
	adminoptioncall(){
		
	
		
		
	
axios.get(Serverurl+'ajaxadminoption.php?action=list&ao_id=1,3')
 
  
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		 var currentYear = (new Date()).getFullYear();
		 	  let nxt=(currentYear-1)+'-'+(currentYear);
			this.setState({  
			  adminData: resp.data,
              invoiceno: resp.data[0].ao_type+"/"+nxt				  
              //invoiceno: "IN/"+resp.data[0].ao_type+"/"+nxt,
              			  
			   
			});
			
			this.onloaduserbyid(resp.data[1].ao_type);
	
	 
	  
	 
     }
  
    });
	
		
	}
	

onloaduserbyid(cuid){
		
		 axios.get(Serverurl+'ajaxcustomer.php?action=list&cu_id='+cuid)
  .then(resp => {  
   if(resp.data.length>0){
	   const items = {
        title:resp.data[0].cu_contactname,
          year:resp.data[0].cu_id,
          email:resp.data[0].cu_email,
		   mobileno:resp.data[0].cu_mobileno,
		       rowindex:1,
			   billingassress:resp.data[0].cu_billingassress,
			   shippingaddress:resp.data[0].cu_shippingaddress,
			   gstin:resp.data[0].cu_gstinno,
			   payment:resp.data[0].cu_payment,
			   dest:resp.data[0].cu_pl_id,
			
        };
 
       
    this.setState({ 
	
	selectedcustomerid:resp.data[0].cu_id,
   	 	custtags:resp.data[0].cu_contactname,
	   customer: items,
	billingassress:resp.data[0].cu_billingassress,
	shippingaddress:resp.data[0].cu_shippingaddress,
	gstin:resp.data[0].cu_gstinno,
	payment:resp.data[0].cu_payment,
	dest:resp.data[0].cu_pl_id,
	
	
	
	
  
	});
	
   }
   this.invoicebind();	
  });

} 
	
invoicebind(){
	
	
	 axios.get(Serverurl+"ajaxmaster.php?action=list&ma_type=3")
    
      .then((response) => {    
     
      this.setState({  invoicearray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.invoicearray.map(invoice => {
                            
          return {value: invoice.ma_id, display:invoice.ma_value}
        });
      
		
        this.setState({
          invoicearray: [{value: '0', display: 'Select Invoice'}].concat(roleFromApi)
        });
		
		
      })
	
	this.paymentbind();
	
}

	
paymentbind(){
	
	
	 axios.get(Serverurl+"ajaxpaymentterms.php?action=paymentbind&type=0")
    
      .then((response) => {    
     
      this.setState({  paymentarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.paymentarray.map(payment => {
                            
          return {value: payment.pt_id, display:payment.pt_title}
        });
      
		
        this.setState({
          paymentarray: [{value: '0', display: 'Select payment Type'}].concat(roleFromApi)
        });
      })
	this.Statebind();
	
	
}


Statebind(){
	
	
	 axios.get(Serverurl+"ajaxstate.php?action=list")
    
      .then((response) => {    
     
      this.setState({  statarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.statarray.map(stat => {
                            
          return {value: stat.pl_id, display:stat.pl_name}
        });
      
		
        this.setState({
          statarray: [{value: '0', display: 'Select State'}].concat(roleFromApi)
        });
      })
	
	 this.taxbind();
	
}


 
 onloadProductsBind=(event)=>{
 axios.get(Serverurl+'ajaxproduct.php?action=productautoextendertype&pr_name='+event.target.value)
  .then(resp => { 
  this.setState({ produserarray:resp.data });
  
  
//alert(JSON.stringify(this.state.produserarray))



	 var rowsArrayp = this.state.produserarray;
	 rowsArrayp.map((row, j) => {
			 
		 const items = {
			 
        title:row.title,
         year:row.year,
		 pr_name:row.pr_name,
		 pr_salesrateexcl:row.pr_salesrateexcl,
		 pr_salesrateincl:row.pr_salesrateincl,
		 tax:row.tax,
		 stock:row.stock,
		 pr_tx_id:row.pr_tx_id,
		  qty:row.qty,
           rowindex:row.rowindex,
		   pr_code:row.pr_code,
		   pr_unit:row.pr_unit,
		   discount:row.discount,
        };
         strProduct.push(items);
		// strProductexchange.push(items);
		
		 });
  });
 
  
}
 
 
 
 onloadProductsexchangeBind=(event)=>{
 axios.get(Serverurl+'ajaxproduct.php?action=productautoextendertype&pr_name='+event.target.value)
  .then(resp => { 
  this.setState({ produserarray1:resp.data });
  
  
//alert(JSON.stringify(this.state.produserarray))



	 var rowsArrayp = this.state.produserarray1;
	 rowsArrayp.map((row, j) => {
			 
		 const items = {
			 
        title:row.title,
         year:row.year,
		 pr_name:row.pr_name,
		 pr_salesrateexcl:row.pr_salesrateexcl,
		 pr_salesrateincl:row.pr_salesrateincl,
		 tax:row.tax,
		 stock:row.stock,
		 pr_tx_id:row.pr_tx_id,
		  qty:row.qty,
           rowindex:row.rowindex,
		   pr_code:row.pr_code,
		   pr_unit:row.pr_unit,
		   discount:row.discount,
        };
        // strProduct.push(items);
		 strProductexchange.push(items);
		
		 });
  });
 
  
}
 

taxbind(){
	
	
	 axios.get(Serverurl+"ajaxtaxes.php?action=list")
    
      .then((response) => {    
     
      this.setState({  taxarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.taxarray.map(tax => {
                            
          return {value: tax.tx_id+"~"+tax.tx_rate, display:tax.tx_rate}
        });
      
		
        this.setState({
          taxarray: [{value: '0', display: 'Select Tax'}].concat(roleFromApi)
        });
      })
	
	
	
}

 


onloadCustomerBind(){
	 axios.get(Serverurl+'ajaxcustomer.php?action=autoextender')
  .then(resp => { 
  this.setState({ custarray:resp.data});

	 var rowsArray = this.state.custarray;
	 rowsArray.map((row, i) => {
			 
		 const items = {
        title:row.title,
          year:row.year,
          email:row.cu_email,
		   mobileno:row.cu_mobileno,
		       rowindex:row.rowindex,
			   billingassress:row.cu_billingassress,
			   shippingaddress:row.cu_shippingaddress,
			   gstin:row.cu_gstinno,
			   payment:row.cu_payment,
			
        };
         strCust.push( items)
		  
		 });
  });
  
  this.adminoptioncall();
  
	
}






handfrChange = (date) => {
	 var month="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date.getDate()


	 })

  }
  

 




handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};



handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
   handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });
	
	 
   
  }; 


handleDiscard= (event) => {
	
	window.location.reload();
}

handleSubmit= (event) => {
	
	
	let search = window.location.href;
let orid = this.getParameterByName('orid',search);
    
    event.preventDefault();
	//alert('')
   
     let taxamt = this.state.ordgst;
    let invoice = this.state.invoice;
	let invoiceno = this.state.invoiceno;
	let fdate1 = this.state.fdate1;
	let payment = this.state.payment;
	let salesperson = this.state.salesperson;
	let gstin = this.state.gstin;
	let selectedcustomerid = this.state.selectedcustomerid;
	//let stat = this.state.stat;
	let dest = this.state.dest;
	
	 let billingassress = this.state.billingassress;
	  let shippingaddress = this.state.shippingaddress;
	  let paymentname = this.state.paymentname;

	 let grandtotal= this.state.grandtotal;
	 let ordertype = this.state.ordertype;
	 let comment = this.state.comment;
	
	  
	 if(invoice === "0")
	 {
		
		 this.setState({errormessage:"Please Enter Name.",open:true,btndisabled:false,submitbtn:"Submit"});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 
	  if(payment === "0")
	 {
		
		 this.setState({errormessage:"Please Select Payment Terms",open:true,btndisabled:false,submitbtn:"Submit"});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 
	 
	  if(salesperson === "0")
	 {
		
		this.setState({errormessage:"Please Select Salesperson",open:true,btndisabled:false,submitbtn:"Submit"});
		window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 
	 /* if(gstin === "")
	 {
		
		 this.setState({errormessage:"Please Enter GST No.",open:true,btndisabled:false,submitbtn:"Submit"});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }*/
	 
	   if(selectedcustomerid === "0")
	 {
		 
		 this.setState({errormessage:"Please Select Customer",open:true,btndisabled:false,submitbtn:"Submit"});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	    if(billingassress === "")
	 {
		
		 this.setState({errormessage:"Please Enter Billing Address",open:true,btndisabled:false,submitbtn:"Submit"});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	    if(shippingaddress === "")
	 {
		
		 this.setState({errormessage:"Please Enter Shipping Address",open:true,btndisabled:false,submitbtn:"Submit"});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 
	 
	 
	   if(dest === "0"  )
	 {
		
		 this.setState({errormessage:"Please Select Destination State",open:true,btndisabled:false,submitbtn:"Submit"});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	


this.setState({btndisabled:true,submitbtn:"Processing..."})	
var formData = new FormData();
	
		formData.append('action','customerordersinsert') 
		
		
		 if(orid === "" || orid === null)
	  {
		   formData.append('or_id',0)
	  }
	  else{
		  formData.append('or_id',orid)
	  }
	  
	
	formData.append('or_invoice',invoiceno)
		formData.append('or_invoicetype',invoice)
		formData.append('or_paymentterms',payment)
		formData.append('or_cu_id',selectedcustomerid)
		
		formData.append('or_destination',dest)
		formData.append('or_sp_id',salesperson)
		formData.append('or_gstn',gstin)
		
		formData.append('or_taxamount',taxamt)
		formData.append('or_discount',this.state.ordtotaldiscount)
		formData.append('or_tx_id',0)
		formData.append('or_roundoff',this.state.roundoff)
		formData.append('or_billingaddress',billingassress)
		formData.append('or_shippingaddress',shippingaddress)
		formData.append('paymentname',paymentname)
		formData.append('or_comment',comment)
		formData.append('or_ordertype',ordertype)
		
		
	
		
		
		
		
		
		let optionsVal = this.state.pvdata;
		let sep1="";
		let optionValues="";
		let index=0;
		
	var srearray=optionsVal.filter(value => value.op_isdeleted  < 1)
	
	

if(srearray.length-1 === 0)	
{
	 this.setState({errormessage:"Please Select Product ",open:true,btndisabled:false,submitbtn:"Submit"});
		 window.scrollTo({
          top: 0
      })
		 return false;
}
	
		
		//console.log(optionsVal);

		
    optionsVal.forEach(eachval => {
	
     
		   if(index!=0)
		   {
	   if(eachval.op_id<0 && eachval.op_isdeleted===1)
	   {
		   
	   }
	 /* else if(eachval.op_isdeleted == 1)
	   {
	   }*/
	   
	   else
	   {
 
 
   
 var strtax=eachval.pr_tx_id.split('~');
 optionValues=optionValues+sep1+eachval.pr_code+"~"+eachval.title+"~"+eachval.year+"~"+eachval.pr_salesrateincl+"~"+eachval.pr_salesrateexcl+"~"+eachval.tax+"~"+strtax[0]+"~"+eachval.discount+"~"+eachval.discounttype+"~"+eachval.qty+"~"+eachval.amount+"~"+eachval.incgrossamt;
 sep1="`"; 
	   }
		   
		   }
		   index=1;
		   
	 
    })
	
	
	
	let exchangeoptionsVal = this.state.exchangepvdata;
		let exchangeoptionValues="";
		let  sep2=""
		
		if (exchangeoptionsVal !="" ){
	 exchangeoptionsVal.forEach(eachval => {
		  if(index!=0)
		   {
	   if(eachval.op_id<0 && eachval.op_isdeleted===1)
	   {
		   
	   }
	   else{
		 exchangeoptionValues=exchangeoptionValues+sep2+eachval.year+"~"+eachval.qty;
		sep2="`";  
	   }
		   }
	    index=1;
	 })
		}
	
	
	//alert(optionValues);
		formData.append('op_barcode',optionValues)
		formData.append('exchangeprid',exchangeoptionValues)
	
		formData.append('userid',localStorage.getItem("Ruserid"))
		
		
		axios.post(Serverurl+"ajaxorders.php", formData, {
        }).then(res => {
			 if(res.data!="0"){
		
		var str=res.data;
		
  
  localStorage.setItem("RASBILorder",str);

 this.setState({btndisabled:false,submitbtn:"Submit",redirect:true,pagename:'/Orderdetails?orid='+localStorage.getItem("RASBILorder")})
			 }
			  else{
				 this.setState({btndisabled:false,submitbtn:"Submit"})			 }
 
   localStorage.removeItem("newcuid");
	
		});
	
} 
onInputChange = (event, newTextValue) => {

if(newTextValue!==" "){


  this.setState({custtags:newTextValue})
}

  
 }

 onTagsChange = (event, values) => {
	 
	  
	
	console.log(values)
	  if(values!==" " && values!==null){
	 
	 
    this.setState({
    
	   customer: values,
	   selectedcustomerid:values["year"],
	    billingassress: values["billingassress"],
		 shippingaddress: values["shippingaddress"],
		 gstinno: values["gstinno"],
		 payment: values["payment"],
		 dest:values["destid"],
		
		 
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
     
    });
	  }
  }
 
 
   escFunction= (event) => {
	  var self=this; 
        let nam = event.target.name;
        let val = event.target.value;
 this.setState({[nam]: val});
 
  if(event.keyCode === 13) {

		
		if(nam==="scancode"){
			 
	setTimeout(() => {
            
				 
		
        var formData = new FormData();
		if(val!==""){
			
		
  

	
 axios.get(Serverurl+'ajaxproduct.php?action=barcodedetails&pr_code='+this.state.scancode)
  .then(resp => {  
		
    if(resp.data.length>0){
	//	alert(this.state.exchangediscount);
			 
	 let id = self.state.showField;		
	
 
 let stramount=Number( resp.data[0].pr_salesrateexcl)*Number(1);
 
 
 
 let discal =((Number(resp.data[0].pr_salesrateexcl)*Number(resp.data[0].discount))/100);
	
		let strdisamt=(Number(resp.data[0].pr_salesrateexcl)-Number(discal));
 
 /*
 let incdiscal =((Number(resp.data[0].pr_salesrateincl)*Number(resp.data[0].discount))/100);
 
 
 let strincdisamt = (Number(resp.data[0].pr_salesrateincl)-Number(incdiscal));*/
 let strincdisamt = (Number(resp.data[0].pr_salesrateincl)*Number(1))

 if(resp.data[0].pr_unit!="2")
 {
 
 self.textRef.focus();	
 }
	
	let optionsRep = self.state.pvdata;
	
	
	var rowsArray = self.state.pvdata; 
	
	var exchangeArray = self.state.exchangepvdata;
	
	
let strqty=1,strsetarray=0,strsubtotal=0,streachdiscount=0,strtotalgst=0;
let strgrantotal=0,strtotaldiscount=0;


  var newRow = rowsArray.map((row, i) => {
if(i!==0){  

                   
				 
					
		//alert(val);			
					
  if (row.pr_code === val && row.qty!==0) {
	  
  if(row.op_isdeleted===1){
		row.op_isdeleted=0;
		 row.qty=1;
		 //strsubtotal=0;
		
	
	
	}
	else{
		row.qty= (Number(row.qty)+Number(1));
	
	
	}
  
	
	//row.amount=Number(Number(row.qty)*Number(row.amount)).toFixed(2);

	row.amount=Number(Number(row.qty)*Number(row.pr_salesrateexcl)).toFixed(2);
	
	
	  strsetarray=1;
	  
	  
	  

 //strsubtotal=Number(row.amount)+Number(strsubtotal);
let amt= (Number(row.pr_salesrateexcl)*Number(row.qty));
//alert(strsubtotal)
	  
	  if(row.discounttype === "2"){			
	
			
				 streachdiscount= (Number(row.discount));
				
							
			   
		   }
		   else if(row.discounttype === "1"){
			  let strpercentageamt = Number(amt)*(Number(row.discount)/100 );
			
			streachdiscount=(strpercentageamt);
			  
		   } 
	  
	  strtotalgst	=Number(strtotalgst)+Number(row.sgstamt)+Number(row.sgstamt) ;  
	//strtotaldiscount=Number(streachdiscount)+Number(strtotaldiscount);


	
if(this.state.exchangediscount !== 0){

		 strtotaldiscount=Number(this.state.exchangediscount).toFixed(2);
} 
else{
	 strtotaldiscount=Number(streachdiscount)+Number(strtotaldiscount);
}
	
//strgrantotal=Number(strsubtotal)-Number(strtotaldiscount)+Number(strtotalgst.toFixed(2))-Number(this.state.roundoff)
  
strgrantotal=Number(strsubtotal)+Number(strtotalgst.toFixed(2))-Number(this.state.roundoff)	  
	  setTimeout(() => this.Taxcalculactionbind(),500);
	  setTimeout(() => this.handleboxChangee(),500);	
  }}
  strsubtotal=Number(row.amount)+Number(strsubtotal);
  return row;

  });
  if(strsetarray === 1){
	  
  this.setState({pvdata:newRow,
  subtotal:strsubtotal,
  ordtotaldiscount:Number(strtotaldiscount),
  scancode:'',
	  // orderproduct:Number(this.state.orderproduct)+1
	   
  
  
  
  });
  }

	if(strsetarray === 0){
	//console.log(optionsRep);
    optionsRep.forEach(eachrep => {
		//alert(resp.data[0].pr_tx_id)
		//alert(resp.data[0].tx_rate)
       if (eachrep.op_id === id)
	   {
		
		eachrep.title= resp.data[0].pr_name;
		   
			   eachrep.year= resp.data[0].pr_id;
			   eachrep.pr_code= resp.data[0].pr_code;
			   eachrep.pr_salesrateexcl= resp.data[0].pr_salesrateexcl;
		       eachrep.pr_salesrateincl= resp.data[0].pr_salesrateincl;
		       eachrep.tax= resp.data[0].tx_rate;
			   eachrep.stock= resp.data[0].pr_currentstock;
			   //eachrep.pr_tx_id= resp.data[0].pr_tx_id;
			   
			  eachrep.pr_tx_id= resp.data[0].pr_tx_id+"~"+resp.data[0].tx_rate;
			   
		        eachrep.discount= resp.data[0].discount;
		       eachrep.discounttype="1";
		      
             
			  eachrep.qty= "1";
			  if(eachrep.proddiscount !==""){
			   eachrep.proddiscount= resp.data[0].discount;
			  }
		    eachrep.op_isdeleted= 0;
		    eachrep.unit=resp.data[0].pr_unit;
		    eachrep.amount=stramount
		  // eachrep.amount=strdisamt;
 eachrep.incgrossamt=strincdisamt;
	
	}
	   
	  
    })
			
	 self.setState({  
			 pvdata:optionsRep,
			   totaldata:parseInt(resp.data[0].count),
			   selectedproduct:resp.data[0].pr_id,
			   scancode:''
			  
			   
			}); 
			if(resp.data[0].pr_unit=="2")
 {
			this.fieldsave(id);
 }
	}
	
	
	
	
	
	
	

	
     }
	 else{
		 
		 self.setState({ 
		
		 totaldata:0,
		 subtotal:0
		 })
	 
	 
	// alert("Please Enter Correct Barcode") 
	 }




 //this.setState({scancode: ''});
});
// })}			

		  
		  
		}
				
            
        }, 20);
	}
   
}

  }
 
 
 
 
  escSalesFunction= (event) => {
	  var self=this; 
        let nam = event.target.name;
        let val = event.target.value;
 this.setState({[nam]: val});
 
  if(event.keyCode === 13) {

		
		if(nam==="salespersonname"){
			 
	setTimeout(() => {
            
				 
		
        var formData = new FormData();
		if(val!==""){
			
		
 

	
 axios.get(Serverurl+'ajaxuser.php?action=list&us_username='+this.state.salespersonname)
  .then(resp => {  
		
    if(resp.data.length>0){
		
		
		
		 self.setState({  
			 salesdata:resp.data,
			   totaldata:parseInt(resp.data[0].count),
			   salesperson:resp.data[0].us_id,
			   salespersonname:resp.data[0].us_username
			   
			   
			}); 
		self.textBarcode.focus();
     }
	 else{
		 
		 self.setState({
		 salesdata:[],
			   totaldata:0,
			   salesperson:0,
			   salespersonname:'',
			   errormessage:"SalesPerson does not exist",
			   open:true
		 });
		 window.scrollTo({
          top: 0
      })
		 
	
	return false; 
	 }
	 
	 
	 
	
	




 
});
		

		  
		  
		}
				
            
        }, 20);
	}
   
}

  }
 
 
 
  Onscancode = (event) => {
	  
	window.initOnScan();
			
	}
scanHandler = (event) => {
		console.log("[scanHandler]: Code: " + event.detail.code);
	}

	
 
 
    
  
onTagsproductChange = (event, values) => {
	
	//this.setState({ prodid:values["year"]})

	 let id = this.state.showField;
	
 
 let stramount=Number( values["pr_salesrateexcl"])*Number(1);
 
 
 
 let discal =((Number(values["pr_salesrateexcl"])*Number(values["discount"]))/100);
	//alert(discal);
		let strdisamt=(Number(values["pr_salesrateexcl"])-Number(discal));
 
 //alert(strdisamt);
/* 
 let incdiscal =((Number(values["pr_salesrateincl"])*Number(values["discount"]))/100);
 
 
 let strincdisamt = (Number(values["pr_salesrateincl"])-Number(incdiscal));
*/

 let strincdisamt = (Number(values["pr_salesrateincl"])*Number(1));

	
	let optionsRep = this.state.pvdata;
    optionsRep.forEach(eachrep => {
		
       if (eachrep.op_id === id)
	   {
		
		eachrep.title= values["title"];
		   
			   eachrep.year= values["year"];
			   eachrep.pr_code= values["pr_code"];
			   eachrep.pr_salesrateexcl= values["pr_salesrateexcl"];
		       eachrep.pr_salesrateincl= values["pr_salesrateincl"];
		       eachrep.tax= values["tax"];
			   eachrep.stock= values["stock"];
			   eachrep.pr_tx_id= values["pr_tx_id"]+"~"+values["tax"];
		       eachrep.discount= values["discount"];
		       eachrep.discounttype="1";
		       eachrep.qty= "1";
			    eachrep.proddiscount= values["discount"];
			   
		    eachrep.op_isdeleted= 0;
		   
		      eachrep.unit= values["pr_unit"];
		   
		    eachrep.amount= Number(stramount).toFixed(2);
		   
		   // eachrep.amount= Number(strdisamt).toFixed(2);
	eachrep.incgrossamt=Number(strincdisamt).toFixed(2);
	
	}
	   
	  
    })
  
this.setState({pvdata: optionsRep, selectedproduct:values})	



		
  }
  
 

onTagsproductChangeexchange = (event, values) => {
	


	 let id = this.state.showField;
	


 let stramount=Number( values["pr_salesrateexcl"])*Number(1);
 
 
 
 let discal =((Number(values["pr_salesrateexcl"])*Number(values["discount"]))/100);
	
		let strdisamt=(Number(values["pr_salesrateexcl"])-Number(discal));
 


 let strincdisamt = (Number(values["pr_salesrateincl"])*Number(1));
this.state.exchangeamt= Number(this.state.exchangeamt)+Number(stramount)
	
	let optionsRep = this.state.exchangepvdata;
    optionsRep.forEach(eachrep => {
		
       if (eachrep.op_id === id)
	   {
		
		eachrep.title= values["title"];
		   
			   eachrep.year= values["year"];
			   eachrep.pr_code= values["pr_code"];
			   eachrep.pr_salesrateexcl= values["pr_salesrateexcl"];
		       eachrep.pr_salesrateincl= values["pr_salesrateincl"];
		       eachrep.tax= values["tax"];
			   eachrep.stock= values["stock"];
			   eachrep.pr_tx_id= values["pr_tx_id"]+"~"+values["tax"];
		       eachrep.discount= values["discount"];
		       eachrep.discounttype="1";
		       eachrep.qty= "1";
			    eachrep.proddiscount= values["discount"];
			   
		    eachrep.op_isdeleted= 0;
		   
		      eachrep.unit= values["pr_unit"];
		   
		    eachrep.amount= Number(stramount).toFixed(2);
		   
		   // eachrep.amount= Number(strdisamt).toFixed(2);
	eachrep.incgrossamt=Number(strincdisamt).toFixed(2);
	//exchangeamt= Number(exchangeamt)+Number(stramount).toFixed(2);
	
	}
	   
	  
    })
  setTimeout(() => this.exchangeproductbind(id),500);
this.setState({exchangepvdata: optionsRep, selectedproductexchange:values, ordtotaldiscount:this.state.exchangeamt})	


 //this.exchangeproductbind(id);
		
  }
 
myChangeHandlerPV = (event) => {
	
	const re = /^[0-9\b]+$/;
   let nam = event.target.name;
  let val = event.target.value;
  
  if(nam==="qty" )
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	  
  }
  }
 else{
  this.setState({[nam]: val});
  }
  
  let strtotaldiscount="0";
  
  
  let id = this.state.showField;
  
  
  

   let optionsRep = this.state.pvdata;
   var rowsArray = this.state.pvdata; 
   
    optionsRep.forEach(eachrep => {
		
		var newRow = rowsArray.map((row, i) => {
if(i!==0){
		
		
		 
       if (eachrep.op_id === id)
	   {
		   if(nam==="pr_salesrateexcl")
		   {
		eachrep.pr_salesrateexcl= val;
		let amt= (Number(val)*Number(eachrep.qty));
		   
eachrep.amount=amt.toFixed(2);
		   }
		   else if(nam==="pr_salesrateincl")
		   {
			eachrep.pr_salesrateincl= val;
		   }
		   
		   
		
		   
		    else if(nam==="pr_tx_id")
		   {
			   var strarr=val.split('~');
			eachrep.pr_tx_id = val;
			eachrep.tax= strarr[1];
		   }
		   
		    else if(nam==="qty")
		   {
			eachrep.qty= val;
			
			//let amt= (Number(eachrep.pr_salesrateexcl)*Number(val));
		   let amt= (Number(eachrep.pr_salesrateexcl).toFixed(2))*(Number(val).toFixed(2));
eachrep.amount=amt.toFixed(2);
			   
		
			
			
		   }
		   
		  
			
			
			
			
		   
		      else if(nam==="discount")
		   {
			   
			   eachrep.discount = val;
			   
			if(eachrep.discounttype==="2")
			{   
			   
			   let rsdiscount=eachrep.discounttype;
				
	let rsamt =((Number(eachrep.pr_salesrateexcl)*Number(eachrep.qty).toFixed(2))-Number(val));
	

	
 eachrep.amount=rsamt.toFixed(2);	
				
			}
			   
			else{
				let discount=(Number(val));

	
	let disamt =Number((Number(eachrep.pr_salesrateexcl)*Number(discount))/100).toFixed(2);
	
 
 let amt=Number(Number(eachrep.pr_salesrateexcl).toFixed(2))*Number(Number(eachrep.qty).toFixed(2))-Number(Number(disamt).toFixed(2));
 
 eachrep.amount=amt.toFixed(2);
 
			}   
			//eachrep.discount = val/this.state.orderproduct;
			
	/*let disamt =((Number(eachrep.pr_salesrateexcl)*Number(val))/100);
	//alert(disamt)
		let amt=(Number(eachrep.pr_salesrateexcl)-Number(disamt));
 eachrep.amount=amt;
 //alert(amt)*/
			
		   }
		   
		   
		   
		    else if(nam==="discounttype")
		   {
			eachrep.discounttype= val;
			//alert(val)
		   
			if(val==="2")
			{
				let rsdiscount=val;
				//let rsdiscount=val/this.state.orderproduct;
			//let rsamt =((Number(eachrep.pr_salesrateexcl)-Number(eachrep.discount))*Number(eachrep.qty));
	let rsamt =((Number(eachrep.pr_salesrateexcl)*Number(eachrep.qty).toFixed(2))-Number(eachrep.discount));
	
	
	
//alert(eachrep.qty);
	
	//alert(rsamt);
	
 eachrep.amount=rsamt.toFixed(2);	
				
			}
			else{
				let discount=(Number(eachrep.discount));
//let disamt =((Number(eachrep.pr_salesrateexcl)*Number(discount))/100);
	
	let disamt =Number((Number(eachrep.pr_salesrateexcl)*Number(discount))/100).toFixed(2);
	
	//alert(disamt)
		
		
		//let amt=((Number(eachrep.pr_salesrateexcl)*Number(eachrep.qty))-Number(disamt));
 
 let amt=Number(Number(eachrep.pr_salesrateexcl).toFixed(2))*Number(Number(eachrep.qty).toFixed(2))-Number(Number(disamt).toFixed(2));
 
 eachrep.amount=amt.toFixed(2);
 //alert(eachrep.qty);
 //alert(amt);
			}
			
			
		
		   
		   }
		   		   else
		   {
			   
			   eachrep.amount= val;
		   }
		
	   }
	  
}

		})
    })
this.setState({pvdata: optionsRep,})	
  
 // this.subtotal();

}
handlechangeroundoff = () => evt  => { 
	  var item = {    
                  id: evt.target.id,    
                  name: evt.target.name,    
                  value: evt.target.value    
              };
if(item.name==="roundoffval")
{
	
		
	 this.setState({roundoff: evt.target.value,
	 [item.name]: evt.target.value,
	 });
	

	
	
}
setTimeout(() => this.Taxcalculactionbind(),500);
	  
}
handleboxChangee = () => evt  => {    
        try {    
              var item = {    
                  id: evt.target.id,    
                  name: evt.target.name,    
                  value: evt.target.value    
              }; 


//alert(evt.target.value)

//alert(item.value)

let distotal=0,strgrantotal=0;
let amount=0,strsubamount=0;
	let strtotaldiscount="0",streachdiscount="0",strsubtotal="0";
var rowsArray = this.state.pvdata; 

 var srearray=rowsArray.filter(value => value.op_isdeleted  < 1)
console.log(rowsArray);
  var newRow = rowsArray.map((row, i) => {
if(i!==0){  
                    for (var key in row) { 
	 if (item.name == "orddiscount") {

		 
 if (key == "discount" ) { 
 
let amt= (Number(row.pr_salesrateexcl)*Number(row.qty));
			 





 

  if(row.discounttype == "2"){
	
				
		 row[key] = Number(item.value)/Number(srearray.length-1);	
			
						
			streachdiscount = (Number(row.discount));

				
		
				
			}
			else{
					
			 row[key] = Number(row["proddiscount"])+Number(item.value); 	
				
				 let strpercentageamt = Number(amt)*(Number(row.discount)/100 );
				
	//let strpercentageamt = ((Number(row.discount)/2 )*100)	
			streachdiscount=(strpercentageamt);
	//strsubamount = Number(streachdiscount);	

			}
			
			
			
		
			
			
			
			
			

  row["amount"]=(amt-streachdiscount).toFixed(2);
 strsubamount = Number(strsubamount)+Number(row["amount"]);

 }

	 }
	 if (item.name == "orddiscounttype" ) { 
	
  if (key == "discounttype" ) { 
        let amt= (Number(row.pr_salesrateexcl)*Number(row.qty));             
 row[key] = item.value; 
if(item.value==="1")
		   {
		   row["distypetitle"]="%";
		   
		   	   row["olddistypetitle"]="%";
		   }else{
		   row["distypetitle"]="Rs.";
		    row["olddistypetitle"]="Rs.";
		   }
  if(item.value == "2"){
				//this.state.orddiscount = row.discount;
				
				// row[key] = Number(this.state.orddiscount)/Number(this.state.orderproduct);
						
			streachdiscount = (Number(row.discount));

			}
			else{
				
				 	
				 let strpercentageamt = Number(amt)*(Number(row.discount)/100 );
						
			streachdiscount=Number(strpercentageamt).toFixed(2);
	

			}

row["amount"]=(amt-streachdiscount).toFixed(2);
 
 strsubamount = Number(strsubamount)+Number(row["amount"]);
 }
					}
					
					 
					}
					
				
					
			 strtotaldiscount=(Number(streachdiscount)+Number(strtotaldiscount)	).toFixed(2);
			 
					}	
	  			
  });
 
  if(item.name==="roundoffval")
{
	
		
	 this.setState({roundoff: evt.target.value,
	 grandtotal:Number(this.state.subtotal)+Number(this.state.ordgst.toFixed(2))-Number(evt.target.value),
	// grandtotal:Number(this.state.subtotal)-Number(strtotaldiscount)+Number(this.state.ordgst.toFixed(2))-Number(evt.target.value),
	
	 });
	

	
	
}


this.setState({
[item.name]: evt.target.value,

ordtotaldiscount:Number(strtotaldiscount), 
subtotal: Number(strsubamount) }); 

 this.Roundoffautocalculation();

		}
catch (error) {    
              console.log("Error in React Table handle change : " + error);    
          }  
		  
		
		setTimeout(() => this.Taxcalculactionbind(),500);
setTimeout(() => this.handleboxChangee(),500);		
      };	

	  

 UsergetPVdata() {
	 
	
	let search = window.location.href;
let orid = this.getParameterByName('orid',search);	
		
		
		
	  if(orid!=="" && orid!==null)
  {
	  
	   axios.get(Serverurl+'ajaxorders.php?action=orderproductlistbyorid&op_or_id='+orid)
  .then(resp => {  
		let oldOptionspvdata=[]
    if(resp.data.length>0){
		
		  oldOptionspvdata=resp.data;//this.state.pvdata
   
	}
	
	let newrow={"op_id":this.state.newpcid,"year":"","title":"",
	 "pr_code":"","pr_salesrateexcl":"","pr_salesrateincl":"","stock":"","tax":"","pr_tx_id":"","discount":"","discounttype":1,"distypetitle":"%","qty":1,"amount":"","op_isdeleted":0,"proddiscount":0,
	"unit":"","incgrossamt":"","opstatus":"1",
	 "oldyear":"","oldtitle":"","oldpr_code":"","oldpr_salesrateexcl":"","oldpr_salesrateincl":"","oldstock":"","oldtax":"","oldpr_tx_id":"","olddiscount":"","olddiscounttype":2,"olddistypetitle":"%","oldqty":"","oldamount":"","oldincgrossamt":""}
	  oldOptionspvdata.unshift(newrow);
	 
	

	let strtotaldiscount="0",strgrantotal="0",streachdiscount="0",strsubtotal="0",strtotalgst=0;
let discountsum=0;

	 let optionsRep = oldOptionspvdata;
	 
	 optionsRep.forEach(eachrep => {
	 
       if (eachrep.op_isdeleted !== 1)
	   {
	 
	 
	 
	  strsubtotal=Number(eachrep.amount)+Number(strsubtotal);
let amt= (Number(eachrep.pr_salesrateexcl)*Number(eachrep.qty));

			if(eachrep.discounttype === "2"){			
	
			
				 streachdiscount= (Number(eachrep.discount));
				
							
			   
		   }
		   else if(eachrep.discounttype === "1"){
			  let strpercentageamt = Number(amt)*(Number(eachrep.discount)/100 );
			
			streachdiscount=(strpercentageamt);
			  
		   }
		    
		  
		
	  
	
		strtotalgst	=Number(strtotalgst)+Number(eachrep.sgstamt)+Number(eachrep.sgstamt) ;  
		    strtotaldiscount=Number(streachdiscount)+Number(strtotaldiscount);
	   }
	 });

	
	// strsubtotal=Number(eachrep.amount)+Number(strsubtotal);
	strgrantotal=Number(strsubtotal)-Number(strtotaldiscount)+Number(strtotalgst.toFixed(2))-Number(this.state.roundoff)
  
 this.setState({ 
	 pvdata: oldOptionspvdata ,
	 showField: this.state.newpcid,
	 subtotal:strsubtotal,ordtotaldiscount:Number(strtotaldiscount),
	   orderproduct:Number(this.state.orderproduct)+1
	  
     
       
    
    });
  setTimeout(() => this.Taxcalculactionbind(),500);
  setTimeout(() => this.handleboxChangee(),500);	
  
});




	  
  } 
  else
  {
	
	
	   this.setState({ 
	 pvdata: [{"op_id":this.state.newpcid,"year":"","title":"",
	 "pr_code":"","pr_salesrateexcl":"","pr_salesrateincl":"","stock":"","tax":"","pr_tx_id":"","discount":"","discounttype":1,"distypetitle":"%","qty":1,"amount":"","op_isdeleted":0,"proddiscount":0,
	"unit":"","incgrossamt":"","opstatus":"1",
	 "oldyear":"","oldtitle":"","oldpr_code":"","oldpr_salesrateexcl":"","oldpr_salesrateincl":"","oldstock":"","oldtax":"","oldpr_tx_id":"","olddiscount":"","olddiscounttype":2,"olddistypetitle":"%","oldqty":"","oldamount":"","oldincgrossamt":""}] ,
	  showField: this.state.newpcid,  
	 
	
	
	 exchangepvdata: [{"op_id":this.state.newpcid,"year":"","title":"",
	 "pr_code":"","pr_salesrateexcl":"","pr_salesrateincl":"","stock":"","tax":"","pr_tx_id":"","discount":"","discounttype":1,"distypetitle":"%","qty":"","amount":"","op_isdeleted":0,"proddiscount":0,
	"unit":"","incgrossamt":"","opstatus":"1",
	 "oldyear":"","oldtitle":"","oldpr_code":"","oldpr_salesrateexcl":"","oldpr_salesrateincl":"","oldstock":"","oldtax":"","oldpr_tx_id":"","olddiscount":"","olddiscounttype":2,"olddistypetitle":"%","oldqty":"","oldamount":"","oldincgrossamt":""}] ,
	   
	}); 
	
	
	 this.setState({ 
	taxdata: [{"taxamount":0,"cgstrate":0,"cgstamt":0,"sgstrate":0,"sgstamt":0,"totaoatax":0}] ,
	}); 
this.onloadCustomerBind();

	 
	 
 }

 }
 
 
 
 
 

fieldedit(id){
	//alert(id)
	this.setState({showField:id,editclick:1,subtotal:0,showtaxField:id })
	
}

fieldstockalter(id){

	let strtotaldiscount="0",streachdiscount="0",strsubtotal="0";
	let r = window.confirm("Are you sure to Delete Product from Invoice & Alter Stock?");
		 if(r == true){
			 
	 let optionsRep = this.state.pvdata;
	 let optionstax = this.state.taxdata;
	 
	 
	 var srearray=optionsRep.filter(value => value.op_isdeleted  < 1)
	 
    optionsRep.forEach(eachrep => {
		

		
		if(eachrep.opstatus === "1"){
       if (eachrep.op_id === id)
	   {
		  
		eachrep.op_isdeleted = 1;
		//this.state.orderproduct=this.state.orderproduct-1;
		 eachrep.qty=0;
		 eachrep.amount=0;
		 eachrep.pr_salesrateexcl=0;
		 eachrep.discount=0;
		 eachrep.tax=0;
		 eachrep.oldincgrossamt=0;
		 //  this.setState({orderproduct:Number(srearray.length-1)})
		
		   } 
		}
		else{
			
 if (eachrep.op_id === id)
	   {
		
		let opid = eachrep.op_id ;
		let prid = eachrep.year;
		let prqty = eachrep.qty;
		eachrep.op_isdeleted = 1;
		
		 eachrep.qty=0;
		 eachrep.amount=0;
		 eachrep.pr_salesrateexcl=0;
		 eachrep.discount=0;
		 eachrep.tax=0;
		 eachrep.oldincgrossamt=0;
			 var formData = new FormData(); 
					 formData.append('action','orderproductdeletealterstock')
					 formData.append('userid',localStorage.getItem("Ruserid"))
					 formData.append('op_id',opid)
					 formData.append('pr_id',prid)
					 formData.append('pr_qty',prqty)
					  axios.post(Serverurl+"ajaxorderproduct.php", formData, {
						}).then(res => {
							
					
			
							});
							
							
							
	   }
			
		}
		  
	   if (eachrep.op_id !== id)
	   {
		  
	    strsubtotal=Number(eachrep.amount)+Number(strsubtotal);
let amt= (Number(eachrep.pr_salesrateexcl)*Number(eachrep.qty));

			if(eachrep.discounttype === "2"){			
	
			
				 streachdiscount= (Number(eachrep.discount));
				
							
			   
		   }
		   else if(eachrep.discounttype === "1"){
			  let strpercentageamt = Number(amt)*(Number(eachrep.discount)/100 );
			
			streachdiscount=(strpercentageamt);
			  
		   }
		  
		    strtotaldiscount=Number(streachdiscount)+Number(strtotaldiscount)
	 
	   
	   }
		 
    });
	this.setState({pvdata: optionsRep,orderproduct:Number(srearray.length-1),subtotal:strsubtotal,ordtotaldiscount:Number(strtotaldiscount)})	
setTimeout(() => this.Taxcalculactionbind(),500);		
		}	
	
}

fielddelete(id){
	//alert()
	
	let strtotaldiscount="0",streachdiscount="0",strsubtotal="0";
	let r = window.confirm("Are you sure to Delete Product from Invoice?");
		 if(r == true){
			 
	 let optionsRep = this.state.pvdata;
	 let optionstax = this.state.taxdata;
	 
	
	 
    optionsRep.forEach(eachrep => {
		
		
		
		
		
		if(eachrep.opstatus === "1"){
       if (eachrep.op_id === id)
	   {
		  
		eachrep.op_isdeleted = 1;
		
		 eachrep.qty=0;
		 eachrep.amount=0;
		 eachrep.pr_salesrateexcl=0;
		 eachrep.discount=0;
		 eachrep.tax=0;
		 eachrep.oldincgrossamt=0;
		  //this.setState({orderproduct:Number(this.state.orderproduct)-1})  
		 
		   } 
		}
		else{
			
 if (eachrep.op_id === id)
	   {
		eachrep.op_isdeleted = 1;
		
		 eachrep.qty=0;
		 eachrep.amount=0;
		 eachrep.pr_salesrateexcl=0;
		 eachrep.discount=0;
		 eachrep.tax=0;
		 eachrep.oldincgrossamt=0;
		let opid = eachrep.op_id ;
		let prid = eachrep.year;
		let prqty = eachrep.qty;
		
			 var formData = new FormData(); 
					 formData.append('action','delete')
					 formData.append('userid',localStorage.getItem("Ruserid"))
					 formData.append('op_id',opid)
					
					  axios.post(Serverurl+"ajaxorderproduct.php", formData, {
						}).then(res => {
							
					
		
							});
							
							
							
	   }
			
		}
		  
	   if (eachrep.op_id !== id)
	   {
		  
	    strsubtotal=Number(eachrep.amount)+Number(strsubtotal);
let amt= (Number(eachrep.pr_salesrateexcl)*Number(eachrep.qty));

			if(eachrep.discounttype === "2"){			
	
			
				 streachdiscount= (Number(eachrep.discount));
				
							
			   
		   }
		   else if(eachrep.discounttype === "1"){
			  let strpercentageamt = Number(amt)*(Number(eachrep.discount)/100 );
			
			streachdiscount=(strpercentageamt);
			  
		   }
		  
		   // strtotaldiscount=Number(streachdiscount)+Number(strtotaldiscount)
	 
	    if(this.state.exchangediscount !== 0){

		 strtotaldiscount=Number(this.state.exchangediscount).toFixed(2);
} 
else{
	 strtotaldiscount=Number(streachdiscount)+Number(strtotaldiscount);
}
	   
	   }
		 
    });
	this.setState({pvdata: optionsRep,orderproduct:Number(this.state.orderproduct)-1,subtotal:strsubtotal,ordtotaldiscount:Number(strtotaldiscount)})	
setTimeout(() => this.Taxcalculactionbind(),500);		
		}
}


 
 
fieldsave(id){

  

	let strtotaldiscount="0",strgrantotal="0",streachdiscount="0",strsubtotal="0",strtotalgst=0;
let discountsum=0;

 

	 let optionsRep = this.state.pvdata;
	 
	// alert (this.state.orddiscount); 
		 
    optionsRep.forEach(eachrep => {
	//alert(eachrep.op_id+"~"+id)
       if (eachrep.op_id === id)
	   {
		   
        		 
		 eachrep.oldyear= eachrep.year;
            eachrep.oldtitle = eachrep.title;	   
			
			eachrep.oldpr_code = eachrep.pr_code; 
			eachrep.oldpr_salesrateexcl = eachrep.pr_salesrateexcl ;
	    	 eachrep.pr_salesrateincl = eachrep.pr_salesrateincl;	
             eachrep.oldstock = eachrep.stock;	
              eachrep.tax = eachrep.tax;	

              eachrep.oldpr_tx_id = eachrep.pr_tx_id;	
              eachrep.olddiscount = eachrep.discount;	
           eachrep.oldqty = eachrep.qty;
		   eachrep.olddiscounttype =  eachrep.discounttype;
		   if(eachrep.discounttype==="1")
		   {
		   eachrep.olddistypetitle="%";
		   }else{
		   eachrep.olddistypetitle="Rs.";
		   }
		   eachrep.oldamount=eachrep.amount;
		  // this.state.orddiscount=eachrep.discount;
		    eachrep.oldincgrossamt=Number(eachrep.pr_salesrateincl)*Number(eachrep.qty);
		   
		     if(this.state.exchangediscount !== 0){

		 discountsum=Number(this.state.exchangediscount).toFixed(2);
} 
else{
	discountsum =Number(discountsum)+Number(eachrep.discount)
}
		   // discountsum =Number(discountsum)+Number(eachrep.discount);

				 	 var strtax=Number(eachrep.tax)/2;
		   var strtaxamount=(Number(eachrep.amount)*((eachrep.tax)/2)/100)
		  
		   var strtotaltaxamount=Number(eachrep.amount)+Number(strtaxamount)+Number(strtaxamount)
		   eachrep.taxamount=eachrep.amount;
		    eachrep.cgstrate=strtax;
		eachrep.cgstamt=strtaxamount;
		 eachrep.sgstrate=strtax;
		eachrep.sgstamt=strtaxamount;
		eachrep.totaoatax=strtotaltaxamount.toFixed(2);
	   }
	    if (eachrep.op_isdeleted !== 1)
	   {
	 
	 
	 
	 
	  strsubtotal=Number(eachrep.amount)+Number(strsubtotal);
let amt= (Number(eachrep.pr_salesrateexcl)*Number(eachrep.qty));

			if(eachrep.discounttype === "2"){			
	
			
				 streachdiscount= (Number(eachrep.discount));
				
							
			   
		   }
		   else if(eachrep.discounttype === "1"){
			  let strpercentageamt = Number(amt)*(Number(eachrep.discount)/100 );
			
			streachdiscount=(strpercentageamt);
			  
		   }
		    
		  
		
	  
	
		strtotalgst	=Number(strtotalgst)+Number(eachrep.sgstamt)+Number(eachrep.sgstamt) ;  
		   // strtotaldiscount=Number(streachdiscount)+Number(strtotaldiscount);
	   
	   if(this.state.exchangediscount !== 0){

		 strtotaldiscount=Number(this.state.exchangediscount).toFixed(2);
} 
else{
	 strtotaldiscount=Number(streachdiscount)+Number(strtotaldiscount);
}
	   
	   
	   
	   }

   
    }) 
	
	let vnewpcid=this.state.newpcid;
	
if(this.state.newpcid<0)
{
		 
	
	
	if(this.state.editclick!==1)
	{
	  vnewpcid=this.state.newpcid-1;
	
	 
	let newrow={"op_id":vnewpcid,"year":"","title":"",
	 "pr_code":"","pr_salesrateexcl":"","pr_salesrateincl":"","stock":"","tax":"","pr_tx_id":"","discount":"","discounttype":2,"qty":1,"amount":"","op_isdeleted":0,"proddiscount":0,
	 "unit":"","incgrossamt":"","opstatus":"1",
	 "oldyear":"","oldtitle":"","oldpr_code":"","oldpr_salesrateexcl":"","oldpr_salesrateincl":"","oldstock":"","oldtax":"","oldpr_tx_id":"","olddiscount":"","olddiscounttype":2,"oldqty":"","oldamount":"","oldincgrossamt":""}
	   optionsRep.unshift(newrow);
	   
	
		}
	
	   
	
	
}

 strgrantotal=Number(strsubtotal)-Number(strtotaldiscount)+Number(strtotalgst.toFixed(2))-Number(this.state.roundoff)
 
 //strgrantotal=Number(strsubtotal)+Number(strtotalgst.toFixed(2))-Number(this.state.roundoff)
 
setTimeout(() => this.Taxcalculactionbind(),500);
 this.setState({taxdatacount: optionsRep.length,
	 pvdata: optionsRep ,
	 showField: vnewpcid,
	  newpcid:vnewpcid,
	 prodarray:[],editclick:0,
     produserarray:[],
       strProduct:[],
	   selectedproduct:[],subtotal:strsubtotal,
	   ordtotaldiscount:Number(strtotaldiscount),
	   orderproduct:Number(this.state.orderproduct)+1
	   
	   //discount:Number(this.state.orddiscount)+ Number(distotal)
    
    });

	 
	
 }
 
 
 exchangeproductbind(id){
	 
	 
	 let strtotaldiscount="0",strgrantotal="0",streachdiscount="0",strsubtotal="0",strtotalgst=0;
let discountsum=0;

 //let optionsRepexchange = this.state.exchangepvdata;	 
  let optionsRep = this.state.exchangepvdata;
	 
	 
	 
		 
    optionsRep.forEach(eachrep => {
	 
       if (eachrep.op_id === id)
	   {
		   
        		 
		 eachrep.oldyear= eachrep.year;
            eachrep.oldtitle = eachrep.title;	   
			
			eachrep.oldpr_code = eachrep.pr_code; 
			eachrep.oldpr_salesrateexcl = eachrep.pr_salesrateexcl ;
	    	 eachrep.pr_salesrateincl = eachrep.pr_salesrateincl;	
             eachrep.oldstock = eachrep.stock;	
              eachrep.tax = eachrep.tax;	

              eachrep.oldpr_tx_id = eachrep.pr_tx_id;	
              eachrep.olddiscount = eachrep.discount;	
           eachrep.oldqty = eachrep.qty;
		   eachrep.olddiscounttype =  eachrep.discounttype;
		   if(eachrep.discounttype==="1")
		   {
		   eachrep.olddistypetitle="%";
		   }else{
		   eachrep.olddistypetitle="Rs.";
		   }
		   eachrep.oldamount=eachrep.amount;
		  // this.state.orddiscount=eachrep.discount;
		    eachrep.oldincgrossamt=Number(eachrep.pr_salesrateincl)*Number(eachrep.qty);
		   
		   
		    discountsum =Number(discountsum)+Number(eachrep.discount);

				 	 var strtax=Number(eachrep.tax)/2;
		   var strtaxamount=(Number(eachrep.amount)*((eachrep.tax)/2)/100)
		  
		   var strtotaltaxamount=Number(eachrep.amount)+Number(strtaxamount)+Number(strtaxamount)
		   eachrep.taxamount=eachrep.amount;
		    eachrep.cgstrate=strtax;
		eachrep.cgstamt=strtaxamount;
		 eachrep.sgstrate=strtax;
		eachrep.sgstamt=strtaxamount;
		eachrep.totaoatax=strtotaltaxamount.toFixed(2);
	   }
	    if (eachrep.op_isdeleted !== 1)
	   {
	 
	 
	 
	 
	  strsubtotal=Number(eachrep.amount)+Number(strsubtotal);
let amt= (Number(eachrep.pr_salesrateexcl)*Number(eachrep.qty));

			if(eachrep.discounttype === "2"){			
	
			
				 streachdiscount= (Number(eachrep.discount));
				
							
			   
		   }
		   else if(eachrep.discounttype === "1"){
			  let strpercentageamt = Number(amt)*(Number(eachrep.discount)/100 );
			
			streachdiscount=(strpercentageamt);
			  
		   }
		    
		  
		
	  
	
		strtotalgst	=Number(strtotalgst)+Number(eachrep.sgstamt)+Number(eachrep.sgstamt) ;  
		    strtotaldiscount=Number(streachdiscount)+Number(strtotaldiscount);
	   }

   
    }) 
	
	let vnewpcid=this.state.newpcid;
	
if(this.state.newpcid<0)
{
		 
	
	  vnewpcid=this.state.newpcid-1;
	 
	let newrow={"op_id":vnewpcid,"year":"","title":"",
	 "pr_code":"","pr_salesrateexcl":"","pr_salesrateincl":"","stock":"","tax":"","pr_tx_id":"","discount":"","discounttype":2,"qty":"","amount":"","op_isdeleted":0,"proddiscount":0,
	 "unit":"","incgrossamt":"","opstatus":"1",
	 "oldyear":"","oldtitle":"","oldpr_code":"","oldpr_salesrateexcl":"","oldpr_salesrateincl":"","oldstock":"","oldtax":"","oldpr_tx_id":"","olddiscount":"","olddiscounttype":2,"oldqty":"","oldamount":"","oldincgrossamt":""}
	   optionsRep.unshift(newrow);
	   
	
		
	
	   
	
	
}

 strgrantotal=Number(strsubtotal)-Number(strtotaldiscount)+Number(strtotalgst.toFixed(2))-Number(this.state.roundoff)
 
 //strgrantotal=Number(strsubtotal)+Number(strtotalgst.toFixed(2))-Number(this.state.roundoff)
 
//(() => this.Taxcalculactionbind(),500);
 this.setState({
	 //taxdatacount: optionsRep.length,
	 exchangepvdata: optionsRep ,
	 showField: vnewpcid,
	  //newpcid:vnewpcid,
	 prodarray:[],
     produserarray:[],
       strProduct:[],
	   selectedproductexchange:[],
	   subtotal:0,
	   ordtotaldiscount:Number(strsubtotal),
	   exchangediscount:Number(strsubtotal),
	  // orderproduct:Number(this.state.orderproduct)+1
	   
	   //discount:Number(this.state.orddiscount)+ Number(distotal)
    
    });


 }
 

Taxcalculactionbind	(){

	
	 let optionsRep = this.state.pvdata;
	 	
		optionsRep=optionsRep.sort((a, b) => a.tax > b.tax ? 1 : -1);
		let previoustax="",strcurnttax="",prerow=0,taxablevalue=0,rate="",taxamount=0,totalamount=0;
	let sumtax=0,strgrantotal=0;
	let taxarr=[];
		 
		   optionsRep.forEach(eachrep => {
		
			  if(  eachrep.op_isdeleted !== 1){
			   strcurnttax=eachrep.tax;
			   
			 
			  if(strcurnttax!==previoustax && previoustax!=="")
			  {
				
				var item = { 
				  taxamount:taxablevalue,
				  cgstrate:rate,
				  cgstamt:taxamount,
				  sgstrate:rate,
				  sgstamt:taxamount,
				  totaoatax:totalamount.toFixed(2),
				}
				
				
				 taxarr.push(item)

taxablevalue=0;
				  rate="";
				  taxamount=0;
				  totalamount=0;

previoustax=eachrep.tax 
			   taxablevalue=Number(taxablevalue)+Number(eachrep.amount);
				  rate=Number(eachrep.tax)/2;
				  taxamount=Number(taxamount)+(Number(eachrep.amount)*((eachrep.tax)/2)/100);
				//taxamount=((Number(eachrep.amount)*(eachrep.tax))/100);
				  totalamount=Number(totalamount)+Number(eachrep.amount)+((Number(eachrep.amount)*(eachrep.tax))/100);
			    sumtax=Number(taxamount)+Number(taxamount)+Number(sumtax)
				  
			  }  else{
				 
			// sumtax=0;
			 previoustax=eachrep.tax 
			   taxablevalue=Number(taxablevalue)+Number(eachrep.amount);
				  rate=Number(eachrep.tax)/2;
				  taxamount=Number(taxamount)+(Number(eachrep.amount)*((eachrep.tax)/2)/100);
				//taxamount=((Number(eachrep.amount)*(eachrep.tax))/100);
				  totalamount=Number(totalamount)+Number(eachrep.amount)+((Number(eachrep.amount)*(eachrep.tax))/100);
			   
			 sumtax=Number(eachrep.amount)*((eachrep.tax)/2)/100+Number(eachrep.amount)*((eachrep.tax)/2)/100+Number(sumtax)
			
			 }
			  /* previoustax=eachrep.tax 
			   taxablevalue=Number(taxablevalue)+Number(eachrep.amount);
				  rate=Number(eachrep.tax)/2;
				  taxamount=Number(taxamount)+(Number(eachrep.amount)*((eachrep.tax)/2)/100);
				//taxamount=((Number(eachrep.amount)*(eachrep.tax))/100);
				  totalamount=Number(totalamount)+Number(eachrep.amount)+((Number(eachrep.amount)*(eachrep.tax))/100);
			  
			   
			   sumtax=Number(taxamount)+Number(taxamount)+Number(sumtax)
			  	 */	  
		 
		 } 
		
		 })
		 var item = { 
				  taxamount:taxablevalue,
				  cgstrate:rate,
				  cgstamt:taxamount,
				  sgstrate:rate,
				  sgstamt:taxamount,
				  totaoatax:totalamount.toFixed(2)
				}
				
				
				 taxarr.push(item)

taxablevalue=0;
				  rate="";
				  taxamount=0;
				  totalamount=0;
	//strgrantotal=Number(this.state.subtotal)-Number(this.state.ordtotaldiscount)+Number(sumtax.toFixed(2))-Number(this.state.roundoff)
	
	 strgrantotal=Number(this.state.subtotal)+Number(sumtax.toFixed(2))-Number(this.state.roundoff)

 this.setState({ ordgst:sumtax.toFixed(2),grandtotal:strgrantotal.toFixed(2),
	   taxdata:taxarr,taxdatacount:taxarr.length,}); 
	   
	this.Roundoffautocalculation();   
	
}
 
 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
  
 onInputChange (newTextValue)
 {  strCust=[];
 }
 
 
  Roundoffautocalculation(){
	
	// alert(this.state.grandtotal)
	let n = this.state.grandtotal;
let whole = Math.floor(n).toFixed(2);   
let fraction =  (n - whole).toFixed(2);  

	 
	//alert(fraction);
this.setState({roundoff:fraction,grandtotal:whole, roundoffval:fraction });
	 
 }
 
 
 render() {
	
	
if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
  
 
  	
    
  return (
   <Page layout="public">

<Section slot="menu"> / New Invoice
</Section>

<Section slot="btn"> <a href='#/invoicereport' tabindex="-1"> Invoice</a>
</Section>

<Section slot="content">
    <React.Fragment>
	
	
<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
	
	
	
	
	
  <div className="mainorder"> <div className="headername">
		 <p><b> Invoice</b></p>
		 </div>
    <div className="ordtbl">

	  <div className="panelpad">
	
	
	<div className="invoice">

     
<ul>
        <li className="dropdown">
<p>Invoice Type <span className="validation">*</span></p>
		<p><FormControl  className={useStyles.formControl} >
       
        <Select
         native inputProps={{ tabIndex: "1", }} 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.invoicelabel, value: this.state.invoice }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.invoice}
		  name="invoice"
		
		
        >
		{this.state.invoicearray.map(invoice => (
            <option  
            
              value={invoice.value}
            >
              {invoice.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>



		</li>
		
		
		
		<li><p>
	
         <p>Invoice Number <span className="validation">*</span></p>
			<TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "2",maxLength:150 }} 
            fullWidth
			value={this.state.invoiceno}
            id="invoiceno"
          
            name="invoiceno" onChange={this.myChangeHandler}
            autoComplete="invoiceno"
            
			
          /></p>
		
		</li>
		
				
		<li>
		
				

		
		  <p>Invoice Date <span className="validation">*</span></p>
		<p className="datepicker"><MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	
      <DatePicker fullWidth
	  variant="outlined"
      margin="normal" inputProps={{ tabIndex: "3"}} 
        //value={this.state.fdate || Date.now()}
		value={this.state.fdate }
        onChange={this.handfrChange}
            format="dd/MM/yyyy"
        autoOk name="fdate"
        ampm={false}
		id="dateborder"
		
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		
		</p></li>
		
		
			<li className="dropdown">
		
				
<p>Payment Terms <span className="validation">*</span></p>
		<p><FormControl  className={useStyles.formControl} >
         
        <Select
         native inputProps={{ tabIndex: "4" }} 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.invoicelabel, value: this.state.payment }}
          onChange={this.myChangeHandler}
          id="payment"
		   value={this.state.payment}
		  name="payment"
		
		
        >
		{this.state.paymentarray.map(payment => (
            <option  
            data-pname={payment.display}
              value={payment.value}
            >
              {payment.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
		</li>
		
		</ul>
		
	</div>	
		
		
	<div className="invoiceleft">	

		<ul>
		
		
		<li >
		
					
<p>Sales Person <span className="validation">*</span></p>
		<p>
		<TextField
		
		type="text"
 hinttext="salesperson name"
 InputLabelProps={{
            shrink: true,
          }} 
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "5",maxLength:150 }} 
            fullWidth
			value={this.state.salespersonname}
            id="salespersonname"
          inputRef={element => (this.textSP = element)}
            name="salespersonname" 
			//onChange={this.myChangeHandler}
            autoComplete="salespersonname"
			onChange={this.escSalesFunction}
onFocus={this.escSalesFunction}
            
			
          />
		
		</p>
		</li>
		
		
		
		
		
		<li>
		
			
<p>GST No. </p>
		 <p><TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "6",maxLength:150 }} 
            fullWidth
			value={this.state.gstin}
            id="gstin"
          
            name="gstin" onChange={this.myChangeHandler}
            autoComplete="gstin"
            
			
          /></p>
		
		</li>
		
		
		
		</ul>
	</div>


<div className="invoiceright">	
		
		 
		 
		<p>Customer <span className="validation">*</span> <span className="addtitle"  onClick={()=>{this.Useraddbox(0)}}><AddIcon />Add New Customer</span>
	<Newcustomer ref="Newcustomer"  updateDonenew={this.insertDonenewParent}   />					
    </p>
		 <p>
		 
		 <Autocomplete
      id="custautoextender" 
	 
	  defaultValue={this.state.customer}
		  inputValue={this.state.custtags}
		    onInputChange={this.onInputChange}
	  onChange={this.onTagsChange}  
     options={strCust}
      renderInput={(params) => (
        <TextField {...params}   variant="outlined" />
      )}
      getOptionLabel={(option) => option.title}
      renderOption={(option) => (
        <React.Fragment>
          <table width='100%' >
            {option.rowindex==="1" &&(
          <thead>
          <tr>
            <th width='10%'>Name</th>
            <th width='10%'>Email</th>
			  <th width='10%'>Mobile No.</th>
			 
				  </tr> 
        </thead>)}
            <tbody>
              <tr>
                <td width='10%'>{option.title}</td>
                <td width='10%'>{option.email}</td>
				
                <td width='10%'>{option.mobileno}</td>
				
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      )}
    />
		 
		 
	
	  </p>
		
		
		
		
		</div>
	<div className="clear"></div>
	
	  <div className="customeraddress invoice">
		  <ul>
		 <li >
<p>Billing Address</p>
		 <p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.billingassress}
            id="billingassress"
			
            fullWidth
           
            name="billingassress" onChange={this.myChangeHandler}
            autoComplete="billingassress"
            autoFocus
          />  </p></li>
		  
		  
		  
		<li > 
<p>Shipping Address</p>
		<p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.shippingaddress}
            id="shippingaddress"
			
            fullWidth
           
            name="shippingaddress" onChange={this.myChangeHandler}
            autoComplete="shippingaddress"
            autoFocus
          />  </p></li>
		  </ul>
		 </div>
		 
		 
		 
	<div className="invoiceleft">	

		<ul>
		
		
		
	
	
	<li className="dropdown">
						
<p>Destination State/Country <span className="validation">*</span></p>
		<p><FormControl  className={useStyles.formControl} id="formdrp" >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.invoicelabel, value: this.state.dest }}
          onChange={this.myChangeHandler}
          id="dest"
		   value={this.state.dest}
		  name="dest"
		
		
        >
		{this.state.statarray.map(stat => (
            <option  
            
              value={stat.value}
            >
              {stat.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	
	</li>
	
	{this.state.ordertypedisplay === "1" && (	
	<React.Fragment>
	
<li className="dropdown">
<p>Order Type <span className="validation">*</span></p>
<p> <FormControl  className={useStyles.formControl}>
       
        <Select
          native
		   variant="outlined"
         value={this.state.ordertype}
          onChange={this.myChangeHandler}
        name='ordertype'
         id='ordertype'
		  InputLabelProps={{
            shrink: true,
          }}
        >
		  <option value={0}>Select Order Type</option>
         
          <option value={1}>Invoice</option>
          <option value={2}>Exchange</option>
          
		
        </Select>
      </FormControl></p>
	  </li>
	  
	</React.Fragment>)}
	  
	  </ul></div>
	  
	<div className="invoiceright">	
	  
	  <p> Comments <span className="validation">*</span>   </p>
	  
	 <p> <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.comment}
            id="comment"
			
            fullWidth
           
            name="comment" onChange={this.myChangeHandler}
            autoComplete="comment"
            autoFocus
          />  </p>
	  
	  
	  </div>
	  
	
	<div className="clear"></div>
	
<br/>

   {this.state.exchangeproductdisplay === "1" && ( <React.Fragment>
<div className="exchangediv">
 
 <ul>
<li><b>Exchange &nbsp;&nbsp;</b></li>	
		
<li>
 <Autocomplete
      id="free-solo-demo"
      
      
      options={strProductexchange}
	   onChange={this.onTagsproductChangeexchange} 
	    value={this.state.selectedproductexchange}
      renderInput={(params) => (
        <TextField {...params} onChange={this.onloadProductsexchangeBind}  variant="outlined" />
      )}
      getOptionLabel={(option) => option.title}
      renderOption={(option) => (
        <React.Fragment>
          <table width='200%' >
            {option.rowindex=="1" &&(
          <thead>
          <tr>
            <th width='10%'>Product Code</th>
            <th width='10%'>Product Name</th>
			  <th width='10%'>S.Rate(Exc)</th>
			   <th width='10%'>S.Rate(Inc)</th>
			    <th width='10%'>Tax</th>
				 <th width='10%'>Stock</th>
				  </tr> 
        </thead>)}
            <tbody>
              <tr>
                <td width='10%'>{option.pr_code}</td>
                <td width='10%'>{option.title}</td>
				
                <td width='10%'>{option.pr_salesrateexcl}</td>
				<td width='10%'>{option.pr_salesrateincl}</td>
				 <td width='10%'>{option.tax}</td>
			
                <td width='10%'>{option.stock}</td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      )}
    />
 </li>

</ul>	
</div>	
<br/>

<div className="exchangedisplay">




<Paper className={useStyles.root}>
  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  

					  
		<TableHead> 
<TableRow>
  <TableCell width="25%"><b>Product Name</b></TableCell>     
  <TableCell width="10%"><b>S.Rate(Exc)</b></TableCell>  
  <TableCell width="10%"><b>S.Rate(Inc)</b></TableCell>  
  <TableCell width="20%"><b>Discount</b></TableCell>  
  <TableCell width="10%"><b>Tax</b></TableCell>   
  <TableCell width="10%"><b>Qty</b></TableCell>  
  <TableCell width="10%"><b>Amount</b></TableCell>    
   	
		
</TableRow>
</TableHead> 
   
                      <TableBody>

                     {(this.state.exchangepvdata).map((item,index) => {  
           return ( <React.Fragment>
 <TableRow key={index} >

  {item.op_isdeleted!==1 && (<React.Fragment>

	<TableCell>{item.title}</TableCell>
	<TableCell>{item.pr_salesrateexcl}</TableCell>
	<TableCell>{item.pr_salesrateincl}</TableCell>
	<TableCell>{item.discount}  </TableCell>
	<TableCell>{item.tax}</TableCell>
	<TableCell>{item.qty}</TableCell>
	<TableCell>{item.amount}</TableCell>
	
</React.Fragment>	
)}
 </TableRow>


				</React.Fragment>	
				);
					})}
                      </TableBody>


</Table>
  </TableContainer> 
  
  </Paper>
   




</div>
  </React.Fragment>)}

<br/>
	
	<div className="ordtbl">
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table"> 
 
 <TableHead> 
<TableRow>
  <TableCell width="25%"><b>Product Name</b></TableCell>     
  <TableCell width="10%"><b>S.Rate(Exc)</b></TableCell>  
  <TableCell width="10%"><b>S.Rate(Inc)</b></TableCell>  
  <TableCell width="20%"><b>Discount</b></TableCell>  
  <TableCell width="10%"><b>Tax</b></TableCell>   
  <TableCell width="10%"><b>Qty</b></TableCell>  
  <TableCell width="10%"><b>Amount</b></TableCell>    
   <TableCell><SyncAltIcon /> </TableCell> 
						
                      </TableRow></TableHead>

		   
               
                      <TableBody>
					  
					  
					  
			
<TableRow>	
	 
			<TableCell colspan="8" width="100%">	<TextField  
type="text"
 hinttext="Scan Code"
 InputLabelProps={{
            shrink: true,
          }} 
		  value={this.state.scancode}
 variant="outlined" 
 id="scancodestyle" 
 //label="Scan Code"   
 placeholder="Barcode"
 name="scancode" 
  inputRef={element => (this.textBarcode = element)}
className="scancodestyle"
onChange={this.escFunction}
onFocus={this.escFunction}
 />	
  </TableCell>
  </TableRow>	



            {(this.state.pvdata).map((item,index) => {


				
           return ( <React.Fragment>
		   
	
		   
		   
		   {item.op_isdeleted!==1 && (	 
 <TableRow key={index} >



<TableCell>

{(this.state.showField!==item.op_id) && (


<p>{item.title}</p>


)}
 
 
 
 {this.state.showField===item.op_id && (
 
 
 <Autocomplete
      id="free-solo-demo"
      
      
      options={strProduct}
	   onChange={this.onTagsproductChange} 
	    value={this.state.selectedproduct}
      renderInput={(params) => (
        <TextField {...params} onChange={this.onloadProductsBind}  variant="outlined" />
      )}
      getOptionLabel={(option) => option.title}
      renderOption={(option) => (
        <React.Fragment>
          <table width='200%' >
            {option.rowindex=="1" &&(
          <thead>
          <tr>
            <th width='10%'>Product Code</th>
            <th width='10%'>Product Name</th>
			  <th width='10%'>S.Rate(Exc)</th>
			   <th width='10%'>S.Rate(Inc)</th>
			    <th width='10%'>Tax</th>
				 <th width='10%'>Stock</th>
				  </tr> 
        </thead>)}
            <tbody>
              <tr>
                <td width='10%'>{option.pr_code}</td>
                <td width='10%'>{option.title}</td>
				
                <td width='10%'>{option.pr_salesrateexcl}</td>
				<td width='10%'>{option.pr_salesrateincl}</td>
				 <td width='10%'>{option.tax}</td>
			
                <td width='10%'>{option.stock}</td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      )}
    />
 
 
 
 )}
 
 </TableCell>  
 
 
          
 
  
<TableCell>


{(this.state.showField!==item.op_id ) && (


<p>{item.pr_salesrateexcl}</p>


)}
 
 
 
 {this.state.showField===item.op_id && (
 


< TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={item.pr_salesrateexcl}
            id="salesrateexcl"
          
			//type="number"
            name="pr_salesrateexcl"
			onChange={this.myChangeHandlerPV}
          inputProps={{
    maxLength: 5
  }}
			
		 />
 
 )}
</TableCell>   

<TableCell>


{(this.state.showField!==item.op_id)&& (


<p>{item.pr_salesrateincl}</p>


)}
 
 
 
 {this.state.showField===item.op_id && (
 

 
<TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={item.pr_salesrateincl}
            id="salesrateincl"
           
			//type="number"
            name="pr_salesrateincl"
			onChange={this.myChangeHandlerPV}
          inputProps={{
    maxLength: 5
  }}
			
		 />

 )}
</TableCell>   

<TableCell>


 
{(this.state.showField!==item.op_id ) && (

<React.Fragment>

<p>{item.discount}  {item.olddistypetitle}</p>
</React.Fragment>

)}

 
 <div className="inputleft">
 
 {this.state.showField===item.op_id && (
 


< TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={item.discount}
           // id="discount"
           id="inputwidth"
			//type="number"
            name="discount"
			onChange={this.myChangeHandlerPV}
          inputProps={{
    maxLength: 5
  }}
			
		 />
	)}	
</div>

<div className="inputright">
{this.state.showField===item.op_id && (	
		 
	<FormControl className={useStyles.formControl} >
         
        <Select
         native
        variant="outlined"
		  defaultValue={{ label: this.state.rolelabel, value: item.discounttype}}
          onChange={this.myChangeHandlerPV}
          
		   value={item.discounttype}
		  name="discounttype"
        >
		
		  <option value="2"> Rs </option>
          <option value="1"> % </option>
          	 
		  </Select>
 </FormControl>


 )}
 
 </div>
 
 
 <div className="clear"></div>
</TableCell>  
<TableCell>


 {this.state.showField!==item.op_id && (


<p>{item.tax}</p>


)}
 
 
 
 {this.state.showField===item.op_id && (
 


<FormControl  className={useStyles.formControl} >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.invoicelabel, value: item.pr_tx_id }}
          onChange={this.myChangeHandlerPV}
          id="tax"
		   value={item.pr_tx_id}
		  name="pr_tx_id"
		
		
        >
		{this.state.taxarray.map(tax => (
            <option  
            
              value={tax.value}
            >
              {tax.display}
            </option  >
          ))}
		
        </Select>
 </FormControl>



 )}
</TableCell> 
 
 
<TableCell>




 {this.state.showField!==item.op_id && (


<p>{item.qty}</p>


)}
 
 
 
 {this.state.showField===item.op_id && (
 





 
< TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={item.qty}
            id="qty"
           
			inputRef={element => (this.textRef = element)}
            name="qty"
			onChange={this.myChangeHandlerPV}
          inputProps={{
    maxLength: 5
  }}
			
		 />

 
 )}
</TableCell> 



 <TableCell>
 


 {this.state.showField!==item.op_id && (


<p>{item.amount}</p>


)}

 
 
 
 {this.state.showField===item.op_id && (
 


 
 < TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={item.amount}
            id="amount"
           
			//type="number"
            name="amount"
			onChange={this.myChangeHandlerPV}
          inputProps={{
    maxLength: 5
  }}
			
		 />
		 
 
 )}
</TableCell> 









{this.state.showField===item.op_id && (

<TableCell> 


<Tooltip title="Save">
 <IconButton aria-label="Save">
<div className="addbtns">
<AddIcon onClick={()=>{this.fieldsave(item.op_id)}} />
</div>
 </IconButton>
 </Tooltip>
 
 
</TableCell> 
)}









{this.state.showField!==item.op_id && (	
<TableCell align="right">


{item.f !=="-1" &&(
<React.Fragment>


<Tooltip title="Edit">
 <IconButton aria-label="Edit">
<div className="primary">
 <EditIcon onClick={this.fieldedit.bind(this, item.op_id)} />
 </div>
 </IconButton>
 </Tooltip>
 
 
 </React.Fragment>  
  )}
 

 <Tooltip title="Delete">
 <IconButton aria-label="Delete">
 <div className="secondary">
 <DeleteIcon onClick={()=>{this.fielddelete(item.op_id)}}/>
 </div>
 </IconButton>
 </Tooltip>
 

{item.opstatus === "2" &&(
<React.Fragment>
  <Tooltip title="Delete">
 <IconButton aria-label="Delete">
 <div className="delsecondary">
 <RestoreFromTrashIcon onClick={()=>{this.fieldstockalter(item.op_id)}}/>
 </div>
 </IconButton>
 </Tooltip>
 </React.Fragment>
)}

 </TableCell>
)}



 <TableCell>

 </TableCell>

 </TableRow>
 
)}


 
 
				</React.Fragment>	
				);
					})}
					
					
					
					
					 <TableRow>
	<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
		
					 
 <TableCell ><b>Sub Total</b> </TableCell>	
 
 
 <TableCell> 
 
 {this.state.subtotal.toFixed(2)}

 </TableCell>
<TableCell ></TableCell>
  </TableRow>
  
  

	
 <TableRow>
	<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell>

<TextField
            variant="outlined"
            margin="normal"           
            
			    
            name="orddiscount" 
			  
	 value={this.state.orddiscount}
     onChange={this.handleboxChangee()}
      
			
          />

</TableCell>	
<TableCell>

<FormControl className={useStyles.formControl} >
         
        <Select
         native
        variant="outlined"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.orddiscounttype}}
          onChange={this.handleboxChangee()}
          
		   value={this.state.orddiscounttype}
		  name="orddiscounttype"
        >
		
		  <option value="2"> Rs </option>
          <option value="1"> % </option>
          	 
		  </Select>
 </FormControl>

</TableCell>	
		
					 
 <TableCell ><b>Discount</b> </TableCell>	
 
 
 <TableCell> 
 
 {this.state.ordtotaldiscount.toFixed(2)}

 </TableCell>
<TableCell ></TableCell>	
  </TableRow>
	
	
	
	 <TableRow>
	<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
		
					 
 <TableCell ><b>GST</b> </TableCell>	
 
 
 <TableCell> 
 
 {this.state.ordgst}

 </TableCell>
<TableCell ></TableCell>	
  </TableRow>
  
  
   <TableRow>
	<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell ></TableCell>	
<TableCell>

 <TextField
            variant="outlined"
            margin="normal"           
            id="roundoffval"
			value={this.state.roundoffval}        
            name="roundoffval"   onChange={this.handlechangeroundoff()}
            autoComplete="roundoffval"
			
          />


</TableCell>	
		
					 
 <TableCell ><b>Round off</b> </TableCell>	
 
 
 <TableCell> 
 
 {this.state.roundoff}

 </TableCell>
<TableCell ></TableCell>	
  </TableRow>
  
  
  
   <TableRow>
	<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
<TableCell></TableCell>	
		
					 
 <TableCell ><b>Grand Total</b> </TableCell>	
 
 
 <TableCell> 
 
 {this.state.grandtotal}

 </TableCell>
<TableCell ></TableCell>	
  </TableRow>
  
  
  
  
  
  
  
  
  
  
  
                      </TableBody>



</Table>
  </TableContainer> 
 
  </Paper>
  
  
   

  
  
  
  
  
  
  
  
  
  
  
  {this.state.taxdatacount!==0 && (
  
  <table className="tabbg" cellspacing="1" cellpadding="5">
    <tr><th></th><th>Taxable</th><th colspan="2">	CGST</th><th colspan="2">	SGST</th><th>	Total</th></tr>
	 <tr><th>#</th><th>Taxable</th><th >	Rate</th><th >	Amount</th><th >		Rate</th><th >	Amount</th><th align="center">	Total</th></tr>
	
  {(this.state.taxdata).map((item,index) => {


let j=index+1;
		  
					
           return ( <React.Fragment>
		    {item.op_isdeleted!==1 && (
  <tr><td>{j}</td><td align="right">{item.taxamount.toFixed(2)}</td><td align="right">{item.cgstrate}%</td>
<td align="right">Rs.{item.cgstamt.toFixed(2)}</td><td align="right">{item.sgstrate}%</td>
<td align="right">Rs.{item.sgstamt.toFixed(2)}</td><td align="right">Rs.{item.totaoatax}</td></tr>
 )}
  </React.Fragment>)
		
  
  }
  )}
    </table>
	 )}
	 
	 <br/>
	<span className="validation"> Note *</span> 
	<p><div className="secondary">
	
 <DeleteIcon /> </div>  : Delete From Invoice  </p>
	
	<p><div className="delsecondary">
	<RestoreFromTrashIcon /> </div>  : Delete From Invoice and Alter Stock </p>
	
	
	
 
   </div>
 </div>

<div className="buttons">

<p className="btnleft"><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            id="discard"
            className={useStyles.submit}
			
			onClick={this.handleDiscard}
			
          >
           Discard
          </Button></p>

<p className="btnright"><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
           id="searchform" 
            className={useStyles.submit}
			
			onClick={this.handleSubmit}
			disabled={this.state.btndisabled}
			
          >
           {this.state.submitbtn}
          </Button>



</p>
<div className="clear"></div>
</div>


   </div></div>
 </React.Fragment>
		</Section></Page>
  );
  }	
   

}




export  class  Newcustomer extends Component {



 constructor(props) {
    super();
	
	
	
	 this.state = {
	 
	 
	search:'',order:'desc' ,orderBy:'cu_id',cu_id:0,contactname:'',email:'',mobileno:'',panno:'',gstinno:'',billingassress:'',shippingaddress:'',payment:'',
	place:'',placearray:[],paymentarray:[],payment:'',cuid:'',open1:false
	 
	  
        
      }
	 };
  
 
  onloadplacebind(){

    axios.get(Serverurl+"ajaxstate.php?action=list")
    
      .then((response) => {    
     
      this.setState({  placearray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.placearray.map(place => {
                            
          return {value: place.pl_id, display:place.pl_name}
        });
      
		
        this.setState({
          placearray: [{value: '0', display: 'Select State'}].concat(roleFromApi)
        });
      })


}

onloadpaymentbind(){

    axios.get(Serverurl+"ajaxpaymentterms.php?action=list")
    
      .then((response) => {    
     
      this.setState({  paymentarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.paymentarray.map(role => {
                            
          return {value: role.pt_id, display:role.pt_title}
        });
      
		
        this.setState({
          paymentarray: [{value: '0', display: 'Select Payment'}].concat(roleFromApi)
        });
      })


}
componentDidMount() {
 
 if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
 
 
   let search = window.location.search;
let params = new URLSearchParams(search);
let cuid = params.get('cuid');


if(cuid!=null)
{
	
	setTimeout(()=>this.Usergetdata(cuid),500); 
	
	
}


} 
  
handleErrorclose=(event)=>{
	  
	this.setState({open1: false});  
  }  
  
  
  Usergetdata(cuid) {
	
 
	  
	   axios.get(Serverurl+'ajaxcustomer.php?action=list&cu_id='+cuid)
  .then(resp => {  
		
    if(resp.data.length>0){
    this.setState({ 
  
      cu_id:resp.data[0].cu_id,
	
	
	contactname:resp.data[0].cu_contactname, 
   
	email:resp.data[0].cu_email,
	mobileno:resp.data[0].cu_mobileno,
	
	panno:resp.data[0].cu_panno,
	
	gstinno:resp.data[0].cu_gstinno,
	billingassress:resp.data[0].cu_billingassress,
	shippingaddress:resp.data[0].cu_shippingaddress,
	
	
	payment:resp.data[0].cu_payment,
	place:resp.data[0].cu_pl_id,
	
	
       	  
	  
       
    
    });

  }
  
});
	  
  
  
    }

   
handleClickOpen = (e) => {
 
  this.setState({ open:!this.state.open,cu_id:0,contactname:'',email:'',mobileno:'',panno:'',gstinno:'',billingassress:'',shippingaddress:'',payment:'',
	place:'',placearray:[],paymentarray:[],payment:''
	  
		 });
		this.Usergetdata();
		
this.onloadplacebind();

this.onloadpaymentbind();



};
myChangeHandler = (event) => {
	
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
  


}

 


handleClose = (e) => {

 
 
 
  
	let contactname = this.state.contactname;
    let email = this.state.email;
	let mobileno = this.state.mobileno;
  
	let panno = this.state.panno;
	
	
	let gstinno = this.state.gstinno;
    let billingassress = this.state.billingassress;
	let shippingaddress = this.state.shippingaddress;
    
	

	let payment = this.state.payment;
	
	let place = this.state.place;
	
	
 var formData = new FormData();

	

if(contactname==="")
{
	this.setState({errormessage:"Please Enter Contact Name.",open1:true});
	return false;
}	


if(mobileno==="")
{
	this.setState({errormessage:"Please Enter mobileno.",open1:true});
	window.scrollTo({
          top: 0
      })
	return false;
}




if(panno==="")
{
	this.setState({errormessage:"Please Select panno.",open1:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(gstinno==="")
{
	this.setState({errormessage:"Please Enter gstinno.",open1:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(billingassress==="")
{
	this.setState({errormessage:"Please Enter Billing Address.",open1:true});
	window.scrollTo({
          top: 0
      })
	return false;
}


if(shippingaddress==="")
{
	this.setState({errormessage:"Please Enter Shipping Address.",open1:true});
	window.scrollTo({
          top: 0
      })
	return false;
}




  
  
  
  
	  
	 
	 	

  formData.append('action','insertorupdate') 


 formData.append('cu_id',0) 
 formData.append('cu_contacttype',1)
formData.append('cu_contactname',contactname)
formData.append('cu_email',email)
formData.append('cu_mobileno',mobileno)

formData.append('cu_panno',panno)


formData.append('cu_gstinno',gstinno)
formData.append('cu_billingassress',billingassress)
formData.append('cu_shippingaddress',shippingaddress)


formData.append('cu_payment',payment)
formData.append('cu_pl_id',place)



		
		
		/*formData.append('userid',1)
		  
		axios.post(Serverurl+"ajaxcustomer.php", formData, {
        }).then(res => {
    
        
    
        });
  
	this.setState({ open:false });*/
	
	formData.append('userid',localStorage.getItem("Ruserid"))	
	
	 axios.post(Serverurl+"ajaxcustomer.php", formData, {
        }).then(res => {
			
			//this.props.updateDonenew();
			
			
		  if(res.data==-1)
{ 
	 

		this.setState({errormessage:"Email Already Exist.",open1:true
		});
		

	}else{
		this.setState({errormessage:"Record Updated Successfuly",open1:true	,
		
			
			
			
			
			
			
	
		open:false,
		contactname:'',
		email:'',
		mobileno:'',		
		panno:'',		
		gstinno:"",
		billingassress:'',
		shippingaddress:'',
		payment:'',
		place:'',			
		cu_id:0
		});
	if(res.data[0].cuid !== "")
 {
	localStorage.setItem("newcuid",res.data[0].cuid);
	this.props.updateDonenew();
		/*this.setState({cuid:res.data[0].cuid,
		cuname:res.data[0].cuname})*/
		
 }
			
	}		
            });
	
	
	
};

render (){

return(
<React.Fragment>





    <Dialog open={this.state.open} fullWidth={true } onClose={this.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"> Add  Customer</DialogTitle>
      <DialogContent>
	  
	  
	  <p>  <Collapse in={this.state.open1}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
	  
		<div className="newcustomer">				  
						 
      <ul>


		  

       
		  
		<li>  
		<p>Contact Name <span className="validation">*</span></p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.contactname}
            id="contactname"
          
            name="contactname" onChange={this.myChangeHandler}
            autoComplete="contactname"
            
			
          /></p></li>
		  
	 
		  
		<li>  
		<p>Email </p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.email}
            id="email"
          
            name="email" onChange={this.myChangeHandler}
            autoComplete="email"
			
            
			
          /></p></li>
		  
		 
		 
		 <li>  
		 <p>Mobile.No <span className="validation">*</span></p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.mobileno}
            id="mobileno"
           
            name="mobileno" onChange={this.myChangeHandler}
            autoComplete="mobileno"
			inputProps={{maxLength:10,  }} 
            
			
          /></p></li>
		  
		 
		 
		 
		 
		 
		 <li>  
		 <p>Pan.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.panno}
            id="panno"
           
            name="panno" onChange={this.myChangeHandler}
            autoComplete="panno"
			inputProps={{maxLength:10,  }} 
            
			
          /></p></li>
		 
		
		 
		 <li> 
<p>GSTIN.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.gstinno}
            id="gstinno"
          
            name="gstinno" onChange={this.myChangeHandler}
            autoComplete="gstinno"
			
            
			
          /></p></li>
		  
		 
		 
		  <li> 
<p>Payment Terms <span className="validation">*</span></p>
		  <p><FormControl  className={useStyles.formControl} >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.payment }}
          onChange={this.myChangeHandler}
          id="payment"
		   value={this.state.payment}
		  name="payment"
		
		
        >
		{this.state.paymentarray.map(payment => (
            <option  
            
              value={payment.value}
            >
              {payment.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p></li>
		  
		 
		 
		 
		 <li> 
		 <p>State <span className="validation">*</span></p>
		<p><FormControl  className={useStyles.formControl} >
         
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.place }}
          onChange={this.myChangeHandler}
          id="place"
		   value={this.state.place}
		  name="place"
		
		
        >
		{this.state.placearray.map(place => (
            <option  
            
              value={place.value}
            >
              {place.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
		
		
		
		
		
		</li>
		
		<li></li>
		<br/>
		<li>
<p>Billing Address</p>
		 <p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.billingassress}
            id="billingassress"
			
            fullWidth
           
            name="billingassress" onChange={this.myChangeHandler}
            autoComplete="billingassress"
            autoFocus
          />  </p></li>
		  
		  
		  
		<li> 
<p>Shipping Address</p>
		<p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.shippingaddress}
            id="shippingaddress"
			
            fullWidth
           
            name="shippingaddress" onChange={this.myChangeHandler}
            autoComplete="shippingaddress"
            autoFocus
          />  </p></li>
		  
		  
		
		
		
		
		
		
		</ul>
		</div>
     
						  
			
                 
               </DialogContent>
      <DialogActions>
        <Button onClick={this.handleClickOpen} color="secondary">
          Cancel
        </Button>
        <Button onClick={this.handleClose} color="secondary">
          Save
        </Button>
		
		 
		
      </DialogActions>
    </Dialog>
</React.Fragment>





) }
  

} 