import React ,{Component} from 'react';


import { Page, Section } from 'react-page-layout';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Serverurl from './Apiurl';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [



]

function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
 
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  
  form: {
    width: '500%', 
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
}));

export default class Productdetails extends Component {
	
	constructor(props) {
    super();
	
	
	
	
	 this.state = {
         logredirect:false,pagename:'',redirect: false,prdData:[],
totalrevenue:'',productsold:'',unpaidinvoice:'',newclients:'',opensales:'',
currentstock:'',
      }
	 };


  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


componentDidMount() {
 
 if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
 
 else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "3" || localStorage.getItem("Rrole") === "4" )

	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  

	}
 
   /*let search = window.location.search;
let params = new URLSearchParams(search);
let prid = params.get('prid');*/

let search = window.location.href;
let prid = this.getParameterByName('prid',search);



if(prid!=null)
{
	
	setTimeout(()=>this.onloadproductbyid(prid),500); 
	//this.onloadtaxbind(prid);
	
}

this.onloadproductcountbind(prid);


}





onloadproductcountbind(prid){
		
axios.get(Serverurl+'ajaxproduct.php?action=productcount&pr_id='+prid)
 
  
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		
			this.setState({  
			
    totalrevenue: Number(resp.data[0].totalrevenue).toFixed(2),	
              productsold: resp.data[0].productsold,	
              unpaidinvoice: resp.data[0].unpaidinvoices,
              newclients: resp.data[0].newclients,
              opensales: Number(resp.data[0].opensales).toFixed(2),
               currentstock	: Number(resp.data[0].currentstock),		  
			   
			});
			 
     }
  
    });
	


		
	}


myChangeHandler = (event) => {
	
	const re = /^[0-9\b]+$/; 
	const renew = /^[0-9\b.]+$/; 
  let nam = event.target.name;
  let val = event.target.value;
  
   this.setState({[nam]: val});
  
  
  
  
  
 

 }  

myRoleChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
 this.setState({[nam]: val});
  


}

handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
  
  
  
onloadproductbyid(prid){
		
		 axios.get(Serverurl+'ajaxproduct.php?action=list&pr_id='+prid)
  .then(resp => {  
   if(resp.data.length>0){
	   
    this.setState({ 
	  prdData: resp.data, 
   	
	
  
	});
	
   }
  });

} 





	

render() {
	


	
	return (
	
	<Page layout="public">

<Section slot="menu"><a href='#/configuration'>Configuration</a> / Product
</Section>

<Section slot="btn"><a href='#/adminproduct'>Back</a>
</Section>

<Section slot="content">
	
	
	
<div class="Productdetails">

	
 
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  

		    
            
                      <TableBody>

                     {stableSort(this.state.prdData, getSorting(this.state.orderBy, this.state.order)).map((item,index) => {  
           return ( <React.Fragment>
		   
	<TableRow>
	
<TableCell className="tblhead">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Product Code : <b>{item.pr_code}</b></TableCell>

</TableRow>	


<TableRow>	
<div className="prddetailsmain">
	   
 <div className="prddetailsleft">
 
 <h4>Details :</h4>
 
<p>Product Name : {item.pr_name}</p>
<p>Product Type : {item.pr_type}</p>

	{item.pr_hsncode!=="" && ( <React.Fragment>
<p>Hsn Code : { item.pr_hsncode}</p>
	</React.Fragment>)}
<p>Category : {item.ca_name}</p>
<p>Min stock : {item.pr_minstock}</p>
 
 </div>
 
 
 <div className="prddetailsright">
 <ul>


<li>
<p className="boxtopright"><PeopleAltIcon /></p>
<p className="boxbtmleft">{this.state.totalrevenue} </p>
<p className="boxbtmleft">Total Revenue</p>


</li>

<li>
<p className="boxtopright"><PersonAddIcon /> </p>
<p className="boxbtmleft">{this.state.opensales}</p>
<p className="boxbtmleft">Open Sales Orders</p>

</li>




</ul>
<br/>

<ul>
<li>
<p className="boxtopright"> <ShoppingCartIcon /></p>
<p className="boxbtmleft">{this.state.currentstock} </p>
<p className="boxbtmleft">Current Stock</p>

</li>

<li>
<p className="boxtopright"><PeopleAltIcon /></p>
<p className="boxbtmleft">{this.state.unpaidinvoice} </p>
<p className="boxbtmleft">Unpaid Invoices</p>


</li>




</ul>
 </div>

<div className="clear"></div>
</div>
<br/>
</TableRow>	


			</React.Fragment>	
				);
					})}
                      </TableBody>


</Table>
  </TableContainer> 
   
  </Paper>


          
</div>


	 
	 </Section></Page>		
		  
		 
		  




  );
  }
}