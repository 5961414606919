import React ,{Component} from 'react';

import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';

import Serverurl from './Apiurl';
import Uploadsurl from './Apiurl';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';


import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import SchoolIcon from '@material-ui/icons/School';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';

import { Page, Section } from 'react-page-layout';


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  row:{border:0},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});

export default class customerviewmore extends Component {
	
	constructor(props) {
    super(props);
	
	this.state = {logredirect:false,pagename:'',customerviewmore:[],order:'desc',orderBy:'cu_id',page: 0 ,
	prevpage:0,totaldata:0,rowsPerPage:20,quaviewmoredata:[],expdata:[],otherdocdata:[],
	redirect:false,vredirect:false,prevca_icon:'',previd_icon:'',
	promotiondata:[]}
	
	
	 
	  
	}
	
	
	myChangeHandler = (event) => {
	const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;
  
 
}

componentDidUpdate() {
		
  if(this.state.prevpage != this.state.page) {
    this.setState({   prevpage: this.state.page});
 
  }
}



 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}



componentDidMount() {
	
	/* let search = window.location.search;
let params = new URLSearchParams(search);
let cuid = params.get('cuid');*/

let search = window.location.href;
let cuid = this.getParameterByName('cuid',search);

	
document.title = "Customer Viewmore"
	
	if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
	else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "3" || localStorage.getItem("Rrole") === "4" )

	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  

	}
 
 localStorage.setItem("page",this.state.rowsPerPage);
  this.apicall(cuid);  
  this.viewmoreorder(); 
}

apicall(cuid){	
  axios.get(Serverurl+'ajaxcustomer.php?action=customerviewmore&cu_id='+cuid)
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  customerviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			
			});
	
	}
	
	   		
		})
	
		
}


viewmoreorder(){
	
	 axios.get(Serverurl+"ajaxcustomer.php?action=orderviewmore")
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  StoreData: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
  
    });
}



		
		
 render() {
	

	 /*let search = window.location.search;
let params = new URLSearchParams(search);
let cuid = params.get('cuid');*/
let search = window.location.href;
let cuid = this.getParameterByName('cuid',search);
	  
  return ( 
  
  
   <Page layout="public">

<Section slot="menu">Configuration / View more
</Section>

<Section slot="content">
  
  <React.Fragment>
 



<div className="customerview">



   {this.state.customerviewmore.map((item,index) => { 

				 
           return ( <React.Fragment>
	
<TableRow key={index} >

<TableCell className={useStyles.row}>

<div className="cusdetails">
<h4>Details : </h4>

<TableRow> <TableCell id="ldtblcel"> Customer Name </TableCell> <TableCell id="ldtblcel"> : </TableCell> <TableCell id="ldtblcel"> {item.cu_contactname} </TableCell></TableRow> 
 
  
<TableRow> <TableCell id="ldtblcel"> Email </TableCell> <TableCell id="ldtblcel"> : </TableCell> <TableCell id="ldtblcel"> {item.cu_email} </TableCell></TableRow> 

<TableRow> <TableCell id="ldtblcel"> Phone No </TableCell> <TableCell id="ldtblcel"> : </TableCell> <TableCell id="ldtblcel"> {item.cu_phonenum} </TableCell></TableRow>    

<TableRow> <TableCell id="ldtblcel"> Pan No </TableCell> <TableCell id="ldtblcel"> : </TableCell> <TableCell id="ldtblcel"> {item.cu_panno} </TableCell></TableRow> 

<TableRow> <TableCell id="ldtblcel"> GSTN No </TableCell> <TableCell id="ldtblcel"> : </TableCell> <TableCell id="ldtblcel"> {item.cu_gstinno} </TableCell></TableRow> 

<br></br>

<h4>Billing Address : </h4>
 
<TableRow> <TableCell id="ldtblcel"> {item.cu_billingassress} </TableCell></TableRow> 


<h4>Shipping Address : </h4>
<TableRow> <TableCell id="ldtblcel"> {item.cu_shippingaddress} </TableCell></TableRow>    

</div>
</TableCell></TableRow> 


<div className="orders">

<h4>Order{item.ordercount}</h4>	


</div>			
<div className="clear"></div>	
				
	 
 </React.Fragment>
 )
  })}	 



</div>


   
</React.Fragment>

		 </Section></Page>	 
 
 	) ;

 }
} 
			
			
			
	
	
	 
  





		