import React ,{Component} from 'react';

import { Page, Section } from 'react-page-layout';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Serverurl from './Apiurl';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';



import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';


const useStyles = makeStyles(theme => ({
 
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  
  form: {
    width: '500%', 
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
}));

export default class addcustomer extends Component {
	
	constructor(props) {
    super();
	
	var today = new Date();
	var todayMonth=(today.getMonth() + 1);
	if(todayMonth<10)
		todayMonth='0'+todayMonth;
    var        cudate = today.getFullYear() + '-' + todayMonth + '-' + today.getDate();
	
	
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],redirect: false,
	 
	 
	search:'',order:'desc' ,orderBy:'cu_id',cu_id:0,contacttype:'',contactname:'',email:'',mobileno:'',phonenum:'',panno:'',gstinno:'',billingassress:'',shippingaddress:'',accountnum:'',ifsccode:'',bankname:'',branchname:'',dob:'',weddinganniversary:'',payment:'',
	place:'',placearray:[],fdate:cudate,fdate1:'',adate:cudate,adate1:'',paymentarray:[],payment:'',
		btndisabled:false,
submitbtn:"Submit",
	 
	 
	  
        
      }
	 };


 



onloadplacebind(){

    axios.get(Serverurl+"ajaxstate.php?action=list")
    
      .then((response) => {    
     
      this.setState({  placearray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.placearray.map(place => {
                            
          return {value: place.pl_id, display:place.pl_name}
        });
      
		
        this.setState({
          placearray: [{value: '0', display: 'State'}].concat(roleFromApi)
        });
      })


}



onloadpaymentbind(){

    axios.get(Serverurl+"ajaxpaymentterms.php?action=list")
    
      .then((response) => {    
     
      this.setState({  paymentarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.paymentarray.map(role => {
                            
          return {value: role.pt_id, display:role.pt_title}
        });
      
		
        this.setState({
          paymentarray: [{value: '0', display: 'Payment'}].concat(roleFromApi)
        });
      })


}


handfrChange = (date) => {
	 var month="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date.getDate()


	 })

  }
  



handanChange = (date) => {
	 var month="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
     this.setState({ adate:date,
adate1:date.getFullYear() + '-' + month + '-' +date.getDate()


	 })

  }

getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


componentDidMount() {
 
 
 if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		  
		  
		  
	else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "3" || localStorage.getItem("Rrole") === "4" )

	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  

	}	  
		  
 
 /*
   let search = window.location.search;
let params = new URLSearchParams(search);
let cuid = params.get('cuid');*/

	let search = window.location.href;
			let cuid = this.getParameterByName('cuid',search);
if(cuid!=null)
{
	
	setTimeout(()=>this.onloaduserbyid(cuid),500); 
	//this.onloaduserbyid(cuid);
	
}


this.onloadplacebind();
//alert (this.state.fdate1);
this.onloadpaymentbind();

}

myChangeHandler = (event) => {
	
	const re = /^[0-9\b]+$/; 
	
  let nam = event.target.name;
  let val = event.target.value;
  
  if(nam==="mobileno" || nam==="phonenum")
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	  
  }
  }
  else{
  this.setState({[nam]: val});
  }
  
  
  
  
 

 }  

myRoleChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
 this.setState({[nam]: val});
  


}

handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
  
  
  
onloaduserbyid(cuid){
		
		 axios.get(Serverurl+'ajaxcustomer.php?action=list&cu_id='+cuid)
  .then(resp => {  
   if(resp.data.length>0){
	   
    this.setState({ 
   	cu_id:resp.data[0].cu_id,
	
	contacttype:resp.data[0].contacttype,	
	contactname:resp.data[0].cu_contactname, 
   
	email:resp.data[0].cu_email,
	mobileno:resp.data[0].cu_mobileno,
	phonenum:resp.data[0].cu_phonenum,
	panno:resp.data[0].cu_panno,
	
	gstinno:resp.data[0].cu_gstinno,
	billingassress:resp.data[0].cu_billingassress,
	shippingaddress:resp.data[0].cu_shippingaddress,
	
	fdate:resp.data[0].cu_dob,
	
	
	adate:resp.data[0].cu_weddinganniversary,
	payment:resp.data[0].cu_payment,
	place:resp.data[0].cu_pl_id,
	accountnum:resp.data[0].cb_accountnum,
	
	ifsccode:resp.data[0].cb_ifsccode,
	bankname:resp.data[0].cb_bankname,
	branchname:resp.data[0].cb_branchname,
	
  
	});
	
   }
  });

} 


handleSubmit= (event) => {
    
    event.preventDefault();
	//alert('')
    
  
    let contacttype = this.state.contacttype;
	let contactname = this.state.contactname;
    let email = this.state.email;
	let mobileno = this.state.mobileno;
    let phonenum = this.state.phonenum;
	let panno = this.state.panno;
	
	
	let gstinno = this.state.gstinno;
    let billingassress = this.state.billingassress;
	let shippingaddress = this.state.shippingaddress;
    
	
	let fdate = this.state.fdate1;
	let adate = this.state.adate1;
	let payment = this.state.payment;
	
	let place = this.state.place;
	
	let accountnum = this.state.accountnum;
	let ifsccode = this.state.ifsccode;
    let bankname = this.state.bankname;
	let branchname = this.state.branchname;
	
   
	 const remail= /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
	
	



if(contacttype==="")
{
	
	this.setState({errormessage:"Please Select Contact Type.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}	

if(contactname==="")
{
	this.setState({errormessage:"Please Enter Contact Name.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}	

/*
if (! remail.test(email)) {
	  this.setState({errormessage:"Please Enter valid Email.",open:true});
	  window.scrollTo({
          top: 0
      })
	return false;
  }
*/

/*if(mobileno==="")
{
	this.setState({errormessage:"Please Enter Mobile No.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}*/

/*
if(phonenum==="")
{
	this.setState({errormessage:"Please Enter Phone Number.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}*/
/*
if(panno==="")
{
	this.setState({errormessage:"Please Select panno.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(gstinno==="")
{
	this.setState({errormessage:"Please Enter gstinno.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(billingassress==="")
{
	this.setState({errormessage:"Please Enter Billing Address.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}


if(shippingaddress==="")
{
	this.setState({errormessage:"Please Enter Shipping Address.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}*/

if(payment==="")
{
	this.setState({errormessage:"Please Select Payment.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(place==="")
{
	this.setState({errormessage:"Please Select Place.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

this.setState({btndisabled:true,submitbtn:"Processing..."})
	  var formData = new FormData();
	 if(this.state.cu_id===0)
	  {
		  formData.append('cu_id',0) 
		  
	  }
	  else
	  {
		  
	   formData.append('cu_id',this.state.cu_id)
		
          
	  }
	  		 
	 //alert(this.state.cu_id)
	
//alert(name);
formData.append('action','insertorupdate') 


formData.append('cu_contacttype',contacttype)
formData.append('cu_contactname',contactname)
formData.append('cu_email',email)
formData.append('cu_mobileno',mobileno)
formData.append('cu_phonenum',phonenum)
formData.append('cu_panno',panno)


formData.append('cu_gstinno',gstinno)
formData.append('cu_billingassress',billingassress)
formData.append('cu_shippingaddress',shippingaddress)


formData.append('cu_dob',fdate)
formData.append('cu_weddinganniversary',adate)
formData.append('cu_payment',payment)
formData.append('cu_pl_id',place)

formData.append('cb_accountnum',accountnum)
formData.append('cb_ifsccode',ifsccode)
formData.append('cb_bankname',bankname)
formData.append('cb_branchname',branchname)




	   
formData.append('userid',localStorage.getItem("Ruserid"))	
	 
	   	  axios.post(Serverurl+"ajaxcustomer.php", formData, {
        }).then(res => {
			
			
		  if(res.data==-1)
{ 
	 

		this.setState({errormessage:"Email Already Exist.",open:true,btndisabled:false,submitbtn:"Submit"
		});

	}else{
		this.setState({errormessage:"Record Udated Successfuly",open:true,
		btndisabled:false,submitbtn:"Submit",
		
			
			
			
			
			 redirect: true,
			
	
		contacttype:"",
		contactname:'',
		email:'',
		mobileno:'',
		phonenum:'',
		panno:'',
		
		gstinno:"",
		billingassress:'',
		shippingaddress:'',
		
		
		
		dob:'',
		weddinganniversary:'',
		payment:'',
		place:'',
		accountnum:'',
		ifsccode:'',
		bankname:'',
		branchname:'',
		
		cu_id:0
		});
			
	}		
            });
			
};




 handleClickdiscard = (e) => {
 
  this.setState({ open:!this.state.open,  cu_id:0,contacttype:'',contactname:'',email:'',mobileno:'',phonenum:'',panno:'',gstinno:'',billingassress:'',shippingaddress:'',accountnum:'',ifsccode:'',bankname:'',branchname:'',dob:'',weddinganniversary:'',payment:'',
	place:'',placearray:[],paymentarray:[],payment:'',dob:'',
		weddinganniversary:'',
	 
	  
		 });
		


};

	

render() {
	if (this.state.redirect === true) {
      return <Redirect to='/admincustomer' />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
	
	
	return (
	
	
		<Page layout="public">

<Section slot="menu"> / <a href='#/configuration' tabindex="-1">Configuration</a> / Contacts
</Section>

<Section slot="btn"><a href='#/admincustomer' tabindex="-1">Back</a>
</Section>

<Section slot="content">
	
	
<div class="customerform">

<div class="main">


<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>

  <form className={useStyles.form}  onSubmit={this.handleSubmit}>

<div className="headername">
		 <p><b>  New Customer</b></p>
		 </div>

<div className="formbgclr">



      <ul>


        <li>  
		<p>Contact Type <span className="validation">*</span></p>
		<p> <FormControl  className={useStyles.formControl} >
   
        <Select
          native 	inputProps={{ tabIndex: "1" }} 
		  variant="outlined"
            margin="normal"
         value={this.state.contacttype}
          onChange={this.myRoleChangeHandler}
        name='contacttype'
         id='contacttype'>
		 autoFocus
          <option value={0}>Type</option>
		  <option value={1}>Customer</option>
		  <option value={2}>Vendor</option>
		 
		  
          
        </Select>
      </FormControl>
		  </p>
		   
		
		</li>
		  
		<li>  
		<p>Contact Name <span className="validation">*</span></p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
         	inputProps={{ tabIndex: "2",maxLength:150 }} 
            fullWidth
			value={this.state.contactname}
            id="contactname"
          
            name="contactname" onChange={this.myChangeHandler}
            autoComplete="contactname"
            
			
          /></p></li>
		  
	 
		  
		<li>  
		<p>Email </p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           	inputProps={{ tabIndex: "3",maxLength:150 }} 
            fullWidth
			value={this.state.email}
            id="email"
          
            name="email" onChange={this.myChangeHandler}
            autoComplete="email"
			
            
			
          /></p></li>
		  
		 
		 
		 <li>  
		 <p>Mobile.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           	inputProps={{ tabIndex: "4",maxLength:12 }} 
            fullWidth
			value={this.state.mobileno}
            id="mobileno"
           
            name="mobileno" onChange={this.myChangeHandler}
            autoComplete="mobileno"
			inputProps={{maxLength:10,  }} 
            
			
          /></p></li>
		  
		 
		 <li> 
<p>Phone.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
              	inputProps={{ tabIndex: "5",maxLength:12 }} 
            fullWidth
			value={this.state.phonenum}
            id="phonenum"
           
            name="phonenum" onChange={this.myChangeHandler}
            autoComplete="phonenum"
			inputProps={{maxLength:10,  }} 
            
			
          /></p></li>
		 
		 
		 
		 <li>  
		 <p>Pan.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
              	inputProps={{ tabIndex: "4",maxLength:150 }} 
            fullWidth
			value={this.state.panno}
            id="panno"
           
            name="panno" onChange={this.myChangeHandler}
            autoComplete="panno"
			inputProps={{maxLength:10,  }} 
            
			
          /></p></li>
		 
		
		 
		 <li> 
<p>GSTIN.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "5",maxLength:150 }} 
            fullWidth
			value={this.state.gstinno}
            id="gstinno"
          
            name="gstinno" onChange={this.myChangeHandler}
            autoComplete="gstinno"
			
            
			
          /></p></li>
		  
		  <div className="customeraddress">
		  <ul>
		 <li>
<p>Billing Address</p>
		 <p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.billingassress}
            id="billingassress"
			     	inputProps={{ tabIndex: "6"}}
            fullWidth
           
            name="billingassress" onChange={this.myChangeHandler}
            autoComplete="billingassress"
            autoFocus
          />  </p></li>
		  
		  
		  
		<li> 
<p>Shipping Address</p>
		<p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.shippingaddress}
            id="shippingaddress"
				inputProps={{ tabIndex: "7"}}
            fullWidth
           
            name="shippingaddress" onChange={this.myChangeHandler}
            autoComplete="shippingaddress"
            autoFocus
          />  </p></li>
		  </ul>
		 </div>
		  
		 
		  
		  
		   <li> 
<p>Date of Birth</p>
		   <p>
		<MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	 
      <DatePicker fullWidth
	  variant="outlined"
      margin="normal"
        value={this.state.fdate }
        onChange={this.handfrChange}
           format="dd/MM/yyyy"
         name="fdate"
       inputProps={{ tabIndex: "8"}}
		id="dateborder"
		 className="dateborder"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		</p></li>
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		  
		   <li>  
		   <p>Wedding Anniversary</p>
		   <p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth
	  variant="outlined"
      margin="normal"
        value={this.state.adate}
        onChange={this.handanChange}
            format="dd/MM/yyyy"
        autoOk name="adate"
        ampm={false}  inputProps={{ tabIndex: "9"}}
		id="dateborder"
		 className="dateborder"
		 
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		</p>
		</li>
		  
		  <li> 
<p>Payment Terms <span className="validation">*</span></p>
		  <p><FormControl  className={useStyles.formControl} >
         
        <Select
         native inputProps={{ tabIndex: "10"}}
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.payment }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.payment}
		  name="payment"
		
		
        >
		{this.state.paymentarray.map(payment => (
            <option  
            
              value={payment.value}
            >
              {payment.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p></li>
		  
		 
		 
		 
		 <li> 
		 <p>State/Country <span className="validation">*</span></p>
		<p><FormControl  className={useStyles.formControl} >
         
        <Select
         native
		 variant="outlined"
            margin="normal" inputProps={{ tabIndex: "11"}}
		  defaultValue={{ label: this.state.rolelabel, value: this.state.place }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.place}
		  name="place"
		
		
        >
		{this.state.placearray.map(place => (
            <option  
            
              value={place.value}
            >
              {place.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
		
		
		
		
		
		</li>
		
<h3> Customer Bank Account Details : </h3>	
	
		<li> 
<p>Account.No</p>
		 <p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.accountnum}
            id="accountnum"
           inputProps={{ tabIndex: "12" ,maxLength:150}}
            name="accountnum" onChange={this.myChangeHandler}
            autoComplete="accountnum"
			
            
			
          /></p></li>
		  
		  
		   <li>  
		   <p>IFSC Code</p>
		   <p>
		  <TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "13" ,maxLength:150}}
            fullWidth
			value={this.state.ifsccode}
            id="ifsccode"
           
            name="ifsccode" onChange={this.myChangeHandler}
            autoComplete="ifsccode"
			
            
			
          /></p></li>
		  
		  
		   <li> 
<p>Bank Name</p>
		   <p>
		  <TextField
            variant="outlined"
            margin="normal"
             inputProps={{ tabIndex: "14" ,maxLength:150}}
            fullWidth
			value={this.state.bankname}
            id="bankname"
          
            name="bankname" onChange={this.myChangeHandler}
            autoComplete="bankname"
			
            
			
          /></p></li>
		  
		  
		  
		   <li> 
<p>Branch Name</p>
		   <p>
		  <TextField
            variant="outlined"
            margin="normal"
                 inputProps={{ tabIndex: "15" ,maxLength:150}}
            fullWidth
			value={this.state.branchname}
            id="branchname"
          
            name="branchname" onChange={this.myChangeHandler}
            autoComplete="branchname"
			
            
			
          /></p></li>
		
		
		
		   </ul>
		   
		   
		  	   
		  
		
		  
	
		  
		  
		<div className="formbtns">
		  <ul>
		
		  
		  <li> <p> <Button
            type="submit"
            halfWidth
            variant="contained" 
            color="primary"
            id="discard"                 inputProps={{ tabIndex: "17" }}
            className={useStyles.submit}
			onClick={this.handleClickdiscard}
          >
           Discard
          </Button></p></li><li> <p> <Button
            type="submit"
            halfWidth
            variant="contained" 
			disabled={this.state.btndisabled}
            color="primary"  inputProps={{ tabIndex: "16" }}
             id="searchform" 
            className={useStyles.submit}
          >
            {this.state.submitbtn}
          </Button></p></li>
		  </ul>
		  </div>
		  
		 
		  </div> 
		   
		 </form> 

  
</div>
          
</div>


		  
		   
	 </Section></Page>	  	
		  
		 
		  




  );
  }
}










