import React ,{Component} from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch,HashRouter ,Redirect } from 'react-router-dom';

import logo from './logo.svg';


import './App.css';

import test from './components/test';
import Addinvoice from './components/Addinvoice';



import Admincategories from './components/admincategories';
import Productbarcode from './components/productbarcode';
import Userbarcode from './components/userbarcode';

import Adminstate from './components/adminstate';

import Adminuser from './components/adminuser';

import Adduser from './components/adduser';

import Taxes from './components/Taxes';

import Master from './components/master';

import Adminoption from './components/adminoption';

import Organization from './components/organization';

import Admincustomer from './components/admincustomer';

import Addcustomer from './components/addcustomer';

import Adminproduct from './components/adminproduct';

import Addproduct from './components/addproduct';

import Dashboard from './components/dashboard';

import Customerreport from './components/customerreport';

import Vendorreport from './components/vendorreport';

import Customerviewmore from './components/customerviewmore';

import Paymentterms from './components/Paymentterms';

import Configuration from './components/configuration';

import Displaymenu from './components/Displaymenu';

import customer from './components/customer';

import Login from './components/Login';

import Changepassword from './components/changepassword';

import Invoicereport from './components/Invoicereport';

import Invoiceviewmore from './components/Invoiceviewmore';

import Logout from './components/Logout';

import Orderdetails from './components/Orderdetails';


import Addquotation from './components/Addquotation';
import Quotationdetails from './components/Quotationdetails';
import Quotationreport from './components/Quotationreport';
import Quotationviewmore from './components/Quotationviewmore';
import Invoicefiles from './components/Invoicefiles';
import Addpurchasebillorder from './components/Addpurchasebillorder';

import Productdetails from './components/Productdetails';
import Purchasedetails from './components/Purchasedetails';

import Purchasereport from './components/Purchasereport';

import Purchaseviewmore from './components/Purchaseviewmore';

import Paymentmasterreport from './components/Paymentmasterreport';


import Paymentmasterviewmore from './components/Paymentmasterviewmore';


import Addpaymentmaster from './components/Addpaymentmaster';
import PaymentDetails from './components/PaymentDetails';

import Purchasepaymentreport from './components/Purchasepaymentreport';

import Customeroutstandingreport from './components/Customeroutstandingreport';

import Vendoroutstandingreport from './components/Vendoroutstandingreport';


import Addreceiptmaster from './components/Addreceiptmaster';

import ReceiptDetails from './components/ReceiptDetails';
import Receiptmasterreport from './components/Receiptmasterreport';
import Receiptmasterviewmore from './components/Receiptmasterviewmore';



import Stockreport from './components/Stockreport';

import Categoryreport from './components/Categoryreport';

import Billingreport from './components/Billingreport';

import Quotationbillreport from './components/Quotationbillreport';

import Invoicebillreport from './components/Invoicebillreport';

import Reports from './components/Reports';

import Customerbillreport from './components/Customerbillreport';

import Vendorbillreport from './components/Vendorbillreport';

import Invoicegstreport from './components/Invoicegstreport';


import Purchasegstreport from './components/Purchasegstreport';


import Stockinventoryreport from './components/Stockinventoryreport';
import Reprint from './components/Reprint';
import Datewisereprint from './components/Datewisereprint';
import Billgstreport from './components/Billgstreport';

import masterlayout from './components/masterlayout';
import { LayoutProvider } from 'react-page-layout';
import PublicLayout from './components/publicLayout'
const layouts = {
	'public': PublicLayout,
};
class App extends Component {
render() {
    return ( 
	
	<HashRouter basename="/">
     
          
            <Switch>
	  
	
	
	
  
			<LayoutProvider layouts={layouts} path="/admincategories" >
				<Admincategories  title="Addstudentdetails"/>				
			</LayoutProvider>
			
			
			<LayoutProvider layouts={layouts} path="/master" >
				<Master  title="Master"/>				
			</LayoutProvider>
			
			<LayoutProvider layouts={layouts} path="/adminstate" >
				<Adminstate  title="Adminstate"/>				
			</LayoutProvider>
			
			
			<LayoutProvider layouts={layouts} path="/Paymentterms" >
				<Paymentterms  title="Paymentterms"/>				
			</LayoutProvider>
			
			
			<LayoutProvider layouts={layouts} path="/Taxes" >
				<Taxes  title="Taxes"/>				
			</LayoutProvider>
			
			<LayoutProvider layouts={layouts} path="/adduser" >
				<Adduser  title="Adduser"/>				
			</LayoutProvider>
			
			
			<LayoutProvider layouts={layouts} path="/adminuser" >
				<Adminuser  title="Adminuser"/>				
			</LayoutProvider>
			
			
			<LayoutProvider layouts={layouts} path="/admincustomer" >
				<Admincustomer  title="Admincustomer"/>				
			</LayoutProvider>
			
			<LayoutProvider layouts={layouts} path="/addcustomer" >
				<Addcustomer  title="Addcustomer"/>				
			</LayoutProvider>
			
			<LayoutProvider layouts={layouts} path="/organization" >
				<Organization  title="Organization"/>				
			</LayoutProvider>
			
			
			<LayoutProvider layouts={layouts} path="/dashboard" >
				<Dashboard  title="Dashboard"/>				
			</LayoutProvider>
			
			
		<LayoutProvider layouts={layouts} path="/customerreport" >
				<Customerreport  title="Customerreport"/>				
			</LayoutProvider>
			
			
			<LayoutProvider layouts={layouts} path="/vendorreport" >
				<Vendorreport  title="Vendorreport"/>				
			</LayoutProvider>
		
		
		<LayoutProvider layouts={layouts} path="/Customerviewmore" >
				<Customerviewmore  title="Customerviewmore"/>				
			</LayoutProvider>
		
		<LayoutProvider layouts={layouts} path="/adminoption" >
				<Adminoption  title="Adminoption"/>				
			</LayoutProvider>
			
				<LayoutProvider layouts={layouts} path="/configuration" >
				<Configuration  title="Configuration"/>				
			</LayoutProvider>
			
			
				<LayoutProvider layouts={layouts} path="/addproduct" >
				<Addproduct  title=" product"/>				
			</LayoutProvider>
			<LayoutProvider layouts={layouts} path="/productbarcode" >
				<Productbarcode  title="product barcode"/>				
			</LayoutProvider>
			
			<LayoutProvider layouts={layouts} path="/adminproduct" >
				<Adminproduct  title="Adminproduct"/>				
			</LayoutProvider>
			
			<LayoutProvider layouts={layouts} path="/userbarcode" >
				<Userbarcode  title="User barcode"/>				
			</LayoutProvider>
		
	<Route path="/test" component={test}/>

	
	 <LayoutProvider layouts={layouts} path="/Displaymenu" >
				<Displaymenu />
	
</LayoutProvider>
	
	 <LayoutProvider layouts={layouts} path="/changepassword" >
				<Changepassword />
</LayoutProvider>
	

 <LayoutProvider layouts={layouts} path="/Invoicereport" >
				<Invoicereport />
</LayoutProvider>


 <LayoutProvider layouts={layouts} path="/Invoiceviewmore" >
				<Invoiceviewmore />
</LayoutProvider>

	 <LayoutProvider layouts={layouts} path="/Orderdetails" >
				<Orderdetails />
</LayoutProvider>

<LayoutProvider layouts={layouts} path="/Productdetails" >
				<Productdetails />
</LayoutProvider>


 <LayoutProvider layouts={layouts} path="/Addinvoice" >
				<Addinvoice />
</LayoutProvider>

 <LayoutProvider layouts={layouts} path="/Addquotation" >
				<Addquotation />
</LayoutProvider>	


<LayoutProvider layouts={layouts} path="/Quotationdetails" >
				<Quotationdetails />
</LayoutProvider>



<LayoutProvider layouts={layouts} path="/Quotationreport" >
				<Quotationreport />
</LayoutProvider>



<LayoutProvider layouts={layouts} path="/Quotationviewmore" >
				<Quotationviewmore />
</LayoutProvider>




<LayoutProvider layouts={layouts} path="/Invoicefiles" >
				<Invoicefiles />
</LayoutProvider>


<LayoutProvider layouts={layouts} path="/Purchasedetails" >
				<Purchasedetails />
</LayoutProvider>




<LayoutProvider layouts={layouts} path="/Addpurchasebillorder" >
<Addpurchasebillorder />
</LayoutProvider>


<LayoutProvider layouts={layouts} path="/Purchasereport" >
<Purchasereport />
</LayoutProvider>



<LayoutProvider layouts={layouts} path="/Purchaseviewmore" >
<Purchaseviewmore />
</LayoutProvider>


<LayoutProvider layouts={layouts} path="/Paymentmasterreport" >
<Paymentmasterreport />
</LayoutProvider>


<LayoutProvider layouts={layouts} path="/Paymentmasterviewmore" >
<Paymentmasterviewmore />
</LayoutProvider>

<LayoutProvider layouts={layouts} path="/Addpaymentmaster" >
<Addpaymentmaster />
</LayoutProvider>




<LayoutProvider layouts={layouts} path="/PaymentDetails" >
<PaymentDetails />
</LayoutProvider>


<LayoutProvider layouts={layouts} path="/ReceiptDetails" >
<ReceiptDetails />
</LayoutProvider>

<LayoutProvider layouts={layouts} path="/Addreceiptmaster" >
<Addreceiptmaster />
</LayoutProvider>




<LayoutProvider layouts={layouts} path="/Receiptmasterreport" >
<Receiptmasterreport />
</LayoutProvider>

<LayoutProvider layouts={layouts} path="/Receiptmasterviewmore" >
<Receiptmasterviewmore />
</LayoutProvider>








<LayoutProvider layouts={layouts} path="/Stockreport" >
<Stockreport />
</LayoutProvider>


<LayoutProvider layouts={layouts} path="/Categoryreport" >
<Categoryreport />
</LayoutProvider>

<LayoutProvider layouts={layouts} path="/Billingreport" >
<Billingreport />
</LayoutProvider>

<LayoutProvider layouts={layouts} path="/Quotationbillreport" >
<Quotationbillreport />
</LayoutProvider>

<LayoutProvider layouts={layouts} path="/Invoicebillreport" >
<Invoicebillreport />
</LayoutProvider>


<LayoutProvider layouts={layouts} path="/Reports" >
<Reports />
</LayoutProvider>

<LayoutProvider layouts={layouts} path="/Customerbillreport" >
<Customerbillreport />
</LayoutProvider>

<LayoutProvider layouts={layouts} path="/Vendorbillreport" >
<Vendorbillreport />
</LayoutProvider>

<LayoutProvider layouts={layouts} path="/Invoicegstreport" >
<Invoicegstreport />
</LayoutProvider>


<LayoutProvider layouts={layouts} path="/Purchasepaymentreport" >
<Purchasepaymentreport />
</LayoutProvider>

<LayoutProvider layouts={layouts} path="/Customeroutstandingreport" >
<Customeroutstandingreport />
</LayoutProvider>

<LayoutProvider layouts={layouts} path="/Vendoroutstandingreport" >
<Vendoroutstandingreport />
</LayoutProvider>



<LayoutProvider layouts={layouts} path="/Purchasegstreport" >
<Purchasegstreport />
</LayoutProvider>

<LayoutProvider layouts={layouts} path="/Stockinventoryreport" >
<Stockinventoryreport />
</LayoutProvider>




<LayoutProvider layouts={layouts} path="/Reprint" >
<Reprint />
</LayoutProvider>




<LayoutProvider layouts={layouts} path="/Datewisereprint" >
<Datewisereprint />
</LayoutProvider>




<LayoutProvider layouts={layouts} path="/Billgstreport" >
<Billgstreport />
</LayoutProvider>


<Route path="/Login" component={Login}/>


<Route path="/Logout" component={Logout}/>


	
<Route component={Login}/>
	
	 </Switch>
      
      </HashRouter>
	);
	
}
	
	
	
	
}


export default App;
