import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  
import { Page, Section } from 'react-page-layout';

import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';

import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';

import Tooltip from '@material-ui/core/Tooltip';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Autocomplete from '@material-ui/lab/Autocomplete';

import login_pagelogo from './images/invfilelogo.png';

import loginbgImage from "./images/blue-bg.png";
import submitarrow from "./images/submit-arrow.png";

import Uploadsurl from './Apiurl';
import VisibilityIcon from '@material-ui/icons/Visibility';



function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [




{ id: 'pr_code', numeric: false,  label: <b>Product Code</b> },
{ id: 'op_title', numeric: false,  label: <b>Product Name</b> },
{ id: 'op_srateinc', numeric: false,  label: <b>S.Rate(Inc)</b> },
{ id: 'op_srateexc', numeric: false,  label: <b>S.Rate(Exc)</b> },

{ id: 'op_discount', numeric: false,  label: <b>Discount</b> },
{ id: 'op_tax_value', numeric: false,  label: <b>Tax</b> },
{ id: 'op_quantity', numeric: false,  label: <b>Qty</b> },
{ id: 'op_grossamount', numeric: false,  label: <b>Amount</b> },

]




function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}



EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};



const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class  Invoicefiles extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',
	 
	 
	invoice:'',phone:'',orderid:'',imgPhoto:'',isdisplay:"0",
	isformdisplay:"0", additionalservices:'', shippingaddress:'',
	opviewmore:[],invoiceviewmore:[],orgviewmore:[],primg:'',
	 
	  
 
        
      }
	  
	   
	  
	 };
	 
	 
	 
	 
	componentDidMount() {
	
	document.title = "";
		
		if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		
		
 
 

} 


handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
  
  
  

myChangeHandler = (event) => {
	
	const re = /^[0-9\b]+$/; 
	const renew = /^[0-9\b.]+$/; 
	
	
  let nam = event.target.name;
  let val = event.target.value;
  
   if( nam==="phone" )
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	  
  }
}
  else{
  
  
  this.setState({[nam]: val});
  }
 } 
 handleSearch= (event) => {
	event.preventDefault();
	
  let invoice = this.state.invoice;
	let phone = this.state.phone; 
	
	
	
		let orderid = this.state.orderid;
	
	if(invoice==="")
{
	
	this.setState({errormessage:"Please Enter Invoice No.",open:true});
	
	window.scrollTo({
          top: 0
      })
	  return false;
}
	
		if(phone==="")
{
	
	this.setState({errormessage:"Please Enter Phone No.",open:true});

	window.scrollTo({
          top: 0
      })
	  	return false;
}
	
	 axios.get(Serverurl+'ajaxinvoicefiles.php?action=invoiceorderid&or_invoice='+this.state.invoice+'&or_phone='+this.state.phone)
  
	
  .then(resp => {  
		
    if(resp.data.length>0){
	
			this.setState({  
			    
			   orderid:resp.data[0].or_id,
			  
			   invoice:'',
			   phone:'',
			   
			   
			});
			
			
	if(resp.data[0].or_id != "")
	{
		
		this.orgviewmore()
		
		this.setState({  
			    
			   isdisplay:"1", isformdisplay:"1"
			});
		
	}
	
	
		
	  
	 
     }
	 else{
		alert("Invoice No. / Phone No. is not valid"); 
		this.setState({  
			    
			   isdisplay:"0",isformdisplay:"0"
			}); 
		 
	 }
	 
 
    });
	
	
	 
 }
 
 
orgviewmore(){
	
	axios.get(Serverurl+'ajaxorganization.php?action=orgviewmore&or_id=1')
	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  orgviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
	
 this.viewmoreorder(this.state.orderid)
    });
}
 

viewmoreorder(orid){


	 axios.get(Serverurl+'ajaxorders.php?action=invoicereport&or_id='+orid)
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  invoiceviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			    or_taxamount:resp.data[0].or_taxamount,
			   discount:resp.data[0].or_discount,
			   roundoff:Number(resp.data[0].or_roundoff).toFixed(2),
			});
			
	   this.opviewmore(resp.data[0].or_sessionid); 
	 
     }
  
    });
}

 
opviewmore(sessionid){

	// axios.get(Serverurl+'ajaxorderproduct.php?action=opviewmore&op_id='+opid)
	 
	 axios.get(Serverurl+'ajaxorderproduct.php?action=opviewmore&op_sessionid='+sessionid)
  
	 
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  opviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
	// alert(this.state.orderid)
  this.productphoto(this.state.orderid);
    });
}

 
 

productphoto(orid){

	
	 axios.get(Serverurl+'ajaxinvoicefiles.php?action=invphoto&nv_or_id='+orid)
  
	 
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			 // prdphoto: resp.data, 
              primg:resp.data[0].nv_file,		  
			  
			});
			
	  
	 
     }

    });
	//alert(this.state.primg)
	
}


handleSubmit= (event) => {
    
    event.preventDefault();
	
  
let orderid= this.state.orderid;
let shippingaddress = this.state.shippingaddress;
let additionalservices = this.state.additionalservices;

   var formData = new FormData();
   
     if(shippingaddress === "")
   {
	   
	this.setState({errormessage:"Please Enter Shipping Address",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
   }
   
   if(additionalservices === "")
   {
	   
	this.setState({errormessage:"Please Enter Additional Services",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
   }
   
   
   
	 if(this.state.imgPhoto!='')
	  {
		  
		for (const key of Object.keys(this.state.imgPhoto)) {
            formData.append('Filedata', this.state.imgPhoto[key])
        } 
		
        formData.append('nv_file','1')		  
	  } 
	  else
	  {
		 formData.append('nv_file',this.state.prevca_icon)	  
	  
	  }
	 
        formData.append('action','insert') 
	formData.append('nv_or_id',orderid)
	formData.append('or_shippingaddress',shippingaddress)
	formData.append('or_additionalservices',additionalservices)
		
		formData.append('userid',localStorage.getItem("Ruserid"))
		
		  
		axios.post(Serverurl+"ajaxinvoicefiles.php", formData, {
        }).then(res => {
    
          alert("File Uploaded Successfully..!");
		   		this.setState({  
			    
			   isdisplay:"0",isformdisplay:"0"
			});
    
        });
  
	
	
}



	 




onphotoFileChange=(e) =>{
	  
	 
	   let imageFile = e.target.files[0];
	   
	   if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
  alert( 'Please select valid file (jpg,jpeg,png).' );
    return false;
  }else{
        this.setState({ imgPhoto: e.target.files })
  }
	
	
}




render() {
	let subtaotal = 0;
let grandtotal = 0;
	  
	return (
	
	
	
   
 		<div className="invfilenmain">

	
<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
 
 
		
	
	

<div className="logodiv">

<p>	<img src={login_pagelogo} /></p>


</div>

<br/>



<div className="invform">









	<h1> INVOICE FILES </h1>
		
		{this.state.isformdisplay === "0" && ( <React.Fragment>	
		
		
			<div className="invinput">
	
	
<p>Invoice No. <span className="validcolor"> * </span></p>
	<p><TextField
           
            margin="normal"
            
            fullWidth
			value={this.state.invoice}
            id="invoice"
           variant="outlined"
            name="invoice" onChange={this.myChangeHandler}
            autoComplete="invoice"
			
            autoFocus
			
          />
		  </p>
		  
		 
		
<p>Phone No. <span className="validcolor"> * </span></p>
		 <p><TextField
            
            margin="normal"
            variant="outlined"
            fullWidth
			value={this.state.phone}
            id="phone"
           
            name="phone" onChange={this.myChangeHandler}
            autoComplete="phone"
			
            autoFocus
			
          />
		  </p>
		  
		  
		  
		  
		  <p></p>
		  <p className="submitlogin"><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
         // id="submitlogin" 
            className={useStyles.submit}
			onClick={this.handleSearch}
			
          >
            Submit 
          </Button></p>
		  
		  
		  
		</div>
		  
		 </React.Fragment>
)}	  
		  
 
 
		
	<br/>
<br/>



{this.state.isdisplay === "1" && ( <React.Fragment>	
	
	
	<p className="uptext">Please upload your file <span className="validcolor"> * </span></p>
	<p className="up-load">
	
	
	
	<p className="inptcol upload-btn-wrapper">

<p className="invup-load">
  
 
 <input type="file" className="upload-btnfile" name="imgPhoto"  onChange={this.onphotoFileChange} accept="image/"/></p>
</p>
 </p>



	<div className="invinput">

<div className="browseleft">
<p>Shipping Address <span className="validcolor"> * </span></p>
<p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.shippingaddress}
            id="shippingaddress"
				//placeholder="Shipping address"
            fullWidth
           
            name="shippingaddress" onChange={this.myChangeHandler}
            autoComplete="shippingaddress"
            autoFocus
          />  </p>

</div>
<div className="browseright">

<p>Additional Services <span className="validcolor"> * </span></p>
<p>
		   <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.additionalservices}
            id="additionalservices"
			//placeholder="Additional Services"
            fullWidth
           
            name="additionalservices" onChange={this.myChangeHandler}
            autoComplete="additionalservices"
            autoFocus
          />  </p>
		  
		  
  </div>
  
  <div className="clear"></div>
		  
</div>

 <p className="submitlogin"> <Button
            type="submit"
            fullWidth
            variant="contained" 
           color="primary"
         //  id="submitlogin" 
            className={useStyles.submit}
			onClick={this.handleSubmit}
			
          >
            Submit
          </Button></p>	
		  
		  
		  
		 


<div className="invoiceview">


<div className="organization">

{this.state.orgviewmore.map((item,index) => { 

				 
           return ( <React.Fragment>
	
<ul>
<p>From :</p>
<li><b>{item.or_name}</b> </li>
<li>{item.or_address}</li>
<li>{item.or_city}</li>

<li>Email : {item.or_email}</li>

<li>Phone : {item.or_mobilenum}</li>
<li>PAN : {item.or_pannum}</li>
<li>GSTIN : {item.or_gstinnum}</li>






</ul>


	 
 </React.Fragment>
 )
  })}
  
  </div>





	 







{this.state.invoiceviewmore.map((item,index) => { 

				 
           return ( <React.Fragment>
		   
		   <div className="customerdetails">
		   
		   
<ul>
<p>To :</p>
<li><b>{item.or_name} </b> </li>
 
  
<li>Email  :  {item.or_email} </li>

<li>Phone No  :  {item.or_phone}  </li>   

<li>Pan No :  {item.cu_panno} </li>

<li>GSTN No : {item.or_gstn} </li>
</ul>

		   </div> 
	<div className="orderdetails">
<ul>
<p>Details :</p>
<li> Invoice Type : <b>{item.ma_value}</b> </li>

<li> Invoice No : <b>{item.or_invoice}</b></li>

<li> Invoice Date : {item.or_createddate}</li>

<li> Ref No : </li>

<li> Ref Date : </li>

<li> Sales Person :<b> {item.us_name}</b></li>
  
  
  
{this.state.primg !==""    && (
<React.Fragment>
  
  <li > File : &nbsp;
  
  <a href={Uploadsurl+"uploads/"+this.state.primg} target="_blank">
  <VisibilityIcon  /></a>
  
  
  </li>
  </React.Fragment>
  )}

</ul>

			
	</div> 
 </React.Fragment>
 )
  })}
  

  
  
  
  
  		<div className="clear"></div>
  
  </div>
   
		  
	
  <div className="orderproduct">
  
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table">  
  
   <EnhancedTableHead
             
              
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              
            />
  
  
   <TableBody>
  
  {this.state.opviewmore.map((item,index) => { 

		subtaotal=Number(subtaotal)+Number(item.op_grossamount)
       
        grandtotal=Number(grandtotal)+Number(item.op_grossamount)	   
		
           return ( <React.Fragment>
	
<TableRow key={index} >



<TableCell>{item.pr_code}</TableCell>
<TableCell>{item.op_title}</TableCell>
<TableCell>{item.op_srateinc}</TableCell>
<TableCell>{item.op_srateexc}</TableCell>


<TableCell>{item.op_discount}</TableCell>
<TableCell>{item.op_tax_value}%</TableCell>

<TableCell>{item.op_quantity}</TableCell>
<TableCell align="right">Rs.{item.op_grossamount}</TableCell>

	
</TableRow>	
	
	


 </React.Fragment>
 )
  })}
<TableRow  >
	

<TableCell colspan="7" align="right">Sub Total</TableCell>
<TableCell align="right">Rs.{subtaotal}</TableCell>

	</TableRow>	
		<TableRow  >
	

<TableCell colspan="7" align="right">Discount</TableCell>
<TableCell align="right">Rs.{Number(this.state.discount).toFixed(2)}</TableCell>

	</TableRow>	
	<TableRow  >
	

<TableCell colspan="7" align="right">GST</TableCell>
<TableCell align="right">Rs.{Number(this.state.or_taxamount).toFixed(2)}</TableCell>

	</TableRow>	
	<TableRow  >
	

<TableCell colspan="7" align="right">Round off</TableCell>
<TableCell align="right">Rs.{this.state.roundoff}</TableCell>

	</TableRow>
		<TableRow  >
	

<TableCell colspan="7" align="right">Grand Total</TableCell>
<TableCell align="right">Rs.{Number(Number(subtaotal).toFixed(2))-Number(Number(this.state.discount).toFixed(2))+Number(Number(this.state.or_taxamount).toFixed(2))-Number(Number(this.state.roundoff).toFixed(2))}</TableCell>

	</TableRow>   
   </TableBody>
  
</Table>
  </TableContainer>   
  
  

  
  </div>  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  

	
	 </React.Fragment>
)}
	
	
   
 


      
 </div>
 

 
 </div>
  

);
 
  }	
   
   

}
 
		 