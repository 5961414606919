import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  

import { Page, Section } from 'react-page-layout';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';


import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import DateFnsUtils from '@date-io/date-fns';
import TableBody from '@material-ui/core/TableBody'; 
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import TableFooter from '@material-ui/core/TableFooter';
import Checkbox from '@material-ui/core/Checkbox';


let strCust=[{title:'',year:0}];



const strProduct=[];
const strProductdeflt='';


const useStyles = makeStyles(theme =>({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
 
form: {
    width: '500%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
})
)



export default class Addpaymentmaster extends Component {
	
	
	constructor(props) {
    super();
	var today = new Date();
	var todayMonth=(today.getMonth() + 1);
	if(todayMonth<10)
		todayMonth='0'+todayMonth;
    var        cudate = today.getFullYear() + '-' + todayMonth + '-' + today.getDate();
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],invoicearray:[],invoice:'18',invoicelabel:'Select Invoice Type',invoiceno:'',payment:'1',paymentarray:[],fdate:Date.now(),fdate1:cudate,gstin:'',salespersonarray:[], salesperson:'0',adminData:[],custtags:'',
	 custarray:[],stat:'0',statarray:[],dest:'0',scancode:'',pvdata:[],taxdata:[],taxdatacount:0,
	 barcodeval:'',salesrateexcl:'',salesrateincl:'',orddiscount:'',qty:'',newpcid:"-1",ordgst:0,
amount:'',produserarray:[],taxarray:[],tax:'',barcodedata:[],prdextndata:[],
customer:[],redirect:false,roundoff:0,billingassress:'',shippingaddress:'',
	
	 pr_salesrateexcl:'',pr_salesrateincl:'',pr_tx_id:'',orddiscounttype:1,
subtotal:0,roundoffval:0,discountval:'',ordtotaldiscount:0,salesdata:[],
salespersonname:'',dcus:'',errormessage:'',open:false,selectedcustomerid:'',paymentname:'',orderproduct:0,billno:'',refno:'',
rdate:Date.now(),rdate1:cudate,ddate:Date.now(),ddate1:cudate,account:'',
billamount:'',paymentduedata:[],chkbx:'0',isSelected:'0',poid:0,selected:[],
pobillno:'',gtotal:0,btndisabled:false,
submitbtn:"Submit",  
	   

	        
      }
	  
	  this.onTagsChange = this.onTagsChange.bind(this);
	  //  this.insertDonenewParent = this.insertDonenewParent;
	  // this.onTagsproductChange = this.onTagsproductChange.bind(this);
	  //this.escFunction = this.escFunction;
	   //this.escSalesFunction = this.escSalesFunction;
	   
	    //this.ChildElement = React.createRef();
	 };
	 
	 
 insertDonenewParent =(text)=>{
	 
	 
	//alert(localStorage.getItem("newcuid")) 
  // this.onloaduserbyid(localStorage.getItem("newcuid"));
	 
 };
 
 
 componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
 // this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}


componentDidMount() {
	
if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }


 localStorage.setItem("page",this.state.rowsPerPage);
 // this.apicall(this.state.page,this.state.rowsPerPage); 
// this.Usergetdata();
this.onloadCustomerBind();

 //document.addEventListener("keydown", this.escFunction, false);
//  document.addEventListener("keydown", this.escSalesFunction, false);
	}
	
	
	
	componentWillUnmount(){
  //  document.removeEventListener("keydown", this.escFunction, false);
	//document.removeEventListener("keydown", this.escSalesFunction, false);
  }
  
  myChangeHandler = (event) => {
	
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
if(nam==="payment")
  {
	 
	  let idx = event.target.selectedIndex;
	 let paymentname=event.target.options[idx].dataset.pname;
	//  alert(paymentname)
	  
	  this.setState({["paymentname"]: event.target.options[idx].dataset.pname});

//alert(this.state.paymentname)
  }

}


vouchernocall(){
		
axios.get(Serverurl+'ajaxpaymentmaster.php?action=generatevoucherno')
 
  
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		
			this.setState({  
			 // adminData: resp.data,
              billno: resp.data[0].billno			  
              //invoiceno: "IN/"+resp.data[0].ao_type+"/"+nxt,
              			  
			   
			});
			
		//	this.onloaduserbyid(resp.data[0].ao_type);
	
	 
	  
	 
     }
  
    });
	
		
	}
	

onloaduserbyid(cuid){
		
		 axios.get(Serverurl+'ajaxcustomer.php?action=list&cu_id='+cuid)
  .then(resp => {  
   if(resp.data.length>0){
	   const items = {
        title:resp.data[0].cu_contactname,
          year:resp.data[0].cu_id,
          email:resp.data[0].cu_email,
		   mobileno:resp.data[0].cu_mobileno,
		       rowindex:1,
			   billingassress:resp.data[0].cu_billingassress,
			   shippingaddress:resp.data[0].cu_shippingaddress,
			   gstin:resp.data[0].cu_gstinno,
			   payment:resp.data[0].cu_payment,
			   dest:resp.data[0].cu_pl_id,
			
        };
 
       
    this.setState({ 
	
	selectedcustomerid:resp.data[0].cu_id,
   	 	custtags:resp.data[0].cu_contactname,
	   customer: items,
	billingassress:resp.data[0].cu_billingassress,
	shippingaddress:resp.data[0].cu_shippingaddress,
	gstin:resp.data[0].cu_gstinno,
	payment:resp.data[0].cu_payment,
	dest:resp.data[0].cu_pl_id,
	
	
	
	
  
	});
	
   }
   //this.invoicebind();

//this.paymentbind();   
  });

} 



onloadCustomerBind(){
	 axios.get(Serverurl+'ajaxcustomer.php?action=vendorautoextender')
  .then(resp => { 
  this.setState({ custarray:resp.data});

	 var rowsArray = this.state.custarray;
	 rowsArray.map((row, i) => {
			 
		 const items = {
        title:row.title,
          year:row.year,
          email:row.cu_email,
		   mobileno:row.cu_mobileno,
		       rowindex:row.rowindex,
			   billingassress:row.cu_billingassress,
			   shippingaddress:row.cu_shippingaddress,
			   gstin:row.cu_gstinno,
			   payment:row.cu_payment,
			
        };
         strCust.push( items)
		  
		 });
  });
  
  this.paymentbind();
  
	
}


	
paymentbind(){
	
	
	 axios.get(Serverurl+"ajaxpaymentterms.php?action=paymentbind&type=1")
    
      .then((response) => {    
     
      this.setState({  paymentarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.paymentarray.map(payment => {
                            
          return {value: payment.pt_id, display:payment.pt_title}
        });
      
		
        this.setState({
          paymentarray: [{value: '0', display: 'Select payment Type'}].concat(roleFromApi)
        });
      })
	
	this.vouchernocall();
	
}


onInputChange = (event, newTextValue) => {

if(newTextValue!==" "){


  this.setState({custtags:newTextValue})
}

  
 }

 onTagsChange = (event, values) => {
	 
	  
	
	console.log(values)
	  if(values!==" " && values!==null){
	 
	 
    this.setState({
    
	   customer: values,
	   selectedcustomerid:values["year"],
	    billingassress: values["billingassress"],
		 shippingaddress: values["shippingaddress"],
		 gstinno: values["gstinno"],
		 payment: values["payment"],
		 dest:values["destid"],
		
		 
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
     
    });
	
	this.paymentduecall(values["year"]);
	  }
  }
  
  
  
  paymentduecall(cuid)
  {
	   axios.get(Serverurl+'ajaxpaymentmaster.php?action=paymentduedisplay&po_cu_id='+cuid+'&StartIndex='+this.state.page+'&PageSize='+this.state.rowsPerPage)
  .then(resp => {  
   if(resp.data.length>0){
	   this.setState({  
			  paymentduedata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			});
	   
	

  }
  else{this.setState({  
	 paymentduedata:[],totaldata:0,
	
	 });}
  });
  
  }
  
  
  
  
 
handfrChange = (date) => {
	 var month="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date.getDate()


	 })

  }
  
  /*    
 handleClick = (event, po_id,pb_purchasebillno,grandtotal) => {
	 
	 //alert(this.state.isItemSelected)
	 let selected="";let setSelected=""; 

   this.setState({poid:po_id,pobillno:pb_purchasebillno,gtotal:grandtotal});

    
  };
  
 */ 
  
 handleChange = (id,rowss) => {
		
		  const updatednewArray = [...this.state.paymentduedata];
		  
		  if(updatednewArray[rowss].selected==="0")
		  {
					  updatednewArray[rowss].selected = "1" ;
					   
					  
		  }
else{

			  updatednewArray[rowss].selected = "0" ;
			
			  
}

 this.setState({
        paymentduedata: updatednewArray,
    }); 
	
  };

 
handleDiscard= (event) => {
	
	window.location.reload();
} 
  
  
  
  
handleSubmit= (event) => {
    
    event.preventDefault();
	//alert('')
   let chkbx = this.state.chkbx;
   let checked= this.state.selected;
   let billamount = this.state.billamount;
    let refno = this.state.refno;
	
	let fdate1 = this.state.fdate1;
	let payment = this.state.payment;
	let billno = this.state.billno;
	let rdate1 = this.state.rdate1;
	let selectedcustomerid = this.state.selectedcustomerid;

    let paymentname = this.state.paymentname;

	 // alert(this.state.checked)
	//  alert(this.state.selected)
	 
	 
	  if(payment === "0")
	 {
		
		 this.setState({errormessage:"Please Select Payment Terms",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 
	 
	 
	 
	 
	   if(selectedcustomerid === "0")
	 {
		 
		 this.setState({errormessage:"Please Select Customer",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	  
	 
	 
	   if(refno === "")
	 {
		
		 this.setState({errormessage:"Please Enter Reference No.",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 
	   if(billamount === "")
	 {
		
		 this.setState({errormessage:"Please Enter Amount",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
	 
	 
	
	 
	 
	 
	 	var payrowsArray = this.state.paymentduedata;
	
	let strdata = "",strsep="",strpaydata = "";
 var paynewRow = payrowsArray.map((rowss, i) => { 
 if(rowss.selected=="1")
 {
	 
strdata=Number(strdata)+Number(rowss.subtotal);

 }
 
 /*else{
	 
	
		 this.setState({errormessage:"Please Select Bill.",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false; 
 }*/
 }) 
 

 
    if(strdata.length === 0)
	 {
		
		 this.setState({errormessage:"Please Select Bills",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }
 
 if(billamount > strdata  ) 
 {
	 this.setState({errormessage:"Amount Entered must be lesser than or equal to Selected Invoice amount",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
 }
 else{
	 
	var payArray = this.state.paymentduedata;
	
	let strsep="";
	 var rolenewRow = payArray.map((row, i) => { 
 if(row.selected=="1")
 {
	 
	 
	//strdata=strdata+strcolan+firstrow.ic_title+"~"+firstrow.iv_amount;



strpaydata=strpaydata+strsep+row.po_id+"~"+row.po_billno;
strsep=";"; 
	 
/*	 
strpaydata=strpaydata+row.po_id+strsep+row.po_billno;
strsep="~";*/
 }
	 }) 
	 
	 
	 
	 
	 //alert(strpaydata); 
	 
	 
 }
 
 //alert(strdata);

	this.setState({btndisabled:true,submitbtn:"Processing..."}) 
var formData = new FormData();
	
		formData.append('action','paymentsinsert') 
	
	    formData.append('pm_referenceno',refno)
		
		formData.append('pm_paymentmode',payment)
		formData.append('pm_cu_id',selectedcustomerid)
		
	
		formData.append('pm_referencedate',rdate1)
		
		formData.append('paymentdetails',strpaydata)
		
		//formData.append('paymentname',paymentname)
		
		formData.append('amount',billamount)
		
		
	
		formData.append('userid',localStorage.getItem("Ruserid"))
		
		localStorage.removeItem("RASBILpurchase");
		axios.post(Serverurl+"ajaxpaymentmaster.php", formData, {
        }).then(res => {
			 if(res.data!="0"){
		///alert(res.data)
		var payid=res.data;
		
 // alert(payid)
  localStorage.setItem("RASBILpayment",payid);
//alert(localStorage.getItem("RASBILpayment"))
 this.setState({btndisabled:false,submitbtn:"Submit",redirect:true,pagename:'/PaymentDetails?pmid='+localStorage.getItem("RASBILpayment")})
			 }
 
   //localStorage.removeItem("newcuid");
	
		});
	
}
  
  
   handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
 
 isSelected = id => this.state.paymentduedata.indexOf(id) !== -1;
 
 render() {
	
if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
	
  
	
 
  	
	let dueamt=0;
	let grandtotal=0;
	let paidtotal=0;
	let duetotal=0;
    
  return (
   <Page layout="public">

<Section slot="menu"> / Purchase / Payments
</Section>

<Section slot="btn"><a href='#/Paymentmasterreport' tabindex="-1"> Payments</a>
</Section>

<Section slot="content">
    <React.Fragment>
	
	
<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
	
	
	
	
	
  <div className="mainorder"> <div className="headername">
		 <p><b> New Payment</b></p>
		 </div>
    <div className="ordtbl">

	  <div className="panelpad">
	
	
	
	
<div className="purchaseleft">	
		
		 <p></p>
		 
		<p>Vendor <span className="validation">*</span> 
					
    </p>
		 <p>
		 
		 <Autocomplete
      id="custautoextender" 
	 
	  defaultValue={this.state.customer}
		  inputValue={this.state.custtags}
		    onInputChange={this.onInputChange}
	  onChange={this.onTagsChange}  
     options={strCust}
      renderInput={(params) => (
        <TextField {...params}   variant="outlined" />
      )}
      getOptionLabel={(option) => option.title}
      renderOption={(option) => (
        <React.Fragment>
          <table width='100%' >
            {option.rowindex==="1" &&(
          <thead>
          <tr>
            <th width='10%'>Name</th>
            <th width='10%'>Email</th>
			  <th width='10%'>Mobile No.</th>
			 
				  </tr> 
        </thead>)}
            <tbody>
              <tr>
                <td width='10%'>{option.title}</td>
                <td width='10%'>{option.email}</td>
				
                <td width='10%'>{option.mobileno}</td>
				
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      )}
    />
		 
		 
	
	  </p>
		
		
		
		
		</div>
	
	
	 <div className="voucherright">
	 <div className="voucher">
	 <ul>
	 
	 <li>
	 <p>
	
         <p>Voucher No. <span className="validation">*</span></p>
			<TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "1",maxLength:150 }} 
            fullWidth
			value={this.state.billno}
            id="billno"
          
            name="billno" onChange={this.myChangeHandler}
            autoComplete="billno"
            
			
          /></p>
	 
	 </li>
	 
	 
	 <li>
	 <p>Voucher Date <span className="validation">*</span></p>
		<p className="datepicker"><MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	
      <DatePicker fullWidth
	  variant="outlined"
      margin="normal" inputProps={{ tabIndex: "2"}} 
        value={this.state.fdate || Date.now()}
        onChange={this.handfrChange}
            format="dd/MM/yyyy"
        autoOk name="fdate"
        ampm={false}
		id="dateborder"
		
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		
		</p>
	 </li>
	 
	 
	 </ul>
	 
	 </div>
	 
	 
	 </div>
	 
	 
	 <div className="clear"></div>
	
	
	<div className="voucherdiv">

     
<ul>
       
		
	
		
		
		
		<li className="dropdown">
		
				
<p>Payment Mode <span className="validation">*</span></p>
		<p><FormControl  className={useStyles.formControl} >
         
        <Select
         native inputProps={{ tabIndex: "4" }} 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.invoicelabel, value: this.state.payment }}
          onChange={this.myChangeHandler}
          id="payment"
		   value={this.state.payment}
		  name="payment"
		
		
        >
		{this.state.paymentarray.map(payment => (
            <option  
            data-pname={payment.display}
              value={payment.value}
            >
              {payment.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
		</li>
		
		
		
		
		<li><p>
	
         <p>Reference No. <span className="validation">*</span></p>
			<TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "5",maxLength:150 }} 
            fullWidth
			value={this.state.refno}
            id="refno"
          
            name="refno" onChange={this.myChangeHandler}
            autoComplete="refno"
            
			
          /></p>
		
		</li>
		
		
		<li>
		
				

		
		  <p>Reference Date <span className="validation">*</span></p>
		<p className="datepicker"><MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container justify="space-around"  >
	
      <DatePicker fullWidth
	  variant="outlined"
      margin="normal" inputProps={{ tabIndex: "6"}} 
        value={this.state.rdate || Date.now()}
        onChange={this.handrefChange}
            format="dd/MM/yyyy"
        autoOk name="rdate"
        ampm={false}
		id="dateborder"
		
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		
		</p></li>
		
		<li><p>
	
         <p>Amount <span className="validation">*</span></p>
			<TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "7",maxLength:150 }} 
            fullWidth
			value={this.state.billamount}
            id="billamount"
          
            name="billamount" onChange={this.myChangeHandler}
            autoComplete="billamount"
            
			
          /></p>
		
		</li>
		
		</ul>
		
	</div>	
		
	<div className="ordtbl">
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table"> 
 

  

					  
				<TableHead> 
<TableRow>
<TableCell width="10%"> </TableCell>
  <TableCell width="15%"><b>Bill No.</b></TableCell>     
  <TableCell width="10%"><b>Bill Date</b></TableCell>  
  <TableCell width="10%"><b>Grand Total</b></TableCell>  
  <TableCell width="10%"><b>Paid Amount</b></TableCell>  
  <TableCell width="10%"><b>Due Amount</b></TableCell>   
   

						
                      </TableRow></TableHead>
			
		   
               
                      <TableBody>

	 {(this.state.paymentduedata).map((item,index) => {


   
   

dueamt=(Number(item.subtotal)- Number(item.paidamt)).toFixed(2)

paidtotal=(Number(paidtotal)+Number(item.paidamt)).toFixed(2)
grandtotal=(Number(grandtotal)+Number(item.subtotal)).toFixed(2)
duetotal=(Number(duetotal)+Number(dueamt)).toFixed(2)
       
  
  const isItemSelected = this.isSelected(item.po_id);  
				
           return ( <React.Fragment>
		            
 <TableRow key={index} >
   
   <TableCell>  
 {item.selected==="0" && (
<Checkbox
  
 onChange={()=>{this.handleChange(item.po_id,index)}}
 inputProps={{ 'aria-label': 'uncontrolled-checkbox'  }} 
   />
)} 
  {item.selected==="1" && (
<Checkbox
  checked
 onChange={()=>{this.handleChange(item.po_id,index)}}
 inputProps={{ 'aria-label': 'uncontrolled-checkbox'  }} 
   />
)}  
   
   </TableCell>
   
		<TableCell>{item.po_billno}</TableCell>
<TableCell>{item.po_date}</TableCell>
<TableCell>{item.subtotal}</TableCell>
<TableCell>{item.paidamt}</TableCell>	
<TableCell>{dueamt}</TableCell>	
			
			 </TableRow>
			 </React.Fragment>	
				);
					})}
					
	{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="13">No Records</TableCell>
					</TableRow>
					) }				
					
					
	
 </TableBody>
 
 <TableFooter className="tbltot">
<TableCell ></TableCell><TableCell ></TableCell><TableCell ></TableCell>
<TableCell className="footertotal">{grandtotal}</TableCell>
<TableCell >{paidtotal}</TableCell>
<TableCell >{duetotal}</TableCell>
</TableFooter>
 
 
 
</Table> 
</TableContainer>
</Paper>


</div>




<div className="buttons">





<p className="btnleft"><Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            id="discard"
            className={useStyles.submit}
			
			onClick={this.handleDiscard}
			
          >
           Discard
          </Button></p>




<p className="btnright">

<Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            id="searchform" 
            className={useStyles.submit}
			
			onClick={this.handleSubmit}
			disabled={this.state.btndisabled}
          >
          {this.state.submitbtn}
          </Button></p>



<div className="clear"></div>
</div>



   </div></div></div>
 </React.Fragment>
		</Section></Page>
  );
  }	
   

}

