import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  
import { Page, Section } from 'react-page-layout';

import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';

import PropTypes from 'prop-types';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}





const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class dashboard extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] 
      }
	 };
    
   
 myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 }  




componentDidMount() {
	
	
	if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
	
	document.title = "Dashboard";
	
	
 
  this.apicall(); 

}



apicall(){
	
	 axios.get(Serverurl+"ajaxdashboard.php?action=list")
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  StoreData: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
  
    });
}



  
render() {
	
if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    }
  
  	 
    
  return (
  
  
    <Page layout="public">

<Section slot="menu"> / <a href='#/configuration'>Configuration</a> / <a href='dashboard'>Dashboard</a>
</Section>

<Section slot="btn"><a href='#/configuration'>Configuration</a>
</Section>

<Section slot="content">
  
  
   <React.Fragment>
   
   {localStorage.getItem("Rrole") === "1" && (
    <div className="dashboard">

 <div className="main">

		        

                     {stableSort(this.state.StoreData, getSorting(this.state.orderBy, this.state.order)).map((item,index) => {  
           return ( <React.Fragment>


<ul>
<li>
<div className="leftimg"><PeopleAltIcon/></div>
<div className="count"><h3>{item.custcount}</h3><p><b>CUSTOMERS</b></p></div>
<div className="clear"></div>
<div className="view"><p><a href='#/admincustomer?cu=1'>View More ></a></p></div>
</li>


<li>
<div className="leftimg" id="vendorclr"><SettingsIcon/></div>
<div className="count" id="vendorclr"><h3>{item.vendorcount}</h3><p><b>VENDORS</b></p></div>
<div className="clear"></div>
<div className="view"><p><a href='#/admincustomer?cu=2'>View More ></a></p></div>
</li>


<li>
<div className="leftimg" id="catclr"><SettingsIcon/></div>
<div className="count" id="catclr"><h3>{item.catcount}</h3><p><b>CATEGORIES</b></p></div>
<div className="clear"></div>
<div className="view"><p><a href='#/admincategories'>View More ></a></p></div>
</li>


<li>
<div className="leftimg"><SettingsIcon/></div>
<div className="count"><h3>{item.productcount}</h3><p><b>PRODUCTS</b></p></div>
<div className="clear"></div>
<div className="view"><p><a href='#/adminproduct'>View More ></a></p></div>
</li>

<li>
<div className="leftimg" id="invoiceclr"><SettingsIcon/></div>
<div className="count" id="invoiceclr"><h3>{item.ordercount}</h3><p><b>INVOICE</b></p></div>
<div className="clear"></div>
<div className="view"><p><a href='#/Addinvoice'>View More ></a></p></div>
</li>



</ul>



				</React.Fragment>	
				);
					})}
                     

 

   
 </div>

   </div>
   
  )}
  
  
  {localStorage.getItem("Rrole") === "3" && (
  
  
  <div className="dashboard">

 <div className="main">

		        

                     {stableSort(this.state.StoreData, getSorting(this.state.orderBy, this.state.order)).map((item,index) => {  
           return ( <React.Fragment>


<ul>

<li>
<div className="leftimg" id="invoiceclr"><SettingsIcon/></div>
<div className="count" id="invoiceclr"><h3>{item.ordercount}</h3><p><b>INVOICE</b></p></div>
<div className="clear"></div>
<div className="view"><p>View More ></p></div>
</li>



</ul>



				</React.Fragment>	
				);
					})}
                     

 

   
 </div>

   </div>
   
  )}
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
   
 </React.Fragment>
		
		  
	 </Section></Page>	 


  );
  }	
   
   


}