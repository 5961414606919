import React, { Component } from "react";






//const Serverurl= ["http://localhost/xampp/apoorva/rajshinkar/rashinkarsbilling/"]




const Serverurl= ["http://bill.globalbuzz.in/appfiles/"]

export default Serverurl;






