import React ,{Component} from 'react';


import { Page, Section } from 'react-page-layout';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Serverurl from './Apiurl';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import InputLabel from '@material-ui/core/InputLabel';


const useStyles = makeStyles(theme => ({
 
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  
  form: {
    width: '500%', 
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
  
 
  
  
}));



export default class adduser extends Component {
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],redirect: false,
	 
	 
	search:'',order:'desc' ,orderBy:'us_id',name:'',us_id:0,username:'',email:'',uspassword:'',mobile:'',rolearray:[],role:'',address:'',
	rolelabel:'Select Role',role:'',code:'',btndisabled:false,
submitbtn:"Submit",
	  
        
      }
	 };


 



onloadrolebind(){

    axios.get(Serverurl+"ajaxmaster.php?action=list&ma_type=1")
    
      .then((response) => {    
     
      this.setState({  rolearray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.rolearray.map(role => {
                            
          return {value: role.ma_id, display:role.ma_value}
        });
      
		
        this.setState({
          rolearray: [{value: '0', display: 'Select Role'}].concat(roleFromApi)
        });
      })


}


handleClickShowPassword = () => {
    
	this.setState({
            showPassword: !this.state.showPassword
          })
  };
	
	  handleMouseDownPassword = event => {
    event.preventDefault();
  };






getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}




componentDidMount() {
 
 if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
 
 else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "3" || localStorage.getItem("Rrole") === "4" )

	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  

	}
 
  /* let search = window.location.search;
let params = new URLSearchParams(search);
let usid = params.get('usid');*/

let search = window.location.href;
let usid = this.getParameterByName('usid',search);


if(usid!=null)
{
	
	setTimeout(()=>this.onloaduserbyid(usid),500); 
	//this.onloaduserbyid(usid);
}

this.onloadrolebind();
this.adminoptioncall();

}






adminoptioncall(){
		
axios.get(Serverurl+'ajaxadminoption.php?action=list&ao_id=2')
 
  
  .then(resp => {  
	
  
		
    if(resp.data.length>0){
		 var currentYear = (new Date()).getFullYear();
		 	  let nxt=(currentYear-1)+'-'+(currentYear);
			this.setState({  
			
              code: resp.data[0].ao_type		  
			   
			});
			 
     }
  
    });



		
	}








myChangeHandler = (event) => {
	
	const re = /^[0-9\b]+$/; 
	
  let nam = event.target.name;
  let val = event.target.value;
  
  if(nam==="mobile")
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	  
  }
  }
  else{
  this.setState({[nam]: val});
  }
  
  
  
  
 

 }  

myRoleChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
 this.setState({[nam]: val});
  
	
 

}

handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
  
  
  
onloaduserbyid(usid){
		
		 axios.get(Serverurl+'ajaxuser.php?action=list&us_id='+usid)
  .then(resp => {  
   if(resp.data.length>0){
	   
    this.setState({ 
   	us_id:resp.data[0].us_id,
	name:resp.data[0].us_name,
 username:resp.data[0].us_username,	
	email:resp.data[0].us_email, 
   
	uspassword:resp.data[0].us_password,
	mobile:resp.data[0].us_mobile,
	role:resp.data[0].us_ma_id,
	address:resp.data[0].us_address,
	code:resp.data[0].us_code,
  
	});
	
   }
  });

} 


handleSubmit= (event) => {
    
    event.preventDefault();
	//alert('')
    
    let name = this.state.name;
    let username = this.state.username;
	let email = this.state.email;
    let uspassword = this.state.uspassword;
	let mobile = this.state.mobile;
    let role = this.state.role;
	let address = this.state.address;
	let code = this.state.code;
   
	 const remail= /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
	
	


if(name==="")
{
	this.setState({errormessage:"Please Enter Name.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(username==="")
{
	this.setState({errormessage:"Please Enter User Name.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}	

if(email !== ""){

if (! remail.test(email)) {
	  this.setState({errormessage:"Please Enter valid Email.",open:true});
	  window.scrollTo({
          top: 0
      })
	return false;
  }

}
if(uspassword==="")
{
	this.setState({errormessage:"Please Enter Password.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}


if(mobile==="")
{
	this.setState({errormessage:"Please Enter Mobile Number.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}

if(role==="")
{
	this.setState({errormessage:"Please Select Role.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}
/*
if(address==="")
{
	this.setState({errormessage:"Please Enter Address.",open:true});
	window.scrollTo({
          top: 0
      })
	return false;
}
*/


this.setState({btndisabled:true,submitbtn:"Processing..."})

	  var formData = new FormData();
	 if(this.state.us_id===0)
	  {
		  formData.append('us_id',0) 
		  
	  }
	  else
	  {
		  
	   formData.append('us_id',this.state.us_id)
		
          
	  }
	  		 
	 //alert(this.state.us_id)
	
//alert(name);
formData.append('action','insertorupdate') 

formData.append('us_name',name)
formData.append('us_username',username)
formData.append('us_email',email)
formData.append('us_password',uspassword)
formData.append('us_mobile',mobile)
formData.append('us_ma_id',role)
formData.append('us_address',address)
formData.append('us_code',code)
	   
formData.append('userid',localStorage.getItem("Ruserid"))	
	 
	   	  axios.post(Serverurl+"ajaxuser.php", formData, {
        }).then(res => {
			
			if(res.data==-1)
{ 
	 

		this.setState({errormessage:"UserName Already Exist.",open:true,btndisabled:false,submitbtn:"Submit"
		});
		window.scrollTo({
          top: 0
      })
	  

	}else{
		this.setState({errormessage:"Record Udated Successfuly",open:true,btndisabled:false,submitbtn:"Submit",
		
		  
			
			
		redirect: true,pagename:"/userbarcode?usid="+res.data,	
		
 			
		name:"",
		username:'',
		email:'',
		uspassword:'',
		mobile:'',
		role:'',
		address:'',
		code:'',
		us_id:0
		});
			
	}		
            });
			
};

 handleClickdiscard = (e) => {
 
  this.setState({ open:!this.state.open,  name:'',us_id:0,username:'',email:'',uspassword:'',mobile:'',rolearray:[],role:'',address:'',
	rolelabel:'Select Role',role:'',code:'',
   
	  
		 });
		


};
	

render() {
	if (this.state.redirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
	
	
	return (
	
	
	<Page layout="public">

<Section slot="menu"> / <a href='#/configuration'>Configuration</a> / <a href='adduser'>Add User</a>
</Section>

<Section slot="btn"><a href='#/adminuser'>Back</a>
</Section>

<Section slot="content">
	
	
<div class="userform">


<div class="main">


<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>

  <form className={useStyles.form}  onSubmit={this.handleSubmit}>

<div className="headername">
		 <p><b>  New User</b></p>
		 </div>

<div className="formbgclr">



      <ul>
<li>	 
<p>Name <span className="validation">*</span></p>
        <p>
		<TextField
            variant="outlined"
            margin="normal"
           inputProps={{ tabIndex: "1",maxLength:150 }} 
            fullWidth
			value={this.state.name}
            id="name"
            autoFocus
            name="name" onChange={this.myChangeHandler}
            
			
            
			
          /></p></li>


        <li> 
<p>Username <span className="validation">*</span></p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
              inputProps={{ tabIndex: "2",maxLength:150 }} 
            fullWidth
			value={this.state.username}
            id="username"
           
            name="username" onChange={this.myChangeHandler}
           
            
			
          /></p></li>
		  
		<li> 
<p>Email </p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
              inputProps={{ tabIndex: "3",maxLength:150 }} 
            fullWidth
			value={this.state.email}
            id="email"
           
            name="email" onChange={this.myChangeHandler}
            autoComplete="email"
            
			
          /></p></li>
		  
		
		   
		  <li className="inputpassword">
		  <p>Password <span className="validation">*</span></p>
		  <p> <FormControl fullWidth  variant="outlined">
 <InputLabel htmlFor="standard-adornment-password"></InputLabel>
          <OutlinedInput value={this.state.uspassword}
            id="uspassword" name="uspassword" 
			inputProps={{maxLength:15,  tabIndex: "4", }} 
			type={this.state.showPassword ? 'text' : 'password'}
            onChange={this.myChangeHandler}
            endAdornment={<InputAdornment position="start"> <IconButton
                     onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword}  >
				  { this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                
                </IconButton></InputAdornment>}
           />
		 </FormControl></p></li>
		 
		  
		  
		  
		<li>  
		<p>Mobile No <span className="validation">*</span></p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
            inputProps={{ tabIndex: "3",maxLength:12 }} 
            fullWidth
			value={this.state.mobile}
            id="mobile"
            
            name="mobile" onChange={this.myChangeHandler}
            autoComplete="mobile"
			inputProps={{maxLength:10,  }} 
            
			
          /></p></li>
		  
		 <li className="dropdown">  
		 
		
		<p>
		<p >User Role <span className="validation">*</span></p>
		<FormControl  className={useStyles.formControl} >
         
        <Select
         native   inputProps={{ tabIndex: "4" }} 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.role }}
          onChange={this.myChangeHandler}
          id="state1"
		   value={this.state.role}
		  name="role"
		
		
        >
		{this.state.rolearray.map(role => (
            <option  
            
              value={role.value}
            >
              {role.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
		
		
		
		
		
		</li>
		
		
		
		
		
		
		   </ul>
		   
		   
		   	   
		  
		<p>
		<p>Address </p>
		   <TextField
            id="outlined-multiline-static"
       
          multiline
          rows={4}
		  variant="outlined"
            value={this.state.address}
            id="address"
			 inputProps={{ tabIndex: "5" }} 
            fullWidth
           
            name="address" onChange={this.myChangeHandler}
            autoComplete="address"
            autoFocus
          />  </p>
		  
	
		  
		  
		 <div className="formbtns">
		  <ul>
	
		  
		  <li> <p> <Button
            type="submit"
            halfWidth inputProps={{ tabIndex: "7" }} 
            variant="contained" 
            color="primary"
            id="discard"
            className={useStyles.submit}
			onClick={this.handleClickdiscard}
          >
           Discard
          </Button></p></li>	<li> <p> <Button
            type="submit"
			disabled={this.state.btndisabled}
            halfWidth inputProps={{ tabIndex: "6" }} 
            variant="contained" 
            color="primary"
             id="searchform" 
            className={useStyles.submit}
          >
           {this.state.submitbtn}
          </Button></p></li>
		  </ul>
		  </div>
		  
		 </div>
		  
		   
		 </form> 

  
</div>
       





	   
</div>


		 </Section></Page>	  
		   
		
		  
		 
		  




  );
  }
}










