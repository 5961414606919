import React ,{Component} from 'react';


import { Page, Section } from 'react-page-layout';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Serverurl from './Apiurl';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';


import ReactToPrint from 'react-to-print';

import Barcode from 'react-barcode';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';



const useStyles = makeStyles(theme => ({
 
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  
  form: {
    width: '500%', 
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
}));
 
export default class userbarcode extends Component {
	
	constructor(props) {
    super();
	
	
	
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',usercode:'',Username:0
	 

      }
	 };


 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


componentDidMount() {
 
 if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
 
 else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "3" || localStorage.getItem("Rrole") === "4" )

	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  

	}
 
  /* let search = window.location.search;
let params = new URLSearchParams(search);
let usid = params.get('usid');*/

let search = window.location.href;
let usid = this.getParameterByName('usid',search);

//alert(usid)
if(usid!=null)
{
	
	this.onloaduserbyid(usid); 
	
	
}




}





  
  
  
onloaduserbyid(usid){
		
		 axios.get(Serverurl+'ajaxuser.php?action=list&us_id='+usid)
  .then(resp => {  
   if(resp.data.length>0){
	 
    this.setState({ 
   
	usercode:resp.data[0].us_code, 
   
	Username:resp.data[0].us_username,
	name:resp.data[0].us_name,
	
	
	
  
	});
	
   }
   
    document.getElementById('print-barcode').click();
  });

} 



	

render() {
	if (this.state.redirect === true) {
      return <Redirect to='/adminuser' />
    }
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
	
	
	return (
	
	<Page layout="public">

<Section slot="menu"><a href='#/configuration'>Configuration</a> /  User
</Section>

<Section slot="btn"><a href='#/adminuser'>Back</a>
</Section>

<Section slot="content">
	
	
	


	
<div className="productbarcode" >
<div ref={el => (this.componentRef = el)} style={{textAlign: "center",width: "343px",height:"96px", margin: "auto" }}>
<h3 style={{margin: "0"}}>Rashinkar's</h3>


<div style={{width: "192px",height:"96px"}}>
<Barcode value={this.state.Username}   format="CODE128" textAlign="center"   width="2" height="50" />



</div>

</div>

<b/>
<br/>
<ReactToPrint
            trigger={() => <p id="print-barcode" className="Primarybuttons"> Print Barcode</p>}
            content={() => this.componentRef}
          />
</div>

<div className="clear"></div>



	 
	 </Section></Page>		
		  
		 
		  




  );
  }
}











