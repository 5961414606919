import React ,{Component} from 'react';


import { makeStyles } from '@material-ui/core/styles';  
import { Page, Section } from 'react-page-layout';

import { Redirect  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table'; 
import TableContainer from '@material-ui/core/TableContainer';  
import TableRow from '@material-ui/core/TableRow';  
import TableHead from '@material-ui/core/TableHead';  
import TableCell from '@material-ui/core/TableCell'; 
import TableSortLabel from '@material-ui/core/TableSortLabel'; 
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';  
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';
import Uploadsurl from './Apiurl';
import './styles.css';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';

import Tooltip from '@material-ui/core/Tooltip';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';

import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';

import ReactExport from "react-export-excel";

import ReactToPrint from 'react-to-print';
import TableFooter from '@material-ui/core/TableFooter';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

let strCus=[{title:'',year:0}];
const strCusdeflt=[];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [




{ id: 'ma_value', numeric: false,  label: 'Invoice Type' },
{ id: 'or_invoice', numeric: false,  label: 'Invoice No' },
{ id: 'or_createddate', numeric: false,  label: 'Invoice Date' },
{ id: 'us_username', numeric: false,  label: 'Salesperson' },
{ id: 'cu_contactname', numeric: false,  label: 'Customer' },
{ id: '', numeric: false,  label: 'Sub Total' },
{ id: 'or_discount', numeric: false,  label: 'Discount' },
{ id: 'tx_rate', numeric: false,  label: 'Tax' },
{ id: '', numeric: false,  label: 'Total Amount' },

{ id: 'or_roundoff', numeric: false,  label: 'Round off' },
{ id: '', numeric: false,  label: 'Grand Total' },




]

function EnhancedTableHead(props) {
  const {  order, orderBy,   onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  
  
  
  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = props => {
const classes = useToolbarStyles();
const { numSelected } = props;

  
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class  Invoicebillreport extends Component {
	
	
	constructor(props) {
    super();
	
	
	var date = addDays(moment().toDate(),0);
   var month="",  month1="",date1="";
  if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate())		 
	 }else{date1=(date.getDate() )}
	 
	 
	    var strtodate = addDays(moment().toDate(),0);
   var strmonth="",  month1="",strdate1="";
  if((strtodate.getMonth() + 1)<10)
	 {
		strmonth="0"+ (strtodate.getMonth() + 1)		 
	 }else{strmonth=(strtodate.getMonth() + 1)}
	  if((strtodate.getDate() )<10)
	 {
		strdate1="0"+ (strtodate.getDate())		 
	 }else{strdate1=(strtodate.getDate() )}
	
	
	
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:-1,totaldata:0,rowsPerPage:50,rowsPaging:[],
	 
	 
	search:'',order:'desc' ,orderBy:'or_id',value:'',invoice:'',createddate:'',contactname:'',reporttype:'',
	discount:'',rate:'',roundoff:'',searcharray:[],custtags:[],customer:'',reporttype:'1',briefdisplay:'1',detaileddisplay:'0',orid:'',InvoiceData:[],
	orname:'',oraddress:'',orcity:'',oremail:'',ormobilenum:'',orpannum:'',orgstinnum:'',or_taxamount:0,exportData:[],
	
	
	 
	   date: addDays(moment().toDate(),0),
	fdate:addDays(moment().toDate(),0),tdate:addDays(moment().toDate(),0),
fdate1:date.getFullYear() + '-' + month + '-' +date1,tdate1:strtodate.getFullYear() + '-' + strmonth + '-' +strdate1,btndisabled:false,
submitbtn:"Submit", salesarray:[],sales:'',orlogo:'', orphonenum:'',


 
        
      }
	  
	   this.onTagsChange = this.onTagsChange.bind(this);
	  
	 };
	 
	 handfrChange = (date) => {
	 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }
  
  
	 handtoChange = (date) => {
	  var month="",date1="";
	 
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)} 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
	  var strtotime=this.state.fdate1.split(' ');
	 
	if(strtotime[0]>date.getFullYear() + '-' + month + '-' +date.getDate())
	{
		 var strtoday=this.state.fdate1.split('-');
		 if(strtoday[0]>date.getFullYear() && strtoday[1]>month && strtoday[2]>date.getDate())
	{
	alert("Please Select To date greater than equal to")
	return false;
	}
	}
	 
     this.setState({ tdate:date,
tdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  } 
	 
	 
	 
onloadCustomerBind=(event)=>{
	// axios.get(Serverurl+'ajaxcustomer.php?action=searchextender')
	  axios.get(Serverurl+'ajaxcustomer.php?action=searchextender&cu_contactname='+event.target.value)

  .then(resp => { 
  this.setState({ searcharray:resp.data});

	 var rowsArray1 = this.state.searcharray;
	 rowsArray1.map((row, k) => {
			 
		 const items = {
			 
        title:row.title,
         year:row.year
          
        };
         strCus.push( items)
		
		 });
  });
  
  
}	
	 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  
  localStorage.removeItem("fromdate");
   localStorage.removeItem("todate");
  
  this.setState({[nam]: val});
  
 /* if(nam==="" &&  val!="1")
{
this.setState({briefdisplay:"1",detaileddisplay:"0"	})
}

else {
this.setState({briefdisplay:"0",detaileddisplay:"1"})
	
}*/

 } 
 

handleSubmit= (event) => {
    
    event.preventDefault();
	
  let value = this.state.value;
	let invoice = this.state.invoice;
	let customer = this.state.customer;
	
	let fdate = this.state.fdate1;
	let tdate = this.state.tdate1;
	let reporttype = this.state.reporttype;
	let sales = this.state.sales;
		if(reporttype !== "1")
{
this.setState({detaileddisplay:"1",briefdisplay:"0"})

}

else{
	this.setState({briefdisplay:"1",detaileddisplay:"0"	})

	
}

	this.apicall(this.state.page,this.state.rowsPerPage);
	
}


 componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}

componentDidMount() {
	
	document.title = "";
		
		if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
		
		
 localStorage.setItem("page",this.state.rowsPerPage);
  this.apicall(this.state.page,this.state.rowsPerPage); 

 this.orgviewmore(); 

}	 


orgviewmore(){
	
	axios.get(Serverurl+'ajaxorganization.php?action=orgviewmore&or_id=1')
	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  orgviewmore: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  this.setState({		
    orname:resp.data[0].or_name,	
	oraddress:resp.data[0].or_address, 
   
	orcity:resp.data[0].or_city,
	oremail:resp.data[0].or_email,
	ormobilenum:resp.data[0].or_mobilenum,
	orpannum:resp.data[0].or_pannum,
	
	orgstinnum:resp.data[0].or_gstinnum,
	orlogo:resp.data[0].or_logo,
	orphonenum:resp.data[0].or_phonenum,
	
	 }); 
	 
     }
 
    });
	this.salespersonbind();
}


salespersonbind(){
  axios.get(Serverurl+"ajaxuser.php?action=salespersonbind")
    
      .then((response) => {    
     
      this.setState({  salesarray: response.data,  });
      })   
    
      .then(data => {
        let salesFromApi = this.state.salesarray.map(sales => {
                            
          return {value: sales.us_id, display: sales.us_username}
        });
      
		
        this.setState({
          salesarray: [{value: ' ', display: 'Salesperson'}].concat(salesFromApi)
        });
      })
  }	


apicall(page,rowsPerPage){
	
	
 this.setState({btndisabled:true,submitbtn:"Processing..."})	
	
	// axios.get(Serverurl+'ajaxorders.php?action=invoicereport&ma_value='+this.state.value+'&or_invoice='+this.state.invoice+'&cu_contactname='+this.state.customer+'&StartIndex='+page+'&PageSize='+rowsPerPage)
  
  axios.get(Serverurl+'invoice.php?or_id='+this.state.orid+'&ma_value='+this.state.value+'&or_invoice='+this.state.invoice+'&cu_contactname='+this.state.customer+'&fromdate='+this.state.fdate1+'&todate='+this.state.tdate1+'&us_id='+this.state.sales+'&StartIndex='+page+'&PageSize='+rowsPerPage)
  
 
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			 // StoreData: resp.data, 
              InvoiceData: resp.data,			  
			   totaldata:parseInt(resp.data[0].count),
			    or_taxamount:resp.data[0].or_taxamount,
			   discount:resp.data[0].or_discount,
			   roundoff:Number(resp.data[0].or_roundoff).toFixed(2),
			});
			let cnt=this.state.totaldata;
			
			let page=this.state.rowsPerPage;
			 cnt=Number(cnt)/Number(page);
		let copy=[50,100,150];
		
			/*for (var key=0;key<cnt;key++) { 
			
				let val=(Number(key)*5)+5;
				
			 copy.push(val)
			}*/
			
	
	  this.setState({rowsPaging:copy ,btndisabled:false,submitbtn:"Search"});
	  
	 
     }
	 else{
		  this.setState({InvoiceData:[],
		 totaldata:0,
		 btndisabled:false,submitbtn:"Search"});
	  
	 }
  
    });
	
	this.exportapicall();
	
}

exportapicall(){
	
		 axios.get(Serverurl+'invoice.php?or_id='+this.state.orid+'&ma_value='+this.state.value+'&or_invoice='+this.state.invoice+'&cu_contactname='+this.state.customer+'&fromdate='+this.state.fdate1+'&todate='+this.state.tdate1+'&us_id='+this.state.sales)
  
  
	 .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  exportData: resp.data,  
			
			  
			   totaldata:parseInt(resp.data[0].count),
			   
			   
			   
			});
			
		
	 
     }
  
    });
	

	
}





handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
  handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });  
  
}


onTagsChange = (event, values) => {
	
	let optionsval= values["year"];
	let optionsval1= values["title"];

	
	 
    this.setState({
      custtags: values,
	   customer: values["title"]
	   
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
     
    });
  }

/*
<ExcelFile element={<button class="expbtn">Excel</button>}>
        <ExcelSheet data={this.state.InvoiceData} name="Invoice Report">
		<ExcelColumn label="Invoice Type" value="ma_value"/>
        <ExcelColumn label="Invoice No" value="or_invoice"/>
        <ExcelColumn label="Invoice Date" value="or_createddate"/>
		 <ExcelColumn label="Sales Person" value="us_username"/>
	    <ExcelColumn label="Customer" value="or_name"/>
		<ExcelColumn label="Sub Total" value="subtotal"/>
		<ExcelColumn label="Discount" value="or_discount"/>
		<ExcelColumn label="Tax Amount" value="or_taxamount"/>
		<ExcelColumn label="Total Amount" value="extotalamt"/>
		<ExcelColumn label="Round Off" value="or_roundoff"/>
		<ExcelColumn label="Grand Total" value="exgtotal"/>
	   
		
    
                </ExcelSheet>                
            </ExcelFile>*/

render() {
	
	
	 let search = window.location.search;
let params = new URLSearchParams(search);

let subtaotal = 0;
let grandtotal = 0,strsubtotal=0,strgrndtotal=0,strtotal=0;
	
	return (
	
	
	 <Page layout="public">

<Section slot="menu"> / Report / Invoice Report
</Section>

<Section slot="btn"><a href='#/Reports'>Reports</a>


<ReactToPrint
            trigger={() => <p id="print-barcode" className="printreport"> Print </p>}
            content={() => this.componentRef}
			
          />
		  
		  
		  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="expbtn"
                    table="invreport"
                    filename="Invoice report"
                    sheet="Invoice Report"
                    buttonText="Excel"/>




</Section>

<Section slot="content">
  
   <React.Fragment>
   
   
    <div className="main">
	
	<br></br>
 
 <div className="headername">
		 <p><b>Invoices</b></p>
		 </div>
		
	
	
	<div className="search">
	
	<ul>
	
	<li><p>From :</p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth
        value={this.state.fdate || addDays(moment().toDate(),1)}
        onChange={this.handfrChange}
		maxDate={addDays(moment().toDate(),0)}
            format="dd/MM/yyyy"
        autoOk name="fdate"
        ampm={false}
	id="borderdate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		</li>
 
 <li><p>To :</p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
      <DatePicker fullWidth
        value={this.state.tdate || addDays(moment().toDate(),1)}
		maxDate={addDays(moment().toDate(),0)}
        onChange={this.handtoChange}
            format="dd/MM/yyyy"
        autoOk name="tdate"
        ampm={false}
		id="borderdate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
	
		</li>
	
	
	
	<li className="lialignment">
	<p><TextField
           
            margin="normal"
            
            fullWidth
			value={this.state.value}
            id="value"
           variant="outlined"
            name="value" onChange={this.myChangeHandler}
            autoComplete="value"
			placeholder="Invoice type"
            autoFocus
			
          />
		  </p></li>
		  
		 <li> <p><TextField
            //variant="outlined"
            margin="normal"
            variant="outlined"
            fullWidth
			value={this.state.invoice}
            id="invoice"
           
            name="invoice" onChange={this.myChangeHandler}
            autoComplete="invoice"
			placeholder="Invoice No"
            autoFocus
			
          />
		  </p></li>
		  
		  
		  
		  
		  
		 <li> <Autocomplete
       
        
      
       margin="normal"
      
            fullWidth
	   options={this.state.searcharray}
        getOptionLabel={option => option.title}
		//options={strCus}
   //defaultValue={strCusdeflt}
	  onChange={this.onTagsChange}  
        renderInput={params => (
          <TextField {...params} onChange={this.onloadCustomerBind}  variant="outlined" id="search" placeholder="Customer" />
        )}
      /></li>
	  
	  
	  
	    
		<li>
		<FormControl className={useStyles.formControl}  id="myformgift" >
       
        <Select
         native
         variant="outlined"
		  defaultValue={ this.state.sales }
        
          onChange={this.myChangeHandler}
          
		   value={this.state.sales}
		  name="sales"
        >
		{this.state.salesarray.map(sales => (
            <option  
            
              value={sales.value}
            >
              {sales.display}
            </option  >
          ))}
		
        </Select>
		</FormControl>
</li>	
	  
	  <li> <FormControl  className={useStyles.formControl}>
       
        <Select
          native
		   variant="outlined"
         value={this.state.reporttype}
          onChange={this.myChangeHandler}
        name='reporttype'
         id='reporttype'
		  InputLabelProps={{
            shrink: true,
          }}
        >
		  <option value={0}>Select Report Type</option>
         
          <option value={1}>Brief Report</option>
          <option value={2}>Detail Report</option>
          
		
        </Select>
      </FormControl>
	  </li>
			
		  
 
 </ul>
		
		  
		 <p className="btncenter"> <Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
           id="searchform"
            className={useStyles.submit}
			onClick={this.handleSubmit}
			disabled={this.state.btndisabled}
          >
             {this.state.submitbtn}
          </Button></p>	
	
	  	
	
	{this.state.briefdisplay === "1" && ( <React.Fragment>
	
	<div className="main">
	
	<div ref={el => (this.componentRef = el)} >
	
	
	
	<div className="reportheading">
		
       <b>INVOICE REPORT</b>
           </div>
		   
	<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table" > 	   
 
<TableRow >  
<TableCell colSpan={2}>

<div className="reportmain">
<div className="repfromdetails">


	
	<p><b>{this.state.orname}</b><br/>
	{this.state.oraddress} <br/> 
	{this.state.orcity} <br/>

{this.state.orgstinnum}<br/>
	{this.state.orphonenum} , {this.state.ormobilenum}

	<br/>{this.state.oremail}</p>
	
	</div>
	
	<div className="replogo">
	
	{this.state.orlogo!=="" &&(
 <p> <img  src={Uploadsurl+"uploads/"+this.state.orlogo} /> </p>
 )}
 
	</div>
	<div clssName="clear"></div>
	
	
	</div>
	
	</TableCell>
 </TableRow> 

     </Table >
	  </TableContainer>
	  </Paper>
		   
	
<Paper className={useStyles.root}>
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table"  id="invreport">  
 
 
 
 
 

		    <EnhancedTableHead
             
              
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.InvoiceData.length}
            />
                      <TableBody>
					  
					  
					  
					  
					  
					  

                     {stableSort(this.state.InvoiceData, getSorting(this.state.orderBy, this.state.order)).map((item,index) => {  
            grandtotal=Math.round(Number(item.subtotal)+Number(item.or_taxamount)-Number(item.or_roundoff))
		let totalamount=Number(item.subtotal).toFixed(2)-Number(0)+Number(item.or_taxamount)
		 
 strsubtotal=(Number(strsubtotal)+Number(item.subtotal)).toFixed(2);
		strgrndtotal=(Number(strgrndtotal)+Number(grandtotal)).toFixed(2);
		strtotal=(Number(strtotal)+Number(totalamount)).toFixed(2);




		 return ( <React.Fragment>
 <TableRow key={index} >





<TableCell><a className="link" href={"#/invoiceviewmore?orid="+item.or_id+""}>{item.ma_value}</a></TableCell>

<TableCell><a className="link" href={"#/invoiceviewmore?orid="+item.or_id+""}>{item.or_invoice}</a></TableCell>
<TableCell><a className="link" href={"#/invoiceviewmore?orid="+item.or_id+""}>{item.or_createddate}</a></TableCell>
<TableCell><a className="link" href={"#/invoiceviewmore?orid="+item.or_id+""}>{item.us_username}</a></TableCell>
<TableCell><a className="link" href={"#/invoiceviewmore?orid="+item.or_id+""}>{item.or_name}</a></TableCell>
<TableCell><a className="link" href={"#/invoiceviewmore?orid="+item.or_id+""}>Rs. {Number(item.subtotal).toFixed(2)}</a></TableCell>

<TableCell><a className="link" href={"#/invoiceviewmore?orid="+item.or_id+""}>Rs. {item.or_discount}</a></TableCell>
<TableCell><a className="link" href={"#/invoiceviewmore?orid="+item.or_id+""}>Rs. {Number(item.or_taxamount).toFixed(2)}</a></TableCell>
<TableCell><a className="link" href={"#/invoiceviewmore?orid="+item.or_id+""}>Rs. {Number(totalamount).toFixed(2)}</a></TableCell>
<TableCell><a className="link" href={"#/invoiceviewmore?orid="+item.or_id+""}>Rs. {Number(item.or_roundoff).toFixed(2)}</a></TableCell>
<TableCell><a className="link" href={"#/invoiceviewmore?orid="+item.or_id+""}>Rs. {Number(grandtotal)}</a></TableCell>



	
	

 </TableRow>


				</React.Fragment>	
				);
					})}
                      </TableBody>
					  
					  
				  
		{this.state.totaldata!==0 &&(				  

<TableFooter className="tbltot">
<TableCell >Total</TableCell><TableCell></TableCell><TableCell></TableCell>
<TableCell></TableCell><TableCell></TableCell>
<TableCell>Rs. {strsubtotal}</TableCell>
<TableCell></TableCell><TableCell></TableCell>
<TableCell>Rs. {strtotal}</TableCell><TableCell></TableCell>
<TableCell>Rs. {strgrndtotal}</TableCell></TableFooter>

) }	

</Table>
  </TableContainer> 
 <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />    
  </Paper>
  
  </div>
  </div>
   
 </React.Fragment>)}

   </div></div>
 </React.Fragment>
 
 
 {this.state.detaileddisplay === "1" && ( <React.Fragment>
   
   <div ref={el => (this.componentRef = el)} >
  <div className="main">
  

  <div className="reportheading">
		
       <b>INVOICE REPORT</b>
           </div>
 
  
  
   <div className="quotationview">

<Paper className={useStyles.root}>
  
 <TableContainer className={useStyles.container}>  
 <Table stickyHeader aria-label="sticky table" id="invreport"> 



  
<TableRow >  
<TableCell colSpan={2}>

<div className="reportmain">
<div className="repfromdetails">


	<p><b>{this.state.orname}</b></p>
	<p>{this.state.oraddress} <br/> {this.state.orcity} </p>

<p>{this.state.orgstinnum}</p>
	<p>{this.state.orphonenum} , {this.state.ormobilenum}</p>

	<p>{this.state.oremail}</p>
	
	</div>
	
	<div className="replogo">
	
	{this.state.orlogo!=="" &&(
 <p> <img  src={Uploadsurl+"uploads/"+this.state.orlogo} /> </p>
 )}
 
	</div>
	<div clssName="clear"></div>
	
	
	</div>
	
	</TableCell>
 </TableRow> 

            {this.state.InvoiceData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row,index) => {  
               
		  
			   return ( 
                 <React.Fragment>
           
               
			    <TableRow colSpan={2} >  

	           
			
  <TableCell>	          
			
		
<p>To:</p>

<p>Name : {row.or_name}</p>
<p>Email : {row.or_email}</p>
<p>Phone No : {row.or_phone}</p>
<p>PAN No : {row.cu_panno}</p>
<p>GST No : {row.or_gstn}</p>

	</TableCell>			
          
   
		 
<TableCell>		   
		   
<p>Details :</p>


<p>Invoice Type : <b>{row.ma_value}</b></p>
<p>Invoice No : {row.or_invoice}</p>
<p>Invoice Date: {row.or_createddate}</p>
<p>Sales Person : {row.us_name}</p>

</TableCell>
		   
		  
              </TableRow> 
			  
			
			  
              
  <TableRow className="expandable" ref="expanderBody" key={"tr-expander"}> 
      <TableCell className="uk-background-muted" colSpan={2}> 
      <TableContainer className={useStyles.container}>  
        <Table stickyHeader aria-label="sticky table"> 
        <TableBody>	

 <TableRow>  
        
			   <TableCell ><b>Product Code</b></TableCell>
			   <TableCell ><b>Product Name</b></TableCell>			   
               <TableCell ><b>S.Rate(Inc)</b></TableCell>  
               <TableCell ><b>S.Rate(Exc)</b></TableCell>
               <TableCell ><b>Discount</b></TableCell>  			   
               <TableCell ><b>Tax</b></TableCell>  
               <TableCell ><b>Qty</b></TableCell> 
               <TableCell ><b>Amount</b></TableCell> 
				 
              
               
            </TableRow>  
        

		
      {this.state.InvoiceData[index].subinvoice.map((roweach) => {

		subtaotal=(Number(subtaotal)+Number(roweach.op_grossamount)).toFixed(2)
       
        grandtotal=(Number(grandtotal)+Number(roweach.op_grossamount)).toFixed(2)

              return ( 
     
        
         
		  
          <TableRow  > 
   

   
   
          <TableCell>{roweach.pr_code}</TableCell>
           <TableCell>{roweach.op_title}</TableCell>
            <TableCell>Rs. {roweach.op_srateinc}</TableCell>
            <TableCell>Rs. {roweach.op_srateexc}</TableCell>
			 <TableCell>Rs. {roweach.op_discount}</TableCell>
			 <TableCell>{roweach.op_tax_value}</TableCell>
			 <TableCell>{roweach.op_quantity}</TableCell>
			 <TableCell>Rs. {roweach.op_grossamount}</TableCell>
			
			 
        
        </TableRow>
		
		
		
	
        
              )})}
			  
			
			<TableRow  >
	

<TableCell colspan={7} align="right">Sub Total</TableCell>
<TableCell align="left">Rs.{Number(subtaotal).toFixed(2)}</TableCell>

	</TableRow>	
		<TableRow  >
	

<TableCell colspan={7} align="right">Discount</TableCell>
<TableCell align="left">Rs.{Number(this.state.discount).toFixed(2)}</TableCell>

	</TableRow>	
	<TableRow  >
	

<TableCell colspan={7} align="right">GST</TableCell>
<TableCell align="left">Rs.{Number(this.state.or_taxamount).toFixed(2)}</TableCell>

	</TableRow>	
	<TableRow  >
	

<TableCell colspan={7} align="right">Round off</TableCell>
<TableCell align="left">Rs.{Number(this.state.roundoff).toFixed(2)}</TableCell>

	</TableRow>
		<TableRow  >
	

<TableCell colspan={7} align="right">Grand Total</TableCell>
<TableCell align="left">Rs.{Math.ceil(Number(subtaotal)+Number(this.state.or_taxamount)-Number(this.state.roundoff) )}</TableCell>




	</TableRow> 
			
			
			
			
			
			   </TableBody>
			  
              </Table> 
           </TableContainer>
                 </TableCell>
           </TableRow> 
     
              </React.Fragment>   
              );  
           })}  
			
			
		{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="10">No Records</TableCell>
					</TableRow>
					) }
			
        
        



  
  </Table>
  </TableContainer> 
  
  
  
   <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      /> 
   
   
   </Paper>
    </div>
  </div>
   
   
   
   </div>
  </React.Fragment>)} 
 
 
 
 
 
		

		 </Section></Page>	 	


);
 
  }	
   
   

}
 
		 