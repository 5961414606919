import React ,{Component} from 'react';


import { Page, Section } from 'react-page-layout';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Serverurl from './Apiurl';
import { Redirect  } from 'react-router-dom';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import InputLabel from '@material-ui/core/InputLabel';


const useStyles = makeStyles(theme => ({
 
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  
  form: {
    width: '500%', 
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
}));

export default class organization extends Component {
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[] ,id:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],redirect: false,
	 
	 
	 previd_icon:'',prevphoto_icon:'',search:'',order:'desc' ,orderBy:'or_id',name:'',or_id:0,logo:'',header:'',footer:'',name:'',city:'',email:'',mobilenum:'',phonenum:'',placearray:[],place:'',address:'',pannum:'',gstinnum:'',placelabel:'Select Role',place:''
	 , btndisabled:false,
submitbtn:"Submit", 
        
      }
	 };


 
onloadplacebind(){

    axios.get(Serverurl+"ajaxstate.php?action=list")
    
      .then((response) => {    
     
      this.setState({  placearray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.placearray.map(place => {
                            
          return {value: place.pl_id, display:place.pl_name}
        });
      
		
        this.setState({
          placearray: [{value: '0', display: 'Select Place'}].concat(roleFromApi)
        });
      })


}




componentDidMount() {
 
 
	if(localStorage.getItem("Ruserid")==null)
		  {
			 
	
		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  
		  }
 
 else if(localStorage.getItem("Rrole")!==null)	
	{	
if(localStorage.getItem("Rrole") === "3" || localStorage.getItem("Rrole") === "4" )

	this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  

	}



	this.onloadOrganisation();





}

myChangeHandler = (event) => {
	
	const re = /^[0-9\b]+$/; 
	
  let nam = event.target.name;
  let val = event.target.value;
  
  if(nam==="mobilenum" || nam==="phonenum")
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	  
  }
  }
  else{
  this.setState({[nam]: val});
  }
  
  
  
  
 

 }  





handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
  
  
  
onloadOrganisation(){
		
		 axios.get(Serverurl+'ajaxorganization.php?action=list&or_id=1')
		 
		
  .then(resp => {  
   if(resp.data.length>0){
	   
    this.setState({ 
   	orid:resp.data[0].or_id,
	logo:resp.data[0].or_logo,
    header:resp.data[0].or_header,	
	footer:resp.data[0].or_footer, 
   
	name:resp.data[0].or_name,
	city:resp.data[0].or_city,
	email:resp.data[0].or_email,
	mobilenum:resp.data[0].or_mobilenum,
	phonenum:resp.data[0].or_phonenum,
	place:resp.data[0].or_pl_id,
	address:resp.data[0].or_address,
	pannum:resp.data[0].or_pannum,
	gstinnum:resp.data[0].or_gstinnum,
	
	previd_icon:resp.data[0].or_logo,
	prevphoto_icon:resp.data[0].or_header,
  prevphotofooter_icon:resp.data[0].or_footer,
	});
	
   }
   this.onloadplacebind();
  });

} 


handleSubmit= (event) => {
     let search = window.location.search;
let params = new URLSearchParams(search);
//let orid = params.get('orid');

let orid = 1;


    event.preventDefault();
	//alert('')
    
    let logo = this.state.logo;
    let header = this.state.header;
	let footer = this.state.footer;
    let name = this.state.name;
	let city = this.state.city;
    let email = this.state.email;
	let mobilenum = this.state.mobilenum;
	
	let phonenum = this.state.phonenum;
    let place = this.state.place;
	let address = this.state.address;
    let pannum = this.state.pannum;
	let gstinnum = this.state.gstinnum;
    
   
	 const remail= /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
	
	


if(logo==="")
{
	this.setState({errormessage:"Please Select Logo.",open:true});
	return false;
}




if (! remail.test(email)) {
	  this.setState({errormessage:"Please Enter valid Email.",open:true});
	return false;
  }



if(name==="")
{
	this.setState({errormessage:"Please Enter Name.",open:true});
	return false;
}


if(address==="")
{
	this.setState({errormessage:"Please Enter Address.",open:true});
	return false;
}
	
if(phonenum==="")
{
	this.setState({errormessage:"Please Enter Phone Number.",open:true});
	return false;
}



if(gstinnum==="")
{
	this.setState({errormessage:"Please Enter GST  Number.",open:true});
	return false;
}



	
	/* if(this.state.or_id===0)
	  {
		  formData.append('or_id',0) 
		  
	  }
	  else
	  {
		  
	   formData.append('or_id',this.state.or_id)
		
          
	  }*/
	  this.setState({btndisabled:true,submitbtn:"Processing..."})
	   var formData = new FormData();
	  
	  if(this.state.logo!='')
	  {
		  
		for (const key of Object.keys(this.state.logo)) {
            formData.append('Filedatalogo', this.state.logo[key])
        } 
 formData.append('or_logo','1')		
	  } 
	  else
	  {
		 formData.append('or_logo',this.state.previd_icon)	  
	  
	  }
	
	
	  if(this.state.header!='')
	  {
		  
		for (const key of Object.keys(this.state.header)) {
            formData.append('Filedataheader', this.state.header[key])
        } 
 formData.append('or_header','1')		
	  } 
	  else
	  {
		 formData.append('or_header',this.state.prevphoto_icon)	  
	  
	  }
	  
	  if(this.state.footer!='')
	  {
		  
		for (const key of Object.keys(this.state.footer)) {
            formData.append('Filedatafooter', this.state.footer[key])
        } 
 formData.append('or_footer','1')		
	  } 
	  else
	  {
		 formData.append('or_footer',this.state.prevphotofooter_icon)	  
	  
	  }
	  
	  
	  
	  
	  		 
	 //alert(this.state.or_id)
	
//alert(name);
formData.append('action','update') 
 formData.append('or_id',orid)

formData.append('or_name',name)
formData.append('or_city',city)
formData.append('or_email',email)
formData.append('or_mobilenum',mobilenum)

formData.append('or_phonenum',phonenum)
formData.append('or_pl_id',place)
formData.append('or_address',address)
formData.append('or_pannum',pannum)
formData.append('or_gstinnum',gstinnum)

	   
formData.append('userid',localStorage.getItem("Ruserid"))	
	 
	   	  axios.post(Serverurl+"ajaxorganization.php", formData, {
        }).then(res => {
			
			
		  
			
			
			
			this.setState({  
		errormessage:"Records Updated Successfully",open:true,
		btndisabled:false,submitbtn:"Update"
		});
			
			
            });
			
};


Filedatalogo=(e) =>{
	
	
	
	   var filedate=new Date(e.target.files[0].lastModified);
	   var todaydate=new Date();
	   var minutes = Math.abs(todaydate- filedate);
	 
	   
		 
	   let imageFile = e.target.files[0];
	   
	   if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
  alert( 'Please select valid file (jpg,jpeg,png).' );
    return false;
  }else{
        this.setState({ logo: e.target.files })
  }
	
	
}	


	

Filedataheader=(e) =>{
	
	
	
	   var filedate=new Date(e.target.files[0].lastModified);
	   var todaydate=new Date();
	   var minutes = Math.abs(todaydate- filedate);
	 
	   
		 
	   let imageFile = e.target.files[0];
	   
	   if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
  alert( 'Please select valid file (jpg,jpeg,png).' );
    return false;
  }else{
        this.setState({ header: e.target.files })
  }
	
	
}	




Filedatafooter=(e) =>{
	
	
	
	   var filedate=new Date(e.target.files[0].lastModified);
	   var todaydate=new Date();
	   var minutes = Math.abs(todaydate- filedate);
	 
	   
		 
	   let imageFile = e.target.files[0];
	   
	   if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
  alert( 'Please select valid file (jpg,jpeg,png).' );
    return false;
  }else{
        this.setState({ footer: e.target.files })
  }
	
	
}

	

render() {
	 
	if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    }
	
	return (

	  <Page layout="public">

<Section slot="menu"> / <a href='#/configuration' tabindex="-1">        Configuration </a> / <a href='organization' tabindex="-1">  Organization</a>
</Section>

<Section slot="btn"><a href='#/configuration' tabindex="-1">Configuration</a>
</Section>

<Section slot="content">
	
	





<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>

  <form className={useStyles.form}  onSubmit={this.handleSubmit}>


<div className="orgleft"><div className="headername">
		 <p><b>  Update Organization</b></p>
		 </div>
<div className="panelpad">

<p>Logo</p>
<p> <input type="file"  inputProps={{ tabIndex: "10" }}  name="logo" className="upload-btnfile" onChange={this.Filedatalogo} accept="image/"/></p>
{this.state.logo!=="" &&(
<a href={Serverurl+"uploads/"+this.state.logo} target="_blank">view</a>)}


		  
<p>Header</p>
<p > <input type="file" name="header" inputProps={{ tabIndex: "11" }} className="upload-btnfile" onChange={this.Filedataheader} accept="image/"/></p>
{this.state.header!=="" &&(
<a href={Serverurl+"uploads/"+this.state.header} target="_blank">view</a>)}

		 
<p>Footer</p>
<p> <input type="file" name="footer"  inputProps={{ tabIndex: "12" }} className="upload-btnfile" onChange={this.Filedatafooter} accept="image/"/></p>
{this.state.footer!=="" &&(
<a href={Serverurl+"uploads/"+this.state.footer} target="_blank">view</a>)}
		  
		 
</div>

</div>

<div className="orgright"><div className="headername">
		 <p><b>  Update Organization</b></p>
		 </div>
<div className="panelpad">

      <ul>
<li>	
<p>Oganization Name <span className="validation">*</span></p>  
        <p>
		<TextField
            variant="outlined"
            margin="normal"
             autoFocus
            fullWidth
			value={this.state.name} inputProps={{ tabIndex: "1",maxLength:150 }} 
            id="name"
           
            name="name" onChange={this.myChangeHandler}
    
			
            
			
          /></p></li>


        <li><p>City <span className="validation">*</span></p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
			value={this.state.city}
			inputProps={{ tabIndex: "2",maxLength:150 }} 
            id="city"
            
            name="city" onChange={this.myChangeHandler}
           
            
			
          /></p></li>
		  
		<li> <p>Email <span className="validation">*</span></p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.email} inputProps={{ tabIndex: "3",maxLength:150 }} 
            id="email"
           
            name="email" onChange={this.myChangeHandler}
       
            
			
          /></p></li>
		  
		
		 
		 
		  
		  
		  
		<li><p>Mobile.No</p>  
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			value={this.state.mobilenum} inputProps={{ tabIndex: "4",maxLength:12 }} 
            id="mobilenum"
           
            name="mobilenum" onChange={this.myChangeHandler}
         
			
            
			
          /></p></li>
		  
		 
		  
		<li> <p>Phone.No</p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth inputProps={{ tabIndex: "5",maxLength:12 }} 
			value={this.state.phonenum}
            id="phonenum"
            
            name="phonenum" onChange={this.myChangeHandler}
           
			
            
			
          /></p></li>
		  
	
		
		<li>  <p>State/Country</p>
		
		<p><FormControl  className={useStyles.formControl} >
        
		
        <Select
         native
		 variant="outlined"
            margin="normal" inputProps={{ tabIndex: "6",maxLength:150 }} 
		  defaultValue={{ label: this.state.rolelabel, value: this.state.place }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.place}
		  name="place"
		
		
        >
		{this.state.placearray.map(place => (
            <option  
            
              value={place.value}
            >
              {place.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
		  
		  </li>
		  
		  <div className="address">
		
		<p>Address <span className="validation">*</span></p>
		  <p> 
		  <TextField
            id="outlined-multiline-static"
         
          multiline
          rows={4}
		  variant="outlined" inputProps={{ tabIndex: "7"}} 
            value={this.state.address}
            id="address"
			
            fullWidth
          
            name="address" onChange={this.myChangeHandler}
  
            
          />  </p>
		  
		</div>
		
		
		
		
		
		<li>  <p>PAN.No</p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth inputProps={{ tabIndex: "8",maxLength:150 }} 
			value={this.state.pannum}
            id="pannum"
           
            name="pannum" onChange={this.myChangeHandler}
          
			
            
			
          /></p></li>
		
		
		<li>  <p>GSTIN.No</p>
		<p>
		  <TextField
            variant="outlined"
            margin="normal"
           
            fullWidth inputProps={{ tabIndex: "9",maxLength:150 }} 
			value={this.state.gstinnum}
            id="gstinnum"
           
            name="gstinnum" onChange={this.myChangeHandler}
           
			
            
			
          /></p></li>
		
		<li> <p> <Button
            type="submit"
            halfWidth
            variant="contained" 
            color="primary" inputProps={{ tabIndex: "13" }} 
            id="searchform"
            className={useStyles.submit}
			disabled={this.state.btndisabled}
          >
             {this.state.submitbtn}
          </Button></p></li>
		
		
		   </ul>
		   </div>
		   
		   </div>	   
		  
		<div className="clear"></div>
		  
		
		 
		  
		   
		 </form> 

  

          



		  
	 </Section></Page>	 	   
		
		  
		 
		  




  );
  }
}










