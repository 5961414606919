import React ,{Component} from 'react';
import ReactDOM from "react-dom";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';



const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));


export default class test extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
      value1: '',
      value2: '',
	  result: '?',
	  answer:''
    };

  }


myChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({[name] : value});
	

	

  }


  handleSubmit= (event) => {
	
	let value1= this.state.value1;
	let value2= this.state.value2;
	
	//alert(value1);
	//alert(value2);
	let result = parseInt(value1) +parseInt(value2);
	
	
	//alert(result);
	
	
 this.setState({answer:result})
	//let result=({this.state.value1} + {this.state.value2});
	
	//alert(result)
}


  render() {
  return (
  
  <div className="addition">
   
    
    <p>Enter the first number</p>
    <TextField id="outlined-basic" label="value1" variant="outlined" name="value1" onChange={this.myChangeHandler} />
	<p>Enter the second number</p>
	<TextField id="outlined-basic" label="value2" variant="outlined"  name="value2"  onChange={this.myChangeHandler} />
	
	
	<p><Button variant="contained"
	
	onClick={this.handleSubmit} >
	Submit</Button></p>

	<h3>Addition off two numbers is {this.state.answer}</h3>
    </div>
  );
}
}