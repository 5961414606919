import React ,{Component} from 'react';
import './styles.css';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Redirect  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios';
import { Page, Section } from 'react-page-layout';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';




const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
 
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default class  changepassword extends Component {


 constructor(props) {
    super();

    this.state = { logredirect:false,pagename:'',OldPassword: '' ,NewPassword:'',ConfirmNewPassword:'',us_id:'',
	
	btndisabled:false,
submitbtn:"Change Password",
	};
   
}
componentDidMount() {
	
	if(localStorage.getItem("Ruserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
}
validateForm() {
    return (
      this.state.OldPassword.length > 0 &&
      this.state.NewPassword.length > 0 &&
      this.state.NewPassword === this.state.ConfirmNewPassword
    );
}


handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
 
  
handleChange = event => {
let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 };

 handleChangeClick =  (event) => {
    event.preventDefault();
   let Ruserid = localStorage.getItem("Ruserid");
   let OldPassword = this.state.OldPassword;
 
   let NewPassword = this.state.NewPassword;
   let ConfirmNewPassword = this.state.NewPassword;
   
   
   if(OldPassword==="")
{
	this.setState({errormessage:"Please Enter Old Password.",open:true});
	return false;
}
   if(NewPassword==="")
{
	this.setState({errormessage:"Please Enter New Password.",open:true});
	return false;
}
   
    if(NewPassword!=ConfirmNewPassword)
{
	this.setState({errormessage:"Please ReType Correct Password.",open:true});
	return false;
}
  
  if(NewPassword!=ConfirmNewPassword)
  {
	 this.setState({errormessage:"Please ReType Correct Password.",open:true}); 
 
  return false;
  }
    this.setState({btndisabled:true,submitbtn:"Processing..."}) 
var formData = new FormData();
    formData.append('action','changepaswrd')
	formData.append('us_id',localStorage.getItem("Ruserid"))
	formData.append('oldpassword',OldPassword)
	formData.append('newpassword',NewPassword)
	
	
 axios.post(Serverurl+"ajaxuser.php", formData, {
        }).then(res => {
			let strreturn=res.data 	;
			if(strreturn === "0")
 {
	 alert("Your Current Password is not Valid!")
	 this.setState({
			   btndisabled:false,
	 submitbtn:"Change Password",})
 }else
 {
	alert("Password has been updated Successfully!") 
	 this.setState({
			   btndisabled:false,
	 submitbtn:"Change Password",})
	if(localStorage.getItem("Rlogin")==="0")
		  {
			  
let myItem = localStorage.getItem('Rusername');
	let myItem1 = localStorage.getItem('Rpassword');
localStorage.clear();
localStorage.setItem('Rusername',myItem);
  localStorage.setItem('Rpassword',myItem1);
		   this.setState({
			   
			   
            logredirect: true,
			pagename:'Login'
          })
		  }
 }
    
	});
    
    
  };



render() {

if (this.state.logredirect === true) {
      return <Redirect to={this.state.pagename} />
    } 
return (


  <Page layout="public">

<Section slot="menu"> / <a href='#/configuration'>Configuration</a> / <a href='master'>Master</a>
</Section>

<Section slot="btn"><a href='#/Login'>Login</a>
</Section>

<Section slot="content"> 				
					
<React.Fragment>

<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>



<form className={useStyles.form} onSubmit={this.handleChangeClick}>

<div className="changepassword">
 <div className="headername">
		 <p><b>Change Password</b></p>
		 </div>

<div className="changepasswordbg">
<p>Old Password</p>
 <p><TextField
            variant="outlined"
            margin="normal"
           
            fullWidth
			 type="password"
            id="OldPassword"
           
            name="OldPassword" onChange={this.handleChange}
			value={this.state.OldPassword}
           
          
          /></p>


<p>New Password</p>
          <p><TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
			 type="password"
            id="NewPassword"
           
            name="NewPassword"  onChange={this.handleChange}
			value={this.state.NewPassword}
            
          /></p>


<p>Confirm Password</p>
<p><TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
			 type="password"
            id="ConfirmNewPassword"
           
            name="ConfirmNewPassword" onChange={this.handleChange}
			value={this.state.ConfirmNewPassword}
           
          /></p>

 <p className="submitlogin"><Button
            type="submit"
            fullWidth
			disabled={this.state.btndisabled}
            variant="contained" 
            color="primary"
           id="searchform" 
            className={useStyles.submit}
          >
            {this.state.submitbtn}
          </Button></p>

</div></div>
</form>



</React.Fragment>
 </Section></Page>
     );
    }
   }

